<swap-modal [modalId]="modalId" [scrollable]="true" [right]="true">
  <div class="modal-header border-0">
    <h2 class="modal-title fs-5 fw-bolder" id="staticBackdropLabel">
      Get In Touch
    </h2>
    <button
      type="button"
      class="closeBtn"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeBtn
    >
      <img loading="lazy" src="assets/icons/close-icon.svg" alt="" />
    </button>
  </div>
  <div class="modal-body m-3 mt-0 pt-0">
    <div
      class="col-12 d-flex align-items-center justify-content-between gap-5 item mb-4"
      data-bs-toggle="modal"
      attr.data-bs-target="{{ newTicketModalId }}"
      (click)="closeBtn.click()"
    >
      <div class="d-flex align-items-center gap-2 pointer">
        <img loading="lazy" src="assets/icons/exclaim-grey.svg" alt="" />
        <p class="mb-0">Still need more help, Submit a ticket</p>
      </div>
      <i class="bi bi-chevron-right"></i>
    </div>
    <div
      class="col-12 d-flex align-items-center justify-content-between gap-5 item mb-4"
    >
      <div class="d-flex align-items-center gap-2 pointer">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.1171 2.5C15.2346 2.5 16.3096 2.94167 17.1005 3.73417C17.8921 4.525 18.3346 5.59167 18.3346 6.70833V13.2917C18.3346 15.6167 16.443 17.5 14.1171 17.5H5.88464C3.5588 17.5 1.66797 15.6167 1.66797 13.2917V6.70833C1.66797 4.38333 3.55047 2.5 5.88464 2.5H14.1171ZM15.4429 7.95027L15.5096 7.88361C15.7087 7.64194 15.7087 7.29194 15.5004 7.05027C15.3846 6.92611 15.2254 6.85027 15.0596 6.83361C14.8846 6.82444 14.7179 6.88361 14.5921 7.00027L10.8346 10.0003C10.3512 10.4011 9.65874 10.4011 9.16791 10.0003L5.41791 7.00027C5.15874 6.80861 4.80041 6.83361 4.58458 7.05861C4.35958 7.28361 4.33458 7.64194 4.52541 7.89194L4.63458 8.00027L8.42624 10.9586C8.89291 11.3253 9.45874 11.5253 10.0512 11.5253C10.6421 11.5253 11.2179 11.3253 11.6837 10.9586L15.4429 7.95027Z"
            fill="#797B89"
          />
        </svg>

        <p class="mb-0">
          Send us a mail at
          <a href="mailto:support@mail.expatswap.com" class="text-secondary"
            >support@mail.expatswap.com</a
          >
        </p>
      </div>
    </div>
    <div
      class="col-12 d-flex align-items-center justify-content-between gap-5 item mb-4"
    >
      <div
        class="d-flex align-items-center gap-2 pointer"
        data-bs-dismiss="modal"
        aria-label="Close"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.66797 10.0138C1.66797 5.6239 5.1763 1.66797 10.018 1.66797C14.7513 1.66797 18.3346 5.54879 18.3346 9.98878C18.3346 15.1382 14.1346 18.3346 10.0013 18.3346C8.63464 18.3346 7.11797 17.9674 5.9013 17.2497C5.4763 16.991 5.11797 16.799 4.65964 16.9492L2.9763 17.45C2.5513 17.5835 2.16797 17.2497 2.29297 16.799L2.8513 14.9295C2.94297 14.6708 2.9263 14.3954 2.79297 14.1784C2.0763 12.8598 1.66797 11.4159 1.66797 10.0138ZM6.14284 11.0897C5.5595 11.0897 5.07617 10.6056 5.07617 10.0214C5.07617 9.42884 5.55117 8.95312 6.14284 8.95312C6.73451 8.95312 7.20951 9.42884 7.20951 10.0214C7.20951 10.6056 6.73451 11.0813 6.14284 11.0897ZM8.91732 10.0138C8.91732 10.6064 9.39232 11.0821 9.98398 11.0904C10.5757 11.0904 11.0507 10.6064 11.0507 10.0222C11.0507 9.4296 10.5757 8.95388 9.98398 8.95388C9.40065 8.94554 8.91732 9.4296 8.91732 10.0138ZM12.7585 10.022C12.7585 10.6063 13.2335 11.0903 13.8251 11.0903C14.4168 11.0903 14.8918 10.6063 14.8918 10.022C14.8918 9.42949 14.4168 8.95378 13.8251 8.95378C13.2335 8.95378 12.7585 9.42949 12.7585 10.022Z"
            fill="#797B89"
          />
        </svg>

        <p class="mb-0">Go to FAQ to search for answers</p>
      </div>
      <i class="bi bi-chevron-right"></i>
    </div>
  </div>
  <!-- <live-chat [ticketCategories]="globals.helpCategories"></live-chat> -->
</swap-modal>
