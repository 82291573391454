import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import parsePhoneNumber from 'libphonenumber-js';

export function upperCaseValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const hasUpperCaseValue = /[A-Z]/.test(control.value);
    if (hasUpperCaseValue) {
      return null;
    } else {
      return {
        hasUpperCase: {
          message: 'Requires upper case value',
        },
      };
    }
  };
}

export function lowerCaseValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const hasLowerCaseValue = /[a-z]/.test(control.value);
    if (hasLowerCaseValue) {
      return null;
    } else {
      return {
        hasLowerCase: {
          message: 'Requires lower case value',
        },
      };
    }
  };
}

export function numberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const hasNumberValue = /[0-9]/.test(control.value);
    if (hasNumberValue) {
      return null;
    } else {
      return {
        hasNumber: {
          message: 'Requires a number',
        },
      };
    }
  };
}

export function specialCharacterValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const hasSpecialCharacterValue =
      /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(control.value);
    if (hasSpecialCharacterValue) {
      return null;
    } else {
      return {
        hasSpecialCharacter: {
          message: 'Requires special character',
        },
      };
    }
  };
}

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const hasValidEmailRegex =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(control.value);
    if (hasValidEmailRegex) {
      return null;
    } else {
      return {
        hasValidEmail: {
          message: 'Requires valid email',
        },
      };
    }
  };
}

export function phoneValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const phoneNumber = parsePhoneNumber(control.value);
    if (phoneNumber?.isValid()) {
      return null;
    }
    return {
      hasValidPhone: {
        message: 'Requires valid phone number',
      },
    };
  };
}

export function maxAmount(maxAmount: string ): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    // Check if value is not a string or if it's an empty string
    if (typeof value !== 'string' || value.trim() === '') {
      return { invalidString: true };
    }

    // Remove commas from the input and maxAmount strings
    const amountString = value.replace(/,/g, '');
    const maxAmountString = maxAmount.replace(/,/g, '');

    // Convert strings to numbers
    const amount = parseFloat(amountString);
    const max = parseFloat(maxAmountString);

    // Check if the input is a valid number
    if (isNaN(amount)) {
      return { invalidNumber: true };
    }

    // Check if the number exceeds the maxAmount
    if (amount > max) {
      return { exceedsMaxAmount: true };
    }

    return null; // Validation passed
  };
}

export function minAmount(minAmount: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    // Check if value is not a string or if it's an empty string
    if (typeof value !== 'string' || value.trim() === '') {
      return { invalidString: true };
    }

    // Remove commas from the input and minAmount strings
    const amountString = value.replace(/,/g, '');
    const minAmountString = minAmount.replace(/,/g, '');

    // Convert strings to numbers
    const amount = parseFloat(amountString);
    const min = parseFloat(minAmountString);

    // Check if the input is a valid number
    if (isNaN(amount)) {
      return { invalidNumber: true };
    }

    // Check if the number exceeds the minAmount
    if (amount < min) {
      return { exceedsMinAmount: true };
    }

    return null; // Validation passed
  };
}