import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AuthService } from './api/auth/auth.service';
import { SettingsService } from './api/settings/settings.service';
import { SocketService } from './api/socketio/socket.service';
import { TransactionService } from './api/transactions/transaction.service';
import { GlobalsService } from './core/globals';
import { HintsService } from './core/hints';
import { NotificationService } from './core/notification';
import { RequestService } from './core/request';
import { StorageService } from './core/storage';
import { ToasterService } from './core/toaster';
import { MixpanelService } from './api/mixpanel/mixpanel.service';
import { MetaService } from './api/meta/meta.service';

export function initializeApp(
  metaService: MetaService,
  storage: StorageService,
  globals: GlobalsService,
  authService: AuthService,
  settingsService: SettingsService,
  mixpanelService: MixpanelService,
) {
  return async () => {
    await authService.getUserLocation();
    if (globals.countries.length === 0) await authService.getAllCountriesData();
    await settingsService.getHelpCategories();
    await settingsService.getPaymentMethods();
    mixpanelService.initializeMixpanel();
    return true;
  };
}

const providers: Array<any> = [
  GlobalsService,
  StorageService,
  AuthService,
  SettingsService,
  MixpanelService,
  MetaService,
  RequestService,
  ToasterService,
  SocketService,
  TransactionService,
  NotificationService,
  HintsService,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: initializeApp,
    deps: [
      MetaService,
      StorageService,
      GlobalsService,
      AuthService,
      SettingsService,
      MixpanelService,
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: providers,
})
export class ServicesModule {}
