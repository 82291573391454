<swap-modal [modalId]="modalId" [scrollable]="true" [right]="true">
  <div class="modal-header mb-4">
    <p class="modal-title fw-bolder" id="staticBackdropLabel">Order History</p>
    <button
      type="button"
      class="closeBtn"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeBtn
    >
      <img loading="lazy" src="assets/icons/close-icon.svg" alt="" />
    </button>
  </div>
  <div class="modal-body m-3 mt-0 pt-0">
    <div class="row g-0" style="width: 20em;">
      <div class="col-12 border-bottom py-3 d-flex align-items-center justify-content-between">
        <div>
          <p class="mb-0 fw-bolder">Plus Plan: Annual</p>
          <small class="text-muted">January 28, 2023</small>
        </div>
        <a href="#" class="text-decoration-none text-secondary fw-bolder">View</a>
      </div>
      <div class="col-12 border-bottom py-3 d-flex align-items-center justify-content-between">
        <div>
          <p class="mb-0 fw-bolder">Plus Plan: Annual</p>
          <small class="text-muted">January 28, 2023</small>
        </div>
        <a href="#" class="text-decoration-none text-secondary fw-bolder">View</a>
      </div>
      <div class="col-12 border-bottom py-3 d-flex align-items-center justify-content-between">
        <div>
          <p class="mb-0 fw-bolder">Plus Plan: Annual</p>
          <small class="text-muted">January 28, 2023</small>
        </div>
        <a href="#" class="text-decoration-none text-secondary fw-bolder">View</a>
      </div>
    </div>
  </div>
</swap-modal>
