import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Country } from 'src/app/services/core/IApp';

@Component({
  selector: 'currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.scss'],
})
export class CurrencyInputComponent {
  @Input() selectedCurrency: Country | any;
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() rightOffset: boolean = false;
  @Input() bottomOffset: boolean = false;
  @Input() smaller: boolean = false;
  @Input() countries: Country[] = [];
  @Input() name: string = '';
  @Input() label: string = '';
  @Input() inputValue: number | string = '';
  @Output() currencyChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() inputChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() keyup: EventEmitter<any> = new EventEmitter<any>();
  countrySearchTerm: string = '';

  constructor() {}

  ngOnInit() {}

  selectCurrencyFilter() {
    // Get the dropdown element
    let dropDown = document.querySelector(
      '#currency-select-' + this.name
    ) as HTMLElement;
    // Get the search input element
    let searchbox = document.querySelector(
      '#currencysearchbox' + this.name
    ) as HTMLElement;
    // Get the dropdown input to hide inner elements
    let dropdownInput = document.querySelector(
      '#dropdown-input-' + this.name
    ) as HTMLElement;
    dropDown.classList.toggle('active');
    dropdownInput.classList.toggle('active');
    // Reset the search input
    this.countrySearchTerm = '';
    // Set focus on search input
    searchbox.focus();

    // Close the dropdown if the user clicks outside of it
    document.addEventListener('click', function (e: any) {
      if (!dropDown.contains(e.target)) {
        dropDown.classList.remove('active');
        dropdownInput.classList.remove('active');
      }
    });
  }

  selectCurrency(currency: any) {
    this.currencyChange.emit({
      name: this.name,
      currency: currency,
    });
    this.selectedCurrency = currency;
    let dropDown = document.querySelector('#country-select') as HTMLElement;
    dropDown?.classList.remove(...'active');
  }

  // formatAmount(event: any, type: string) {
  //   // format the amount to have commas and decimals
  //   let amount = event.target.value;
  //   amount = amount.replace(/,/g, '');
  //   amount = amount.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

  //   // allow only numbers, commas and decimals
  //   amount = amount.replace(/[^0-9,\.]/g, '');
  //   event.target.value = amount;
  //   this.buyerOfferForm.patchValue({
  //     [type]: this.formatToNumber(amount),
  //   });
  //   // trigger validation
  //   this.buyerOfferForm.controls[type].markAsTouched();
  //   this.buyerOfferForm.controls[type].markAsDirty();
  // }

  formatToNumber(amount: string | number): number {
    if (typeof amount === 'number') return amount;
    return Number(amount?.replace(/,/g, '') || 0);
  }

  onInputChange(event: any) {
    let amount = event.target.value;
    amount = amount.replace(/,/g, '');
    amount = amount.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

    // allow only numbers, commas and decimals
    amount = amount.replace(/[^0-9,\.]/g, '');
    event.target.value = amount;

    this.inputChange.emit(amount);
  }
}
