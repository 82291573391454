<swap-modal [modalId]="modalId" [scrollable]="true" [right]="true">
  <div class="modal-header border-0 mx-3 mt-2">
    <h2 class="modal-title fs-5 fw-bolder" id="staticBackdropLabel">
      Add Currency
    </h2>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeBtn
      (click)="onClose()"
    ></button>
  </div>
  <div class="modal-header border-0 mx-3 d-block">
    <p class="color-grey600 font-14 mb-3">
      When you add a currency, any payments you receive in that currency will be
      credited to that balance
    </p>

    <div class="col-12 label pointer mb-2">
      <div class="search">
        <img loading="lazy" src="assets/images/Search.svg" alt="" />
        <input
          type="text"
          placeholder="Search..."
          class="fw-500"
          [(ngModel)]="search"
        />
      </div>
    </div>
    <!-- Checked Currency Listings -->
    <small class="font-12">
      Selected ({{ selectedCurrencyListings.length }}/5):
      <div class="d-flex align-items-center flex-wrap gap-2">
        <ng-container
          *ngFor="let currency of selectedCurrencyListings; let index = index"
        >
          <span *ngIf="currency.checked">
            <img
              loading="lazy"
              [src]="
                'https://hatscripts.github.io/circle-flags/flags/' +
                currency?.iso2?.toLowerCase() +
                '.svg'
              "
              width="24"
              alt=""
            />
            {{ currency?.name }} - {{ currency?.currency
            }}<span *ngIf="index + 1 < selectedCurrencyListings.length">,</span>
          </span>
        </ng-container>
      </div>
    </small>
  </div>
  <div class="modal-body pt-0 mx-3">
    <div class="col-12 px-24 overflow-scroll">
      <!-- All countries -->
      <div *ngFor="let currency of currencyListings | countryFilter : search">
        <div class="d-flex justify-content-between mb-4">
          <label
            [for]="currency?.currency + '_checkbox_id'"
            class="d-flex align-items-center w-100 pointer"
          >
            <img
              loading="lazy"
              [src]="
                'https://hatscripts.github.io/circle-flags/flags/' +
                currency?.iso2?.toLowerCase() +
                '.svg'
              "
              width="24"
              alt=""
            />
            <span class="ms-2 font-16 fw-500">
              {{ currency?.name }} - {{ currency?.currency }}
            </span>
          </label>
          <input
            type="checkbox"
            [disabled]="
              selectedCurrencyListings.length >= 5 && !currency?.checked
            "
            [id]="currency?.currency + '_checkbox_id'"
            [(ngModel)]="currency.checked"
            (change)="updateCurrency()"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      (click)="saveCurrency()"
      [disabled]="selectedCurrencyListings.length === 0"
      class="col-12 bg-purple p-3 color-white round-12 border-0 font-16 fw-700"
    >
      {{ buttonText }}
    </button>
  </div>
</swap-modal>
