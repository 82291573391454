<section class="m-auto px-4 d-flex">
  <div class="text-center">
    <img
      loading="lazy"
      style="width: 200px"
      src="assets/images/empty-state.svg"
      alt="empty-state"
    />
    <div class="mt-2">
      <div class="fw-600 font-20">{{ content }}</div>
    </div>
  </div>
</section>
