<div class="mb-3">
  <label [for]="placeholder" class="mb-2"
    >{{ label }}
    <span class="text-danger" *ngIf="required && label">*</span></label
  >
  <div class="form-input">
    <input
      [type]="type"
      [id]="placeholder + '-input'"
      [name]="name"
      [value]="value"
      [placeholder]="placeholder"
      autocomplete="off"
      [required]="required"
      (input)="onChange($event)"
    />
    <img
      loading="lazy"
      src="assets/icons/email.svg"
      alt="Icon"
      *ngIf="name === 'email'"
    />
    <img
      loading="lazy"
      src="assets/icons/hide.png"
      alt="Icon"
      *ngIf="name.includes('password') && !togglePassword"
      (click)="togglePasswordVisibility()"
    />
    <img
      loading="lazy"
      src="assets/icons/show.png"
      alt="Icon"
      *ngIf="name.includes('password') && togglePassword"
      (click)="togglePasswordVisibility()"
    />
  </div>
  <ng-content></ng-content>
</div>
