import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../../../services/api/auth/auth.service';
import { MixpanelService } from '../../../services/api/mixpanel/mixpanel.service';
import { SocketService } from '../../../services/api/socketio/socket.service';
import { TransactionService } from '../../../services/api/transactions/transaction.service';
import {
  Offer,
  Ratings_Reviews,
  User,
  Wallet,
} from '../../../services/core/IApp';
import { GlobalsService } from '../../../services/core/globals';
import { HintsService } from '../../../services/core/hints';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  title: string = '';

  userDetails: User | undefined;

  isExpanded: boolean = true;

  addBankSuccess: boolean = false;
  escrowEnabled: boolean = environment.escrowEnabled;
  @Input() isSidebarOpen: boolean = false;

  @Output() toggleSidebar: EventEmitter<any> = new EventEmitter();

  modalId: string = 'offerModal';
  modalId2: string = 'buyerOfferModal';
  modalId3: string = 'ratingsReviewModal';
  modalId4: string = 'upgradeModal';
  modalId5: string = 'verifyPhoneModal';
  modalId6: string = 'createWallet';
  modalId7: string = 'fundWallet';
  modalId8: string = 'addBank';
  modalId9: string = 'topupWallet';

  connection: any;

  displayEscrowFees: boolean = false;

  constructor(
    private titleService: Title,
    public globals: GlobalsService,
    public transactionService: TransactionService,
    private hintsService: HintsService,
    private mixpanelService: MixpanelService,
    public authService: AuthService,
    private socketService: SocketService,
    private renderer: Renderer2,
    private el: ElementRef,
    private cdr: ChangeDetectorRef
  ) {
    this.globals.router.events.subscribe((val) => {
      this.title = this.titleService.getTitle();
    });
  }

  async ngOnInit() {
    this.getOfferFromLocalStorage();
    console.log('this is data2', this.transactionService.offer);

    // Manually trigger change detection after setting the offer
    this.cdr.detectChanges(); // Trigger change detection here

    await this.authService.getNotifications(1);
    await this.transactionService.getAllUserBankAccounts();
    await this.transactionService.getAllWallets();
  }
  // get offer from localstorage
  getOfferFromLocalStorage() {
    const storedOffer = this.globals.storage.getData('currentOffer');
    console.log('this is data', storedOffer);
    if (storedOffer) {
      this.transactionService.offer = storedOffer;
    }
  }

  // getOfferFromLocalStorage() {
  //   const storedOffer = this.globals.storage.getData('currentOffer');
  //   console.log('this is data', storedOffer);
  //   if (storedOffer) {
  //     return (this.transactionService.offer = storedOffer);
  //   }
  // }

  ngOnDestroy() {
    this.stopListener();
  }

  stopListener() {
    this.connection?.unsubscribe();
  }

  selectDropdown(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    let mainElement = event.target as HTMLElement;

    // Handle if the click is on the image inside the button
    if (
      mainElement.nodeName === 'IMG' &&
      mainElement.parentElement?.nodeName === 'BUTTON'
    ) {
      mainElement = mainElement.parentElement;
    }

    // Close other dropdown options
    const elements = this.el.nativeElement.querySelectorAll(
      '.dropdown-options.show'
    );
    elements.forEach((el: HTMLElement) => {
      if (el !== mainElement.querySelector('.dropdown-options')) {
        this.renderer.removeClass(el, 'show');
      }
    });

    // Toggle current dropdown
    const dropdownElement = mainElement.querySelector('.dropdown-options');
    if (dropdownElement) {
      if (dropdownElement.classList.contains('show')) {
        this.renderer.removeClass(dropdownElement, 'show');
      } else {
        this.renderer.addClass(dropdownElement, 'show');
      }
    }
  }

  @HostListener('document:mousedown', ['$event'])
  closeDropdown(event: Event) {
    const elements = this.el.nativeElement.querySelectorAll(
      '.dropdown-options.show'
    );
    elements.forEach((el: HTMLElement) => {
      if (
        !el.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest('.notification')
      ) {
        this.renderer.removeClass(el, 'show');
      }
    });
  }

  async updateOffer(offer: Offer) {
    this.globals.spinner.show();
    await this.transactionService.updateOffer(
      offer,
      this.transactionService.offer._id
    );
    await this.transactionService.getAllMyOffers();
    this.globals.spinner.hide();
  }

  async createOffer(offer: Offer) {
    this.globals.spinner.show();
    await this.transactionService.createOffer(offer);
    this.globals.spinner.hide();

    if (this.globals.user?.verificationStatus?.rank !== 3) {
      this.globals.openModal(this.modalId4);
      return;
    }

    if (this.escrowEnabled && offer.offerType === 'escrow') {
      this.transactionService.offer = offer;
      const wallet = this.transactionService.wallets.find(
        (wallet) => wallet.currency.code === offer.localCurrency.code
      ) as Wallet;

      if (
        !wallet &&
        (offer.localCurrency.code === 'NGN' ||
          offer.foreignCurrency.code === 'NGN')
      ) {
        this.globals.openModal(this.modalId6);
      } else {
        const amount = offer.amount + 0.05 * offer.amount;

        await this.transactionService.createWalletTransaction({
          amount,
          purpose: 'Escrow Funding',
          type: 'credit',
          wallet: wallet._id,
          currency: offer.localCurrency,
        });

        this.transactionService.wallet = wallet;

        this.displayEscrowFees = true;

        this.globals.openModal(this.modalId7);
      }
    }
  }

  async buyerOffer(offer: Offer) {
    this.globals.spinner.show();
    await this.transactionService.buyOffer(
      offer,
      this.transactionService.offer._id
    );
    this.globals.spinner.hide();
    this.globals.user?.verificationStatus?.rank !== 3 &&
      this.globals.openModal(this.modalId4);
    this.hintsService.thirdStageHints();
    setTimeout(() => {
      this.globals.closeModal('#' + this.modalId4);
      this.globals.router.navigate(['/main/transaction'], {
        queryParams: { tab: 'pending' },
      });
    }, 5000);
  }

  async emitRatingReview(ratingReview: Ratings_Reviews) {
    this.globals.spinner.show();
    await this.transactionService.createReview(ratingReview);
    this.globals.spinner.hide();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  async markAsRead(
    notificationId: string,
    trigger?: string,
    biddingId?: string
  ) {
    trigger && this.globals.navigateByTrigger(trigger, biddingId);
    await this.authService.markNotificationAsRead(notificationId);
  }

  resetOffer() {
    this.transactionService.offer = {} as Offer;
  }

  async emitChangeCountry(countryId: string) {
    this.globals.spinner.show();
    await this.authService.getAllStatesData(countryId);
    this.globals.spinner.hide();
  }

  async addBankAccount(form: any) {
    form.currency = this.transactionService.selectedCurrency;
    await this.transactionService.addBankAccount(form);
    await this.transactionService.getAllBanks();
    this.transactionService.groupByCurrency();
    this.addBankSuccess = true;
  }

  async createWalletTransaction(event: any) {
    await this.transactionService.createWalletTransaction(event);
    await this.transactionService.getWalletTransactions(
      this.transactionService.wallet._id
    );
    this.globals.openModal(this.modalId7);
  }

  async verifyPhone(form: any) {
    this.globals.spinner.show();
    await this.authService.verifyPhone(form.phone);
    await this.authService.getUserDetails();
    this.globals.closeModal('#' + this.modalId5);
    this.globals.spinner.hide();
  }

  async logOut() {
    await this.globals.loggedOut();
    this.socketService.disconnectSocket();
    this.mixpanelService.signOutUserEvent({
      username: this.globals.user?.username,
      status: 'success',
    });
  }
}
