<nav class="row g-0">
  <div class="col-4">
    <div class="p-4 px-md-5 mx-md-5">
      <img
        loading="lazy"
        src="assets/images/logo.svg"
        alt="Expatswap Logo"
        class="pointer"
        routerLink="/"
      />
    </div>
  </div>
  <div class="col text-end align-items-center">
    <div
      class="d-flex align-items-center justify-content-end nav_links p-4 mx-5"
    >
      <ul class="d-flex align-items-center unorder">
        <li class="nav-item dropdown">
          <a
            class="nav-link mgr-1 color-body"
            routerLink="/auth"
            [ngClass]="[showHaveAnAcctBtn ? 'isDisplay' : 'isNotDisplay']"
          >
            Have an account?
          </a>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link mgr-1"
            routerLink="/main/home"
            [ngClass]="[showSwapBtn ? 'isDisplay' : 'isNotDisplay']"
            >Swap</a
          >
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link mgr-1 dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            (click)="showHelpDropdown('dropdownMenuLink')"
            [ngClass]="[showHelpBtn ? 'isDisplay' : 'isNotDisplay']"
            >Help</a
          >
          <ul class="dropdown-menu boder-0 p-2" id="dropdownMenuLink">
            <li>
              <a class="dropdown-item" routerLink="/contact-us">Contact Us</a>
            </li>
            <li><a class="dropdown-item" routerLink="/faqs">FAQs</a></li>
          </ul>
        </li>
      </ul>
      <div class="d-flex align-items-center ml-3">
        <button
          routerLink="/auth/sign-up"
          class="btn-outline color-purple px-4 bg-white font-16 fw-700 round-12 mgr-13"
          style="transition: all 0.1s ease-in-out; padding-block: 0.9em"
          [ngClass]="[showCreateAcctBtn ? 'isDisplay' : 'isNotDisplay']"
        >
          Create an Account
        </button>
        <button
          routerLink="/auth"
          class="bg-purple btn-non-outline color-white py-3 px-4 round-12 border-0 fw-500 font-16 w-fc"
          [ngClass]="[showSignInAcctBtn ? 'isDisplay' : 'isNotDisplay']"
        >
          Sign in
        </button>
      </div>
    </div>
    <a
      class="menu text-decoration-none pointer text-primary fw-700"
      (click)="openNav()"
      >&equiv;</a
    >
  </div>
  <div class="overlay" id="mobile_menu">
    <div>
      <div
        class="col-12 d-flex align-items-center justify-content-between px-3 py-2 gap-2"
      >
        <img
          loading="lazy"
          src="assets/images/logo.svg"
          alt="Expatswap Logo"
          class="pointer"
          width="180"
          routerLink="/"
        />
        <a class="close pointer text-decoration-none" (click)="closeNav()"
          >&times;</a
        >
      </div>
      <div class="overlay_content">
        <a routerLinkActive="active" routerLink="/main/home">Swap</a>
        <div class="dropdown">
          <a
            class="dropdown-toggle"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            (click)="showHelpDropdown('dropdownMenuLink2')"
          >
            Help
          </a>

          <ul class="dropdown-menu" id="dropdownMenuLink2">
            <li>
              <a class="dropdown-item" routerLink="/contact-us">Contact Us</a>
            </li>
            <li><a class="dropdown-item" routerLink="/faqs">FAQs</a></li>
          </ul>
        </div>
        <!-- <a routerLink="/auth">Sign in</a> -->
      </div>
    </div>
    <button
      class="btn btn-primary"
      [ngClass]="[showSignInAcctBtn ? 'isDisplay' : 'isNotDisplay']"
      [ngClass]="[showCreateAcctBtn ? 'isDisplay' : 'isNotDisplay']"
      routerLink="/auth/sign-up"
    >
      Create an Account
    </button>

    <button
      class="btn btn-primary"
      [ngClass]="[showSignInAcctBtn ? 'isDisplay' : 'isNotDisplay']"
      routerLink="/auth"
    >
      Sign in
    </button>
  </div>
</nav>
