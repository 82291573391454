import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'social-connect',
  templateUrl: './social-connect.component.html',
  styleUrls: ['./social-connect.component.scss'],
})
export class SocialConnectComponent implements OnInit {
  searchTerm: string = '';
  @Input() type: string = '';
  @Input() modalId: string = '';
  @Output() emitSocials: EventEmitter<any> = new EventEmitter();
  @ViewChild('closeModal') closeModal: any;
  regex = /^https?:\/\/(www\.)?facebook\.com\/[a-zA-Z0-9_\-\.]+\/?$/;

  constructor() {}

  ngOnInit(): void {}

  connectAccount() {
    this.emitSocials.emit(this.searchTerm);
    this.searchTerm = '';
    this.closeModal.nativeElement.click();
  }
}
