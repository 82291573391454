<swap-modal [modalId]="modalId" [centered]="true">
  <div class="position-relative">
    <button class="close" data-bs-dismiss="modal" aria-label="Close" #closeBtn>
      <i class="bi bi-x"></i>
    </button>
  </div>
  <div class="modal-body mt-0 mx-3">
    <div class="text-center">
      <img
        loading="lazy"
        width="296.56"
        height="188.485"
        src="https://res.cloudinary.com/dkmeznkaf/image/upload/v1670049255/img-forgotpass_rurg47.png"
      />
    </div>
    <div class="">
      <h1 class="font-24 mt-4 fw-700 color-header text-center">
        Your Account has been deactivated
      </h1>
      <p class="font-14 my-3 fw-400 color-text text-center mb-3">
        To reactivate your account, please contact support. We apologise for any
        inconvenience this may cause.
      </p>
      <div class="text-center mb-3">
        <button class="submit-btn" (click)="closeBtn.click(); navigate()">
          Contact Support
        </button>
      </div>
    </div>
  </div>
</swap-modal>
