import { Component, Inject, Optional } from '@angular/core';
import { HotToastRef } from '@ngneat/hot-toast';
import { Notification } from 'src/app/services/core/IApp';
import { GlobalsService } from '../../../services/core/globals';

@Component({
  selector: 'app-transaction-notifications',
  templateUrl: './transaction-notifications.component.html',
  styleUrls: ['./transaction-notifications.component.scss'],
})
export class TransactionNotificationsComponent {
  constructor(
    @Optional() @Inject(HotToastRef) public toastRef: HotToastRef<Notification>,
    public globals: GlobalsService
  ) {}

  ngOnInit() {
    this.countDown();
  }

  navigateMessages(notification: any) {
    this.globals.navigateByTrigger(
      notification.trigger,
      notification.biddingId
    );
    notification.markedAsRead = true;
    notification.createdAt = new Date(notification.createdAt);
    this.toastRef.close();
  }

  countDown() {
    var bar = document.getElementById('bar-close')!;
    bar.addEventListener('animationend', () => {
      this.toastRef.close();
    });
  }
}
