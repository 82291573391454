import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(private meta: Meta) {

    meta.addTags([
      {
        name: 'description',
        content:
          'ExpatSwap is a community-based platform where people with different currencies can swap with other community members on their terms and at their preferred rates. All you have to do is make a new post, set your terms, or select any of the existing deals and start swapping with any of the multiple verified users who also need the currency you have.',
      },
      {
        name: 'keywords',
        content:
          'expatswap,expat,swap,exchange,marketplace,community,blockchain,bitcoin,ethereum,altcoin,altcoins,token',
      },
      { name: 'author', content: 'ExpatSwap' },
      { name: 'robots', content: 'index, follow' },
      { name: 'theme-color', content: '#ffffff' },
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'ExpatSwap' },
      {
        property: 'og:description',
        content:
          'ExpatSwap is a community-based platform where people with different currencies can swap with other community members on their terms and at their preferred rates. All you have to do is make a new post, set your terms, or select any of the existing deals and start swapping with any of the multiple verified users who also need the currency you have.',
      },
      { property: 'og:image', content: 'assets/images/logoIcon.svg' },
      { property: 'og:url', content: 'https://expatswap.com/' },
      { property: 'og:site_name', content: 'ExpatSwap' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: '@expatswap' },
      { name: 'twitter:creator', content: '@expatswap' },
      { name: 'twitter:title', content: 'ExpatSwap' },
      {
        name: 'twitter:description',
        content:
          'ExpatSwap is a community-based platform where people with different currencies can swap with other community members on their terms and at their preferred rates. All you have to do is make a new post, set your terms, or select any of the existing deals and start swapping with any of the multiple verified users who also need the currency you have.',
      },
    ]);
  }

  updateMeta({
    title,
    description,
    image,
    url,
    siteName,
  }: {
    title: string;
    description: string;
    image: string;
    url: string;
    siteName: string;
  }) {
    this.setTitle(title);
    this.setDescription(description);
    this.setImage(image);
    this.setUrl(url);
    this.setSiteName(siteName);
  }

  private setTitle(title: string) {
    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ name: 'twitter:title', content: title });
  }

  private setDescription(description: string) {
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({
      name: 'twitter:description',
      content: description,
    });
  }

  private setImage(image: string) {
    this.meta.updateTag({ property: 'og:image', content: image });
  }

  private setUrl(url: string) {
    this.meta.updateTag({ property: 'og:url', content: url });
  }

  private setSiteName(siteName: string) {
    this.meta.updateTag({ property: 'og:site_name', content: siteName });
  }

  private setTwitterCard(card: string) {
    this.meta.updateTag({ name: 'twitter:card', content: card });
  }

  private setTwitterSite(site: string) {
    this.meta.updateTag({ name: 'twitter:site', content: site });
  }

  private setTwitterCreator(creator: string) {
    this.meta.updateTag({ name: 'twitter:creator', content: creator });
  }
  private setTwitterImage(image: string) {
    this.meta.updateTag({ name: 'twitter:image', content: image });
  }
}
