<div
  class="d-flex flex-column p-2 px-4 sidebar"
  [ngStyle]="{
    width: collapsible ? '6.5em' : '12.5em',
    transition: 'width 0.5s'
  }"
>
  <div class="col-12 border-bottom py-4 logo-container">
    <img
      loading="lazy"
      src="assets/images/logo-icon.svg"
      alt="Expatswap Logo"
      class="img-fluid logo-icon"
      routerLink="/main/home"
    />
    <img
      loading="lazy"
      src="assets/images/logo-separator.svg"
      alt="Expatswap Logo"
      class="logo"
      routerLink="/main/home"
    />
    <img
      loading="lazy"
      src="assets/images/logo-text.svg"
      alt="Expatswap Logo"
      class="logo-text"
      routerLink="/main/home"
    />
    <!-- <img
      loading="lazy"
      src="assets/images/logoIcon.svg"
      alt="Expatswap Logo"
      class="icon logo"
      routerLink="/main/home"
      *ngIf="collapsible"
    /> -->
  </div>
  <div class="col-12 d-flex flex-column justify-content-between h-100">
    <nav class="navigation container-fluid g-0 py-3">
      <li
        class="nav-item my-1"
        *ngFor="let item of navigations | slice : 0 : -2"
      >
        <a
          class="nav-link py-3 {{ item.class || '' }}"
          [routerLink]="item.link"
          [routerLinkActive]="['active']"
          data-bs-tooltip="tooltip"
          data-bs-placement="right"
          title="{{ item.name }}"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            [innerHTML]="item.icon"
          ></svg>
          <p class="mb-0 font-14">
            {{ item.name }}
          </p>
        </a>
      </li>
    </nav>
    <nav class="navigation pt-5">
      <li
        class="nav-item my-2"
        *ngFor="let item of navigations | slice : -2 : -1"
      ></li>
      <li class="nav-item my-2" *ngFor="let item of navigations | slice : -1">
        <a
          class="nav-link py-3"
          (click)="logout()"
          data-bs-tooltip="tooltip"
          data-bs-placement="right"
          title="{{ item.name }}"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            [innerHTML]="item.icon"
          ></svg>
          <p class="mb-0">
            {{ item.name }}
          </p>
        </a>
      </li>
    </nav>
  </div>
</div>
