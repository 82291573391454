<div class="card h-100" [routerLink]="route">
  <div class="card-cont d-flex flex-column">
    <div class="r-circle">
      <img [src]="item.img" alt="twt-img" />
    </div>
    <a class="title-text link-tag">
      <p class="font-16 mb-2 mt-3 fw-700">{{ item.name }}</p>
    </a>
    <div
      class="subTitle-text"
      *ngFor="let faq of filteredFaqs(item.name) | slice : 0 : 3"
    >
      <div class="font-12 mt-1 fw-400" [routerLink]="route">
        {{ faq.title }}
      </div>
    </div>
    <div class="mt-4">
      <a
        class="text-decoration-none btn-link mt-auto align-self-start font-16 fw-500"
        >View all</a
      >
    </div>
  </div>
</div>
