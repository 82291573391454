import { Injectable } from '@angular/core';
import { ShepherdService } from 'angular-shepherd';
import { AuthService } from '../api/auth/auth.service';
import { GlobalsService } from './globals';

@Injectable({
  providedIn: 'root',
})
  
export class HintsService {
  when: any;

  constructor(
    private shepherdService: ShepherdService,
    private globals: GlobalsService,
    private authService: AuthService
  ) {
    this.shepherdService.defaultStepOptions = {
      classes: 'hints-popup',
      scrollTo: false,
      cancelIcon: {
        enabled: false,
      },
      modalOverlayOpeningRadius: 10,
      modalOverlayOpeningPadding: 10,
    };
  }

  firstStageHints(modalId3: string) {
    if (
      this.globals.storage.getUserDetails()?.userWalkthrough?.step1?.completed
    )
      return;
    this.shepherdService.modal = true;
    this.shepherdService.confirmCancel = false;
    this.shepherdService.addSteps([
      {
        id: 'create-offer',
        attachTo: {
          element: '.create-offer',
          on: 'bottom',
        },
        title: 'Create offer',
        text: 'Once you enter your phone number and it is successfully verified, you will be able to proceed with using all the features of our platform.',
        buttons: [
          {
            text: 'Skip Tour',
            action: async () => {
              this.shepherdService.cancel();
              await this.skipWalkthrough(1);
            },
            classes: 'shepherd-button-secondary',
          },
          {
            text: 'Next',
            action: () => {
              this.shepherdService.next();
            },
          },
        ],
      },
      {
        id: 'swap',
        attachTo: {
          element: '.swap',
          on: 'top-end',
        },
        title: 'Swap',
        text: 'Search any of the existing offers that meet your needs and select "Swap Now" to initiate a transaction with the seller.',
        buttons: [
          {
            text: 'Skip Tour',
            action: async () => {
              this.shepherdService.cancel();
              await this.skipWalkthrough(1);
              if (
                this.globals.user.userWalkthrough['step1'].skipCount == 0 &&
                !this.globals.user.userWalkthrough['step1'].completed
              ) {
                await this.completeWalkthrough(1, true);
              }
            },
            classes: 'shepherd-button-secondary',
          },
          {
            text: 'Next',
            action: () => {
              this.globals.openModal(modalId3);
              this.shepherdService.next();
            },
          },
        ],
      },
      {
        id: 'verify',
        attachTo: {
          element: '.verify',
          on: 'left',
        },
        title: 'Verification',
        text: 'Please upgrade to get full access to the platform. You would need to verify your phone number, add your social account, and verify your identity.',
        buttons: [
          {
            text: 'Done',
            action: async () => {
              this.shepherdService.complete();
              setTimeout(() => {
                this.globals.closeModal('#' + modalId3);
              }, 2000);
              await this.completeWalkthrough(1, true);
            },
          },
        ],
      },
    ]);
    this.shepherdService.start();
  }

  secondStageHints(id: string) {
    // if (
    //   this.globals.storage.getUserDetails()?.userWalkthrough?.step2?.completed
    // )
    //   return;
    this.shepherdService.modal = true;
    this.shepherdService.confirmCancel = false;
    this.shepherdService.addSteps([
      {
        id: 'offer-created',
        attachTo: {
          element: '.offer_created_' + id,
          on: 'top',
        },
        title: 'Offer created',
        text: 'Thanks for creating your first offer. Once someone is interested in your offer, you will receive a notification to approve or decline their request. If you approve, that would be the beginning of your transaction with them. To monitor your transactions, check the <a href="/main/transaction" class="text-primary">transactions</a> page to view all your active transactions.',
        buttons: [
          {
            text: 'Done',
            action: async () => {
              this.shepherdService.complete();
              // await this.skipWalkthrough(2);
            },
          },
        ],
      },
    ]);
    this.shepherdService.start();
  }

  thirdStageHints() {
    this.shepherdService.modal = true;
    this.shepherdService.confirmCancel = false;
    this.shepherdService.addSteps([
      {
        id: 'make-offer',
        attachTo: {
          element: '.transaction',
          on: 'right',
        },
        title: 'Pending request',
        text: 'Thanks for submitting an interest in this offer. Once the seller responds to your request, you will be notified to start the transaction. You may keep browsing other offers.',
        buttons: [
          {
            text: 'Done',
            action: async () => {
              this.shepherdService.complete();
              // await this.skipWalkthrough(3);
            },
          },
        ],
      },
    ]);
    this.shepherdService.start();
  }

  fourthStageHints(username: string) {
    if (
      this.globals.storage.getUserDetails()?.userWalkthrough?.step4?.completed
    )
      return;
    this.shepherdService.defaultStepOptions = {
      classes: 'hints-popup',
      scrollTo: false,
      cancelIcon: {
        enabled: false,
      },
      when: {
        show: () => {
          const currentStepElement = this.shepherdService.tourObject
            .getCurrentStep()
            ?.getElement();
          const footer = currentStepElement?.querySelector('.shepherd-footer');
          const stepCounterContainer = document.createElement('p');
          stepCounterContainer.classList.add('shepherd-steps-count');
          const currentStep =
            this.shepherdService.tourObject.steps.indexOf(
              this.shepherdService.tourObject.getCurrentStep() as any
            ) + 1;
          stepCounterContainer.innerText = `${currentStep}/${this.shepherdService.tourObject.steps.length}`;
          const progress = Math.round(
            (currentStep / this.shepherdService.tourObject.steps.length) * 360
          );
          stepCounterContainer.style.setProperty(
            '--progress',
            `${progress}deg`
          );
          footer?.insertBefore(stepCounterContainer, footer.firstChild);
        },
      },
    };
    this.shepherdService.addSteps([
      {
        id: 'type-message',
        attachTo: {
          element: '.type-message',
          on: 'top-start',
        },
        title: 'Type a message and share payment details',
        text: `Welcome to your conversation with ${username}. Kindly provide your payment details to facilitate the transfer of funds.`,
        buttons: [
          {
            text: 'Skip Tour',
            action: async () => {
              this.shepherdService.cancel();
              await this.completeWalkthrough(4, true);
            },
            classes: 'shepherd-button-secondary',
          },
          {
            text: 'Next',
            action: () => {
              this.shepherdService.next();
            },
          },
        ],
      },
      {
        id: 'type-message',
        attachTo: {
          element: '.type-message',
          on: 'top-start',
        },
        title: 'Rules for Transacting',
        text: `1. 🚦 Who's Up First? If your ex-ID pops up in the yellow message, you're sending first. <br> 2. 💸 Send & Confirm: Attach proof after payment and await confirmation. <br> 3. 🔄 Money Received: Hit the button once both confirmations are in!`,
        buttons: [
          {
            text: 'Skip Tour',
            action: async () => {
              this.shepherdService.cancel();
              await this.completeWalkthrough(4, true);
            },
            classes: 'shepherd-button-secondary',
          },
          {
            text: 'Next',
            action: () => {
              this.shepherdService.next();
            },
          },
        ],
      },
      {
        id: 'type-message',
        attachTo: {
          element: '.type-message',
          on: 'top-start',
        },
        title: 'Transfer or wait for transfer',
        text: 'Confirm payment details and check that funds have been received into the designated account',
        buttons: [
          {
            text: 'Skip Tour',
            action: async () => {
              this.shepherdService.cancel();
              await this.completeWalkthrough(4, true);
            },
            classes: 'shepherd-button-secondary',
          },
          {
            text: 'Next',
            action: () => {
              this.shepherdService.next();
            },
          },
        ],
      },
      {
        id: 'tranx-details',
        attachTo: {
          element: '.tranx-details',
          on: 'left',
        },
        title: 'Upload proof/Confirm money received',
        text: 'Provide evidence of transferring funds to the other party and click to confirm receipt once the agreed-upon terms have been fulfilled and the funds have been received.',
        buttons: [
          {
            text: 'Done',
            action: async () => {
              this.shepherdService.complete();
              await this.completeWalkthrough(4, true);
            },
          },
        ],
      },
    ]);
    this.shepherdService.start();
  }

  async completeWalkthrough(step: number, completed = false) {
    this.globals.user.userWalkthrough['step' + step] = {
      ...this.globals.user.userWalkthrough['step' + step],
      completed,
    };
    await this.authService.updateProfile(
      {
        firstName: this.globals.user?.name?.first,
        lastName: this.globals.user?.name?.last,
        userWalkthrough: this.globals.user.userWalkthrough,
      },
      '🎉 Step ' + step + ' completed!!!'
    );
  }

  async skipWalkthrough(step: number) {
    this.globals.user.userWalkthrough['step' + step] = {
      ...this.globals.user.userWalkthrough['step' + step],
      skipCount:
        this.globals.user.userWalkthrough['step' + step].skipCount == 0
          ? 0
          : this.globals.user.userWalkthrough['step' + step].skipCount - 1,
      completed:
        this.globals.user.userWalkthrough['step' + step].skipCount <= 1 &&
        !this.globals.user.userWalkthrough['step' + step].completed,
    };
    await this.authService.updateProfile(
      {
        firstName: this.globals.user?.name?.first,
        lastName: this.globals.user?.name?.last,
        userWalkthrough: this.globals.user.userWalkthrough,
      },
      '🎉 Step ' + step + ' completed!!!'
    );
  }
}
