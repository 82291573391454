import { Injectable } from '@angular/core';
import { GlobalsService } from '../../core/globals';
import { RequestService } from '../../core/request';

const routes = {
  addPicture: 'swap/upload-picture',
  editPicture: 'swap/edit-picture',
  editPassword: 'swap/change-password',
  deactivateAccount: 'auth/deactivate',
  editUserSettings: 'profile/settings',
};

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private api: RequestService, private globals: GlobalsService) {}

  //Function to add user profile picture
  async editNotificationSettings(data: any) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.patch(routes.editUserSettings, data);
        this.globals.toast.success('Settings updated successfully');
        this.globals.spinner.hide();
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  //Function to deactivate user
  async deactivateUser(reason: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.patch(routes.deactivateAccount, {
          reason,
        });
        this.globals.toast.success(resp.message);
        this.globals.spinner.hide();
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  async getPaymentMethods() {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.get('settings/payment-methods');
        this.globals.paymentMethods = resp.data.items;
        this.globals.storage.setPaymentMethods(resp.data.items);
        this.globals.spinner.hide();
        resolve(resp);
      } catch (err: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(err.message || 'Internal Server Error');
        reject(err);
      }
    });
  }

  async getHelpCategories() {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.get('settings/help-categories');
        this.globals.helpCategories = resp.data.items;
        this.globals.storage.setHelpCategories(resp.data.items);
        this.globals.spinner.hide();
        resolve(resp);
      } catch (err: any) {
        this.globals.spinner.hide();
        reject(err || 'Internal Server Error');
      }
    });
  }

  async getFaqs() {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.get('settings/faqs');
        this.globals.faqs = resp.data.items;
        this.globals.storage.setFaqs(resp.data.items);
        this.globals.spinner.hide();
        resolve(resp);
      } catch (err: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(err.message);
        reject(err);
      }
    });
  }
}
