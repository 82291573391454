import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { SocketService } from '../api/socketio/socket.service';
import { GlobalsService } from '../core/globals';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private globals: GlobalsService,
    private socketService: SocketService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(catchError((error) => this.handleError(error)));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 401) {
      this.socketService.disconnectSocket();
      this.globals.storage.clearAllExceptRememberMe();
      this.globals.spinner.hide();
      this.globals.router.navigate(['auth']);
    }

    if (
      error.status === 403 &&
      error.error.message?.toLowerCase() === 'pending ratings review'
    ) {
      this.globals.checkForReview(error.error.data);
    }

    if (
      error.status === 403 &&
      error.error.message?.toLowerCase() === 'account deactivated'
    ) {
      this.socketService.disconnectSocket();
      this.globals.deactivateUser();
    }

    return throwError(error);
  }
}
