import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent {
  @Input() modalId: string = '';

  @Output() emitTermsAndConditions: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  enableButton: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.checkTermsAndConditions();
  }

  checkTermsAndConditions() {
    let termsAndConditions = document.getElementById(
      'terms-and-conditions'
    ) as HTMLElement;
    termsAndConditions.addEventListener('scroll', () => {
      if (
        Math.ceil(
          termsAndConditions.scrollTop + termsAndConditions.clientHeight
        ) >= termsAndConditions.scrollHeight
      ) {
        this.enableButton = true;
      }
    });
  }
}
