import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalsService } from '../../../services/core/globals';
import { environment } from '../../../../environments/environment';
import { phoneValidator } from '../../validators';
import {
  getAuth,
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithCredential,
  signInWithPhoneNumber,
} from 'firebase/auth';
import { initializeApp } from 'firebase/app';

@Component({
  selector: 'verify-phone-nos',
  templateUrl: './verify-phone-nos.component.html',
  styleUrls: ['./verify-phone-nos.component.scss'],
})
export class VerifyPhoneNosComponent {
  @Input() modalId = '';
  @Input() modalId2 = '';
  @Input() phone = '';
  @Output() verifyPhone = new EventEmitter<any>();
  @ViewChild('closeModal') closeModal: any;

  phoneVerificationForm: FormGroup = new FormGroup({
    phone: new FormControl('', [Validators.required, phoneValidator()]),
    verificationCode: new FormControl('', [
      Validators.required,
      Validators.maxLength(6),
      Validators.pattern('^[0-9]+$'),
    ]),
  });

  recaptchaVerifier: RecaptchaVerifier | undefined;

  //jason
  // firebaseApp: any;
  firebaseAuth: any;

  constructor(public globals: GlobalsService) {
    // Initialize Firebase App
    const app = initializeApp(environment.firebaseConfig);

    this.firebaseAuth = getAuth(app);

    //Jason
    // Promise.all([import('firebase/auth'), import('firebase/app')]).then(
    //   (res) => {
    //     this.firebaseApp = res[1].default.initializeApp(
    //       environment.firebaseConfig
    //     );
    //     this.firebaseAuth = res[1].default.auth;
    //   }
    // );
  }

  ngOnInit() {
    if (this.phone) {
      this.phoneVerificationForm.controls['phone'].setValue(this.phone);
    }
  }

  onSubmit() {
    if (this.phoneVerificationForm.valid) {
      this.signInWithCredential();
    }
  }

  onValueChange(input: string, value: string) {
    this.phoneVerificationForm.get(input)?.setValue(value);
    this.phoneVerificationForm.get(input)?.markAsTouched();
    this.phoneVerificationForm.get(input)?.markAsDirty();
    this.phoneVerificationForm.updateValueAndValidity();
  }

  generateOTP() {
    this.renderRecaptchaVerifier().then((res) => {
      this.signInWithPhoneNumber(res);
    });
  }

  async pasteCode() {
    try {
      const code = await navigator.clipboard.readText();
      this.phoneVerificationForm.get('verificationCode')?.setValue(code);
    } catch (error) {
      console.error(error);
    }
  }

  private signInWithCredential() {
    const otp = this.phoneVerificationForm.value.verificationCode as string;
    const verificationId = this.globals.storage.getOtpVerificationId();
    // const credential = this.firebaseAuth.PhoneAuthProvider.credential(
    //   verificationId,
    //   otp
    // );

    const credential = PhoneAuthProvider.credential(verificationId, otp);

    signInWithCredential(this.firebaseAuth, credential)
      .then((res: any) => {
        console.log('here is our otp', otp);
        console.log('here is our verificationId', verificationId);
        this.verifyPhone.emit(this.phoneVerificationForm.value);
        this.phoneVerificationForm.reset();
        this.closeModal.nativeElement.click();
        this.globals.openModal(this.modalId2);
      })
      .catch((error: any) => {
        this.globals.mixpanel.verifyTier1Event(
          this.globals.user?.username,
          this.phoneVerificationForm.value.phone as string,
          'failed',
          error
        );

        console.error('Error during Firebase authentication:', error);
      });
  }

  //jason
  // private async renderRecaptchaVerifier() {
  //   const recaptchaVerifier = new this.firebaseAuth.RecaptchaVerifier(
  //     'otp-button',
  //     {
  //       size: 'invisible',
  //     }
  //   );
  //   recaptchaVerifier.render();
  //   return recaptchaVerifier;
  // }

  private async renderRecaptchaVerifier() {
    if (!this.recaptchaVerifier) {
      this.recaptchaVerifier = new RecaptchaVerifier(
        this.firebaseAuth,
        'otp-button',
        {
          size: 'invisible', // or 'normal' if you want it visible
          callback: () => {},
          'expired-callback': () => {},
        }
      );
      await this.recaptchaVerifier.render();
    }
    return this.recaptchaVerifier;
  }

  private signInWithPhoneNumber(recaptchaVerifier: any) {
    const phoneNumber = this.phoneVerificationForm.value.phone as string;

    //jason
    // this.firebaseAuth()
    //   .signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
    //   .then((resp: any) => {
    //     this.globals.toast.success('OTP sent successfully');
    //     this.globals.storage.setOtpVerificationId(resp.verificationId);
    //     recaptchaVerifier.clear();
    //   })
    //   .catch((error: any) => {
    //     this.globals.toast.error(error.message);
    //     recaptchaVerifier.clear();
    //   });

    signInWithPhoneNumber(this.firebaseAuth, phoneNumber, recaptchaVerifier)
      .then((resp: any) => {
        console.log('here is our phone number', phoneNumber);
        this.globals.toast.success('OTP sent successfully');
        this.globals.storage.setOtpVerificationId(resp.verificationId);
        recaptchaVerifier.clear();
      })
      .catch((error: any) => {
        this.globals.toast.error(error.message);
        recaptchaVerifier.clear();
        // console.error('Error during Firebase authentication:', error);
        console.error('Error during OTP request:', error);

        if (error instanceof Error) {
          // `Error` is a general JavaScript error class
          console.error('Error Code:', (error as any).code); // Type cast to access code
          console.error('Error Message:', error.message);
          console.error('Full Error Object:', JSON.stringify(error, null, 2)); // Logs the entire error as a formatted JSON string
        } else {
          console.error('Unknown Error:', error);
        }
      });
  }
}
