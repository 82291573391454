import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'swap-input',
  templateUrl: './swap-input.component.html',
  styleUrls: ['./swap-input.component.scss'],
})
export class SwapInputComponent {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() value: string = '';
  @Input() type: string = 'text';
  @Input() name: string = '';
  @Input() required: boolean = false;
  togglePassword: boolean = false;

  @Output() emitPassword: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  onChange(event: any) {
    this.valueChange.emit(event.target.value);
  }

  togglePasswordVisibility() {
    this.emitPassword.emit((this.togglePassword = !this.togglePassword));
  }
}
