import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Country } from 'src/app/services/core/IApp';
import { GlobalsService } from '../../../services/core/globals';

@Component({
  selector: 'phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
})
export class PhoneInputComponent {
  @Input() phoneNumber: string = '';
  @Input() calling_code: string = '+1';
  @Input() otpButton: boolean = false;
  @Input() enableOtpButton: boolean = false;
  @Output() emitPhoneNumber: EventEmitter<string> = new EventEmitter<string>();
  @Output() emitOtpSent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('searchInput') searchInput: any;
  dialCodeSearch: string = '';
  selectedCountryPhone: Country | undefined;
  phoneCodeData: Array<Country> = [];
  showDropdown: boolean = false;
  nationalNumber: string = '';
  timer: number = 0;

  constructor(public globals: GlobalsService) {
    this.phoneCodeData = globals.countries;
  }

  ngOnChanges() {
    this.setSelectDialCode();
  }

  ngOnDestroy() {
    this.showDropdown = false;
    this.nationalNumber = '';
  }

  setSelectDialCode() {
    
    this.selectedCountryPhone = this.phoneCodeData.find(
      (item) => item.phone_code === Number(this.calling_code?.replace('+', ''))
    );
    if (!this.phoneNumber) {
      this.nationalNumber = '';
      return;
    }
  }

  selectCode(country: Country) {
    this.selectedCountryPhone = country;
    this.showDropdown = false;
    this.searchInput = '';
    this.emitPhoneNumber.emit(`+${country.phone_code}${this.nationalNumber}`);
  }

  openDropdown() {
    this.showDropdown = true;
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 100);
    document.addEventListener('mousedown', (e: any) => {
      if (!e.target?.classList.contains('dropdown-item')) {
        document.querySelector('.dropdown-menu')?.classList.remove('show');
        this.showDropdown = false;
      }
    });
  }

  submitPhoneNumber(event?: any) {
    this.nationalNumber = event.target.value;
    let phoneNumber = `+${
      this.selectedCountryPhone?.phone_code + this.nationalNumber
    }`;
    this.emitPhoneNumber.emit(phoneNumber);
  }

  startCountDown() {
    this.emitOtpSent.emit();
    this.timer = 60;
    const interval = setInterval(() => {
      this.timer--;
      if (this.timer === 0) {
        clearInterval(interval);
      }
    }, 1000);
  }
}
