<div>
  <div class="notification-container">
    <img
      loading="lazy"
      [src]="toastRef.data.avatar || 'assets/icons/user.svg'"
      alt=""
      width="40"
      style="border-radius: 50%"
      class="m-auto"
    />
    <div class="notification-content">
      <h5 class="font-14 mb-0 fw-bolder">{{ toastRef.data.title }}</h5>
      <p
        class="mb-0 truncate font-12"
        [innerHTML]="globals.replaceCurlies(toastRef.data.message)"
      ></p>
    </div>
  </div>
</div>
<div
  (click)="navigateMessages(toastRef.data)"
  class="pointer position-absolute top-0 start-0 bottom-0 end-0"
></div>
<div
  id="bar-close"
  class="bg-pry position-absolute start-0 bottom-0 end-0 animate-bar-close"
></div>
