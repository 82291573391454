import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() showSwapBtn!: boolean;
  @Input() showCreateAcctBtn!: boolean;
  @Input() showSignInAcctBtn!: boolean;
  @Input() showHelpBtn!: boolean;
  @Input() showHaveAnAcctBtn!: boolean;

  elementToggled!: any;

  constructor() {}

  ngOnInit(): void {}

  openNav() {
    this.elementToggled = window.document.getElementById('mobile_menu')!;
    this.elementToggled.style.transform = 'translateX(0)';
  }

  closeNav() {
    this.elementToggled = window.document.getElementById('mobile_menu')!;
    this.elementToggled.style.transform = 'translateX(-100%)';
  }

  showHelpDropdown(id: string) {
    let helpDropDownMenu = document.getElementById(id);
    helpDropDownMenu?.classList.toggle('show');

    // Close the dropdown menu if the user clicks outside of it
    document.addEventListener('click', function (event: any) {
      if (!event.target.matches('.dropdown-toggle')) {
        let dropdowns = document.getElementsByClassName('dropdown-menu');
        let i;
        for (i = 0; i < dropdowns.length; i++) {
          let openDropdown = dropdowns[i];
          if (openDropdown.classList.contains('show')) {
            openDropdown.classList.remove('show');
          }
        }
      }
    });
  }
}
