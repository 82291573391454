import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Ratings_Reviews, Transaction } from '../../../services/core/IApp';
import { GlobalsService } from '../../../services/core/globals';

@Component({
  selector: 'rating-review',
  templateUrl: './rating-review.component.html',
  styleUrls: ['./rating-review.component.scss'],
})
export class RatingReviewComponent implements OnInit {
  @Input() modalId: string = '';

  @Input() ratingReview: Transaction | any;

  @ViewChild('closeBtn') closeBtn: any;

  @Output() emitRatingReview: EventEmitter<Ratings_Reviews> =
    new EventEmitter<Ratings_Reviews>();

  ratingsReviewForm: FormGroup = new FormGroup({
    comment: new FormControl('', Validators.required),
    rating: new FormControl(null, Validators.required),
    revieweeId: new FormControl('', Validators.required),
    biddingId: new FormControl('', Validators.required),
  });

  emojis: any[] = [
    {
      img: 'assets/images/Weary Face.svg',
      name: 'Bad',
      value: 1,
    },
    {
      img: 'assets/images/Slightly Frowning Face.svg',
      name: 'Not Bad',
      value: 2,
    },
    {
      img: 'assets/images/Slightly Smiling Face.svg',
      name: 'Average',
      value: 3,
    },
    {
      img: 'assets/images/Beaming Face With Smiling Eyes.svg',
      name: 'Good',
      value: 4,
    },
    {
      img: 'assets/images/Star Struck.svg',
      name: 'Impressive',
      value: 5,
    },
  ];

  constructor(public globals: GlobalsService) {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.ratingsReviewForm.patchValue({
      revieweeId:
        this.globals.user?._id == this.ratingReview?.bidding?.buyer?.userId?._id
          ? this.ratingReview?.bidding?.seller?.userId?._id
          : this.ratingReview?.bidding?.buyer?.userId?._id,
      biddingId: this.ratingReview?._id,
    });
  }

  home() {
    this.globals.router.navigateByUrl('/main/home');
  }

  onSubmit() {
    this.closeBtn?.nativeElement?.click();
    this.emitRatingReview.emit(this.ratingsReviewForm.value);
    this.ratingsReviewForm.reset({
      comment: '',
      rating: null,
      revieweeId: '',
      biddingId: '',
    });
  }
}
