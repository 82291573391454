<section class="parent bg-purple position-relative">
  <img
    loading="lazy"
    class="position-absolute md-no-display"
    src="assets/images/pinkLine.svg"
    alt=""
  />
  <div class="row py-5 position-relative">
    <div class="col-md-3 mr-4 md-mb-30" routerLink="/">
      <img
        loading="lazy"
        class="pointer mb-3 w-wk md-w-auto sm-w-180"
        src="assets/images/expatswapFooterLogo.svg"
        alt=""
      />
      <div class="font-14 fw-400 color-white">
        Expatswap is a regulated solution that reflects trust in your community
        and facilitates financial transactions within your network.
      </div>
    </div>
    <div class="col-md-2"></div>
    <div class="col-md-1"></div>
    <div class="col-md-2">
      <div class="font-18 fw-500 color-white mb-3">Our Services</div>
      <div class="fw-400 font-14 color-white2 mb-3 pointer">Expatswap blog</div>
    </div>

    <div class="col-md-2">
      <div class="font-18 fw-500 color-white mb-3">Others</div>
      <div
        class="fw-400 font-14 color-white2 mb-3 pointer"
        routerLink="/contact-us"
      >
        Contact us
      </div>
      <div class="fw-400 font-14 color-white2 mb-3 pointer" routerLink="/faqs">
        FAQ
      </div>
    </div>

    <div class="col-md-2">
      <div class="font-18 fw-500 color-white mb-3">Security</div>
      <div
        class="fw-400 font-14 color-white2 mb-3 pointer"
        routerLink="/contact-us"
      >
        Report spam or fraud
      </div>
      <div
        class="fw-400 font-14 color-white2 mb-3 pointer"
        routerLink="/terms-service"
      >
        Terms of Service
      </div>
      <div
        class="fw-400 font-14 color-white2 pointer"
        routerLink="/privacy-policy"
      >
        Privacy Policy
      </div>
    </div>
  </div>

  <div class="my-4">
    <hr class="color-white" />
  </div>
  <div class="d-flex justify-content-between pb-4 md-flex-column">
    <div class="fw-400 font-16 color-white md-mb-30">
      © Copyright {{ currentYear }}, All Rights Reserved
    </div>
    <div class="w-120 justify-content-between d-flex mr-48">
      <div class="r-circle mr-13">
        <a
          class="facebook"
          target="_blank"
          href="//www.facebook.com/expatswapfx"
          ><span class="">
            <img src="assets/icons/facebook-white.svg" alt="twt-img" /> </span
        ></a>
      </div>
      <div class="r-circle mr-13">
        <a
          class="instagram"
          target="_blank"
          href="//www.instagram.com/expatswapfx"
          ><span class="">
            <img src="assets/icons/instagram-white.svg" alt="twt-img" /> </span
        ></a>
      </div>
      <div class="r-circle mr-13">
        <a
          class="linkedin"
          target="_blank"
          href="//www.linkedin.com/company/expatswap"
          ><span class="">
            <img src="assets/icons/linkedin-white.svg" alt="twt-img" /> </span
        ></a>
      </div>
      <div class="r-circle">
        <a class="twitter" target="_blank" href="//twitter.com/expatswap"
          ><span class="">
            <img src="assets/icons/twitter-white.svg" alt="twt-img" /> </span
        ></a>
      </div>
    </div>
  </div>
</section>
