import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { environment } from '../../../../environments/environment';
import { Offer } from '../../core/IApp';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  constructor() {}

  initializeMixpanel() {
    mixpanel.init(environment.mixpanel.token, {
      debug: environment.mixpanel.debug,
      track_pageview: true,
      persistence: 'localStorage',
    });
  }

  identifyUser(user: string) {
    mixpanel.identify(user);
  }

  signInUserEvent({
    email,
    username,
    authenticationMethod,
    status,
  }: {
    email: string;
    username: string;
    authenticationMethod: string;
    status: string;
  }) {
    mixpanel.track('Sign In', {
      user: username || email,
      status,
      authenticationMethod,
    });
  }

  signOutUserEvent(data: any) {
    mixpanel.track('Sign Out', {
      user: data.username || data.email,
      status: data.status,
    });
  }

  updateOfferEvent(offer: Offer, status: string) {
    mixpanel.track('Update Offer', {
      ...offer,
      status,
    });
  }

  createOfferEvent(offer: Offer, status: string) {
    mixpanel.track('Create Offer', {
      ...offer,
      status,
    });
  }

  buyOfferEvent(offer: Offer, status: string) {
    mixpanel.track('Buy Offer', {
      ...offer,
      status,
    });
  }

  approveOfferEvent(transaction_id: string, status: string) {
    mixpanel.track('Approve Offer', {
      transaction_id,
      status,
    });
  }

  rejectOfferEvent(transaction_id: string, status: string) {
    mixpanel.track('Reject Offer', {
      transaction_id,
      status,
    });
  }

  searchCurrencyPairsEvent(search: string) {
    mixpanel.track('Search Currency Pairs', {
      search,
    });
  }

  reportTransaction(
    transaction_id: string,
    subject: string,
    ticket_category: string,
    message: string,
    status: string
  ) {
    mixpanel.track('Report Transaction', {
      transaction_id,
      subject,
      ticket_category,
      message,
      status,
    });
  }

  signUpEvent({
    firstName,
    lastName,
    authenticationMethod,
    email,
    username,
    referredBy,
    status,
  }: {
    firstName?: string;
    lastName?: string;
    authenticationMethod: string;
    email: string;
    username?: string;
    referredBy?: string;
    status: string;
  }) {
    mixpanel.track('Sign Up', {
      firstName,
      lastName,
      authenticationMethod,
      email,
      username,
      referredBy,
      status,
    });
  }

  verifyTier1Event(
    username: string,
    phone: string,
    status: string,
    error?: any
  ) {
    mixpanel.track('Tier 1 Verification', {
      username,
      phone,
      status,
      error,
    });
  }

  verifyTier2Event(
    username: string,
    social_account: string,
    type: string,
    status: string,
    error?: any
  ) {
    mixpanel.track('Tier 2 Verification', {
      username,
      social_account,
      type,
      status,
      error,
    });
  }
}
