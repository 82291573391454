import { Component, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'swap-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() type: string = 'button';
  @Input() disabled: boolean = false;
  @Input() ngStyle: any = {};
  @Input() ngClass: any = {};
  @Input() onClick: EventEmitter<any> = new EventEmitter();
  @Input() emitValue: any = null;
  @Input() routerLink: string = '';
}