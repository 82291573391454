import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Plan } from 'src/app/services/core/IApp';

@Component({
  selector: 'change-plan',
  templateUrl: './change-plan.component.html',
  styleUrls: ['./change-plan.component.scss'],
})
export class ChangePlanComponent {
  @Input() modalId: string = '';

  @Output() emitPlan: EventEmitter<{ plan: string; period: string }> =
    new EventEmitter<{ plan: string; period: string }>();

  @ViewChild('closeBtn') closeBtn: any;

  @Input() plan: Plan | any;

  @Input() plans: Plan[] = [];

  @Input() period: string = 'yearly';

  plansForm: FormGroup = new FormGroup({
    plan: new FormControl('', Validators.required),
    period: new FormControl('', Validators.required),
  });

  periods: { period: string; amount: string }[] = [
    { period: 'monthly', amount: '$0' },
    { period: 'yearly', amount: '$0' },
  ];

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['plan'] || changes['period']) {
      this.updateFormValues();
    }
  }

  updateFormValues() {
    if (this.plan) {
      this.plansForm.patchValue({
        plan: this.plan.name,
        period: this.period,
      });
      this.periods[0].amount = `${this.plan.price_per_month}`;
      this.periods[1].amount = `${this.plan.price_per_year}`;
    }
  }

  calculateExpiryDate(): string {
    const currentDate = new Date();
    const expiryDate = new Date(currentDate);

    if (this.period === 'yearly') {
      expiryDate.setFullYear(expiryDate.getFullYear() + 1);
    } else if (this.period === 'monthly') {
      expiryDate.setMonth(expiryDate.getMonth() + 1);
    }

    const day = expiryDate.getDate();
    let suffix = 'th';
    if (day === 1 || day === 21 || day === 31) {
      suffix = 'st';
    } else if (day === 2 || day === 22) {
      suffix = 'nd';
    } else if (day === 3 || day === 23) {
      suffix = 'rd';
    }
    const month = expiryDate.toLocaleDateString('en-US', { month: 'long' });
    const year = expiryDate.getFullYear();
    return `${month} ${day}${suffix}, ${year}`;
  }

  selectDropdown(event: any) {
    event.preventDefault();
    const mainElement = event.target;
    const element = document.querySelectorAll('.dropdown');
    element.forEach((el) => {
      if (
        el.classList.contains('active') &&
        el !== event.target.nextElementSibling
      ) {
        el.classList.remove('active');
      }
    });

    mainElement.classList.toggle('active');

    document.addEventListener('mousedown', (e: any) => {
      if (mainElement?.classList.contains('dropdown-option')) {
        element.forEach((el) => {
          if (el.classList.contains('active')) {
            el.classList.remove('active');
          }
        });
      }
    });
  }

  selectPlan(plan: string) {
    this.plansForm.controls['plan'].setValue(plan);
    this.plan = this.plans.find((p) => p.name === plan);
    this.periods[0].amount = `${this.plan.price_per_month}`;
    this.periods[1].amount = `${this.plan.price_per_year}`;
  }

  selectPeriod({ period, amount }: { period: string; amount: string }) {
    this.plansForm.controls['period'].setValue(period);
    this.period = period;
  }

  onSubmit() {
    if (this.plansForm.valid) {
      this.emitPlan.emit({
        plan: this.plansForm.controls['plan'].value,
        period: this.plansForm.controls['period'].value,
      });
      this.closeBtn.nativeElement.click();
    }
  }
}
