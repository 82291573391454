import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'extend-time',
  templateUrl: './extend-time.component.html',
  styleUrls: ['./extend-time.component.scss'],
})
export class ExtendTimeComponent {
  @Input() time: string = '';
  @Input() modalId: string = '';
  @Input() requestor: string = '';
  @Output() emitConfirmation: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}
}
