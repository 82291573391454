import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Offer } from '../../../services/core/IApp';
import { GlobalsService } from '../../../services/core/globals';

@Component({
  selector: 'app-offers-modal',
  templateUrl: './offers-modal.component.html',
  styleUrls: ['./offers-modal.component.scss'],
})
export class OffersModalComponent implements OnInit {
  @Input() offers: Offer | undefined;
  showComponent = true;
  @Input() modalId: string = '';
  @ViewChild('closeModal') closeModal: any;

  constructor(public globals: GlobalsService) {}

  ngOnInit(): void {
    const url = this.globals.router.url;
    const match = url.match(/^\/main\/home/);

    this.showComponent = !match;
  }

  onClose() {
    this.closeModal.nativeElement.click();
  }
  onSwap(offerId?: string) {
    if (!offerId) return;
    this.onClose();
    this.globals.router.navigateByUrl(`/main/home?offerId=${offerId}`);
  }

  buyerOfferModal(res: any) {
    this.onClose();
  }
}
