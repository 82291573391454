// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  escrowEnabled: false,
  // apiBaseURL: 'https://api.expatswap.com/api/',
  apiBaseURL: 'https://refapi.expatswap.com/api/',

  // apiBaseURL: 'http://localhost:3000/api/',
  currencyApiBaseURL:
    'https://v6.exchangerate-api.com/v6/338e71a5109797888911d2cb/pair/',
  googleClientId:
    '482016136889-de4lkf8c5fv7jnv4i31a7ovcfsnku2gi.apps.googleusercontent.com',
  verificationURL: 'https://deverify.expatswap.com',
  facebookClientId: '2143845802454004',
  // firebaseConfig: {
  //   apiKey: 'AIzaSyAW23AVhEQDk-T5TsfhaIfHaVtHFhy6MBc',

  //   authDomain: 'expatswap-29a49.firebaseapp.com',
  //   databaseURL: 'https://expatswap-29a49-default-rtdb.firebaseio.com',
  //   projectId: 'expatswap-29a49',
  //   storageBucket: 'expatswap-29a49.appspot.com',
  //   messagingSenderId: '482016136889',
  //   appId: '1:482016136889:web:7f5750ef240987a90b1568',
  //   measurementId: 'G-ZCEGNV9VJ5',
  // },

  firebaseConfig: {
    apiKey: 'AIzaSyAW23AVhEQDk - T5TsfhaIfHaVtHFhy6MBc',
    authDomain: 'expatswap-29a49.firebaseapp.com',
    databaseURL: 'https://expatswap-29a49-default-rtdb.firebaseio.com',
    projectId: 'expatswap-29a49',
    storageBucket: 'expatswap-29a49.appspot.com',
    messagingSenderId: '482016136889',
    appId: '1:482016136889:web:7f5750ef240987a90b1568',
    measurementId: 'G-ZCEGNV9VJ5',
  },

  stripeSecretKey:
    'pk_test_51NrzXMGU562yOts60v7x6kWjOg0NbISkLAUjM17vGpk2bndOOYUVDDZXmzfx2ed3tDEbt0EZCqQsmg1Uic4USPfK00xZZATQX0',
  mixpanel: {
    token: '6a9d7869b6e845b4921804ff62a6f196',
    debug: true,
  },
};
