import { Component, Input, ViewChild } from '@angular/core';
import { Transaction } from '../../../services/core/IApp';
import { GlobalsService } from '../../../services/core/globals';

@Component({
  selector: 'activities-modal',
  templateUrl: './activities-modal.component.html',
  styleUrls: ['./activities-modal.component.scss'],
})
export class ActivitiesModalComponent {
  @Input() modalId: string = '';
  @Input() activity: Transaction | any;
  @Input() utc_offset: string = '';
  @Input() buyerPaymentProof: string = '';
  @Input() sellerPaymentProof: string = '';
  showSection = true;
  showSection2 = true;
  activeTab: number = 0;
  @ViewChild('closeBtn') closeBtn: any;

  navTabs = [
    {
      name: 'Transaction Detail',
      tab: 0,
    },
    {
      name: 'Document',
      tab: 1,
    },
  ];

  constructor(public globals: GlobalsService) {}

  calculateCurrency(val1: any, val2: any) {
    return Number(val1) * Number(val2);
  }

  navigate(user_id: string | undefined) {
    this.closeBtn.nativeElement.click();
    this.globals.router.navigateByUrl(
      this.globals.user?.id === user_id
        ? '/main/profile'
        : `/main/profile/${user_id}`
    );
  }

  savePDF() {
    let modal = document.getElementById(`${this.modalId}`) as HTMLElement;
    let modalContent = modal.querySelector('.modal-content') as HTMLElement;

    // Dynamically import dom-to-image-more and jspdf
    Promise.all([import('dom-to-image'), import('jspdf')]).then(
      ([domtoImageModule, jspdfModule]) => {
        const domtoImage = domtoImageModule.default;
        const { jsPDF } = jspdfModule;

        // Get the element
        domtoImage
          .toPng(modalContent, { cacheBust: true })
          .then((dataUrl: any) => {
            let imgData = dataUrl;
            let doc = new jsPDF('p', 'mm', 'a4');
            let width = doc.internal.pageSize.getWidth();
            let height = doc.internal.pageSize.getHeight();
            doc.addImage(imgData, 'PNG', 0, 0, width, height);
            doc.save('transaction_receipt.pdf');
          });
      }
    );
  }
}
