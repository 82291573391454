import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'out-of-time',
  templateUrl: './out-of-time.component.html',
  styleUrls: ['./out-of-time.component.scss'],
})
export class OutOfTimeComponent implements OnInit {
  @Input() modalId: string = '';
  @Output() emitExtentionConfirmation: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  
  constructor() {}

  ngOnInit(): void {}
}
