<div
  class="row justify-content-between g-0 sm-px-2 px-4 navbar flex-nowrap gap-2 width1"
  [ngClass]="isSidebarOpen ? 'width1' : 'width2'"
>
  <div class="col col-md-3 d-flex align-items-center">
    <div
      class="pointer hambug d-md-block me-2"
      (click)="toggleSidebar.emit((isSidebarOpen = !isSidebarOpen))"
    >
      <img loading="lazy" src="assets/icons/hamburger.svg" alt="menu" />
    </div>
    <p class="fw-bolder sm-font-16 h5 mb-0">{{ title.split("-")[0].trim() }}</p>
  </div>
  <div class="col d-flex justify-content-end align-items-center gap-3">
    <button
      data-bs-toggle="modal"
      attr.data-bs-target="#{{ modalId }}"
      class="createBtn create-offer"
      (click)="resetOffer()"
    >
      <img loading="lazy" src="assets/icons/plus-icon-2.svg" alt="Plus Icon" />
      Create Offer
    </button>

    <button
      class="notification"
      [attr.data-count]="
        globals.numberOfUnreadMessages > 9
          ? '9+'
          : globals.numberOfUnreadMessages
      "
      (click)="selectDropdown($event)"
      [ngClass]="{ active: globals.unreadMessages }"
    >
      <img
        loading="lazy"
        src="assets/icons/notification-bell-icon.svg"
        alt="Notification Icon "
        (click)="selectDropdown($event)"
      />
      <div class="dropdown-options">
        <div
          class="col-12 d-flex justify-content-between align-items-center border-bottom p-3"
        >
          <p class="mb-0 fw-500 fw-bolder">Notifications</p>
          <a
            role="button"
            class="text-decoration-none text-muted text-secondary fw-500"
            (click)="markAsRead('')"
          >
            <img
              src="assets/icons/notification-checks.svg"
              alt="checkmark icon"
            />
            Mark all as read</a
          >
        </div>
        <div class="scroll-container">
          <a
            *ngFor="let notification of globals.notifications"
            class="dropdown-option gap-2 align-items-start justify-content-between text-decoration-none text-muted"
            (click)="
              markAsRead(
                notification._id,
                notification.trigger,
                notification.biddingId
              )
            "
          >
            <img
              [src]="notification.avatar || 'assets/icons/user.svg'"
              alt="Notif Img"
            />
            <div class="col-9 text-start">
              <p class="mb-0 font-14 text-dark fw-500">
                {{ notification.title | titlecase }}
              </p>
              <p
                class="mb-2 font-12 text-muted"
                [innerHTML]="globals.replaceCurlies(notification.message)"
              ></p>
              <p class="text-muted mb-0 fw-500 font-12">
                {{ notification.createdAt | dateAsAgo }}
              </p>
            </div>
            <div class="col-1 d-flex align-items-center justify-content-end">
              <span
                class="dot"
                *ngIf="notification.markedAsRead == false"
              ></span>
            </div>
          </a>
        </div>
      </div>
    </button>
    <button
      class="profile-dropdown d-flex align-items-center gap-3"
      (click)="selectDropdown($event)"
    >
      <img
        loading="lazy"
        [src]="globals.user?.avatar || 'assets/icons/user.svg'"
        alt="Profile Pic"
      />
      <h6 class="mb-0 sm-ds-none fw-bolder" style="pointer-events: none">
        {{ globals.user?.username }}
      </h6>
      <img
        class="sm-ds-none"
        loading="lazy"
        src="assets/icons/arrow.svg"
        alt="Chevron"
        width="25px"
      />
      <div class="dropdown-options">
        <button
          class="py-2 px-1 h-100 d-flex gap-2 align-items-center justify-content-between w-100"
        >
          <img
            loading="lazy"
            width="40px"
            class="rounded-circle"
            [src]="globals.user?.avatar || 'assets/icons/user.svg'"
          />
          <div class="font-14">
            <span class="color-body fw-700">
              {{ globals.user?.username }}
            </span>
            <span class="color-text text-nowrap">
              {{
                globals.user?.tokens?.balance?.bonus +
                  globals.user?.tokens?.balance?.purchased +
                  globals.user?.tokens?.balance?.subscription | number : "1.0-0"
              }}
              Tokens
            </span>
          </div>
          <div class="font-14 text-center">
            <img
              loading="lazy"
              width="24px"
              [src]="
                'assets/images/' +
                globals.user?.verificationStatus?.name +
                '-' +
                globals.user?.verificationStatus?.rank +
                '.svg'
              "
              alt=""
            />
            <span class="d-block color-pink fw-700 lh-base text-nowrap">
              {{
                globals.user?.verificationStatus?.name +
                  " " +
                  globals.user?.verificationStatus?.rank | titlecase
              }}
            </span>
          </div>
        </button>
        <a
          routerLink="/main/profile"
          class="dropdown-option text-decoration-none text-muted border-0 font-14 color-text"
        >
          <img
            loading="lazy"
            class="menu-user-icon me-3"
            src="assets/icons/profile-icon.svg"
            alt=""
          />
          View profile
        </a>
        <a
          routerLink="/main/activities"
          class="dropdown-option text-decoration-none text-muted border-0 font-14 color-text"
        >
          <img
            loading="lazy"
            class="menu-user-icon me-3"
            src="assets/icons/activities-icon.svg"
            alt=""
          />
          Activities
        </a>

        <a
          class="dropdown-option text-decoration-none text-muted border-0 font-14 color-text"
          routerLink="/main/transaction"
        >
          <img
            loading="lazy"
            class="menu-user-icon me-3"
            src="assets/icons/message-icon.svg"
            alt=""
          />
          Transaction
        </a>

        <a
          class="dropdown-option text-decoration-none text-muted font-14 color-text"
          routerLink="/main/profile"
          [queryParams]="{ tab: 3 }"
        >
          <img
            loading="lazy"
            class="menu-user-icon me-3"
            src="assets/icons/invite-friend-icon.svg"
            alt=""
          />
          Invite Friends
        </a>
        <a
          routerLink="/main/help"
          class="dropdown-option text-decoration-none text-muted border-0 font-14 color-text"
        >
          <img
            loading="lazy"
            class="menu-user-icon me-3"
            src="assets/icons/help-icon.svg"
            alt=""
          />
          Get help
        </a>
        <a
          routerLink="/main/settings"
          class="dropdown-option text-decoration-none text-muted font-14 color-text"
        >
          <img
            loading="lazy"
            class="menu-user-icon me-3"
            src="assets/icons/setting-icon.svg"
            alt=""
          />
          Settings
        </a>
        <a
          class="dropdown-option text-decoration-none text-muted font-14 color-text"
          (click)="logOut()"
          ><img
            loading="lazy"
            class="me-3 menu-user-icon"
            src="assets/icons/logout-icon.svg"
            alt=""
          />
          Log out</a
        >
      </div>
    </button>
  </div>
</div>

<create-offer
  [paymentMethods]="globals.paymentMethods"
  [countries]="globals.countries"
  [modalId]="modalId"
  [offer]="transactionService.offer"
  (createOffer)="
    transactionService.offer._id ? updateOffer($event) : createOffer($event)
  "
></create-offer>
<buyer-offer
  [modalId]="modalId2"
  (buyerOffer)="buyerOffer($event)"
  [offer]="transactionService.offer"
  [countries]="globals.countries"
></buyer-offer>

<rating-review
  [modalId]="modalId3"
  [ratingReview]="globals.ratingReview"
  (emitRatingReview)="emitRatingReview($event)"
></rating-review>

<upgrade-account
  [verificationStatus]="globals.user?.verificationStatus"
  [modalId]="modalId4"
  [verifyPhoneModalId]="modalId5"
></upgrade-account>
<verify-phone-nos
  [modalId]="modalId5"
  [modalId2]="modalId4"
  [phone]="globals.user?.phone"
  (verifyPhone)="verifyPhone($event)"
></verify-phone-nos>
<create-wallet-form
  [modalId]="modalId6"
  [countries]="globals.countries"
  [states]="globals.states"
  (emitChangeCountry)="emitChangeCountry($event)"
></create-wallet-form>
<fund-wallet
  [modalId]="modalId7"
  [walletTransaction]="transactionService.walletTransaction"
  [wallet]="transactionService.wallet"
  [displayEscrowFees]="displayEscrowFees"
  (emptyVariables)="transactionService.offer = null; displayEscrowFees = false"
></fund-wallet>
<add-bank
  [modalId]="modalId8"
  (submitForm)="addBankAccount($event)"
  [selectedCurrency]="transactionService.selectedCurrency"
  [success]="addBankSuccess"
></add-bank>
<topup-wallet
  [modalId]="modalId9"
  [walletId]="transactionService.wallet?._id"
  [currency]="transactionService.wallet?.currency"
  [offerAmount]="transactionService.offer?.amount"
  (emitTopupWallet)="createWalletTransaction($event)"
></topup-wallet>
