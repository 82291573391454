<swap-modal [modalId]="modalId" [scrollable]="true" [right]="true">
  <div class="modal-header border-0 mx-3">
    <h2 class="modal-title fs-5 fw-bolder" id="staticBackdropLabel">
      Verify Phone Number
    </h2>
    <button
      type="button"
      class="btn-close closeBtn"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeModal
    ></button>
  </div>

  <div class="modal-body mx-3">
    <section>
      <div class="fw-400 mb-4 font-16">
        To ensure a secure and reliable community, we kindly request you to
        verify your phone number.
      </div>

      <form [formGroup]="phoneVerificationForm" (ngSubmit)="onSubmit()">
        <div class="col-12 mb-4">
          <div class="col-12">
            <div class="">
              <label class="fw-700 font-14 color-header mb-2 d-block ml-2"
                >Phone
              </label>
              <phone-input
                [calling_code]="
                  globals.storage.getUserLocationDetails()?.country_calling_code
                "
                [otpButton]="true"
                [enableOtpButton]="phoneVerificationForm.controls['phone'].valid"
                [phoneNumber]="phoneVerificationForm.value.phone"
                (emitPhoneNumber)="onValueChange('phone', $event)"
                (emitOtpSent)="generateOTP()"
              ></phone-input>
            </div>
          </div>
        </div>

        <div class="col-12">
          <label
            for="country"
            class="fw-700 font-14 color-header mb-2 d-block ml-2"
            >Verification Code</label
          >
          <div class="w-full border-line p-2 d-flex justify-content-between">
            <input
              class="w-full font-14"
              style="height: 35px"
              placeholder="Enter code"
              type="text"
              formControlName="verificationCode"
              id="verificationCode"
              pattern="\d*"
              [maxLength]="6"
              required
            />
            <button class="otpBtn" (click)="pasteCode()">
              Paste OTP
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.625 9.8625H9.9975C8.6625 9.8625 7.575 8.7825 7.575 7.44V5.8125C7.575 5.505 7.3275 5.25 7.0125 5.25H4.635C2.9025 5.25 1.5 6.375 1.5 8.385V13.365C1.5 15.375 2.9025 16.5 4.635 16.5H9.0525C10.785 16.5 12.1875 15.375 12.1875 13.365V10.425C12.1875 10.11 11.9325 9.8625 11.625 9.8625Z"
                  fill="#DF678C"
                />
                <path
                  d="M13.3643 1.5H11.8868H11.0693H8.94684C7.25184 1.5 5.87934 2.58 5.81934 4.5075C5.86434 4.5075 5.90184 4.5 5.94684 4.5H8.06934H8.88684H10.3643C12.0968 4.5 13.4993 5.625 13.4993 7.635V9.1125V11.145V12.6225C13.4993 12.6675 13.4918 12.705 13.4918 12.7425C15.1643 12.69 16.4993 11.58 16.4993 9.6225V8.145V6.1125V4.635C16.4993 2.625 15.0968 1.5 13.3643 1.5Z"
                  fill="#DF678C"
                />
                <path
                  d="M8.98449 5.36182C8.75199 5.12932 8.35449 5.28682 8.35449 5.60932V7.57432C8.35449 8.39932 9.05199 9.07432 9.90699 9.07432C10.4395 9.08182 11.182 9.08182 11.8195 9.08182C12.142 9.08182 12.307 8.70682 12.082 8.48182C11.2645 7.66432 9.80949 6.20182 8.98449 5.36182Z"
                  fill="#DF678C"
                />
              </svg>
            </button>
          </div>
          <div
            *ngIf="
              phoneVerificationForm.controls['verificationCode'].invalid &&
              (phoneVerificationForm.controls['verificationCode'].touched ||
                phoneVerificationForm.controls['verificationCode'].dirty)
            "
            class="text-danger"
          >
            <div
              class="fw-500 font-12 pt-1"
              *ngIf="phoneVerificationForm.controls['verificationCode'].errors?.['required'] "
            >
              Verification code is required.
            </div>
            <div
              class="fw-500 font-12 pt-1"
              *ngIf="phoneVerificationForm.controls['verificationCode'].errors?.['pattern'] "
            >
              Verification code must be a number.
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center gap-4 mt-5">
          <button
            class="back-btn px-3 font-16 fw-700 py-2 w-100 border-0"
            data-bs-dismiss="modal"
            aria-label="Close"
            (click)="globals.openModal(modalId2)"
          >
            Go Back
          </button>
          <button
            [disabled]="!phoneVerificationForm.valid"
            class="px-4 py-2 font-16 fw-700 btn-upgrade w-100 border-0"
            type="submit"
          >
            Verify
          </button>
        </div>
      </form>
    </section>
  </div>
</swap-modal>
<div id="otp-button"></div>
