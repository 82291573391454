import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalsService } from 'src/app/services/core/globals';
import { SocketService } from '../../api/socketio/socket.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private globals: GlobalsService,
    private socketService: SocketService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (this.globals.storage.isAuthenticated()) {
      this.socketService.initializeSocket(
        this.globals.storage.getAccessToken()
      );
      return true;
    }
    this.globals.router.navigate(['auth'], {
      queryParams: { redirectUrl: state.url },
    });
    this.globals.storage.clearAllStorage();
    return false;
  }
}
