import { Component, Input } from '@angular/core';
import { GlobalsService } from '../../../services/core/globals';

@Component({
  selector: 'get-in-touch',
  templateUrl: './get-in-touch.component.html',
  styleUrls: ['./get-in-touch.component.scss'],
})
export class GetInTouchComponent {
  @Input() modalId: string = '';
  
  @Input() newTicketModalId: string = '';

  constructor(
    public globals: GlobalsService
  ) {}
  
}
