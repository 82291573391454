<swap-modal [modalId]="modalId" [scrollable]="true" [right]="true">
  <div class="modal-header border-0 mx-3 mt-2">
    <h5 class="modal-title font-18 fw-700" id="staticBackdropLabel">
      Edit Profile
    </h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="resetForm()"
      #closeModal
    ></button>
  </div>
  <div class="modal-body mx-3">
    <form [formGroup]="profileForm" class="row g-4 profile-form">
      <div class="col-12 row g-0 mt-3">
        <div class="col-3">
          <div class="position-relative">
            <img
              loading="lazy"
              id="profilePictureId"
              [src]="user?.avatar || 'assets/icons/user.svg'"
              class="img-profile-picture"
            />
            <img
              loading="lazy"
              src="assets/images/avatar-profile-edit.svg"
              class="img-pencil pointer"
            />
          </div>
        </div>
        <div class="col-9 d-flex justify-content-center flex-column">
          <p class="mb-0 fw-bolder">Update Profile Photo</p>
          <a
            href="#"
            class="text-decoration-none text-secondary fw-500 font-14"
            (click)="closeModal.click()"
            data-bs-toggle="modal"
            attr.data-bs-target="#{{ modalId2 }}"
            >Click to update</a
          >
        </div>
      </div>
      <div class="col-12">
        <label class="form-label fw-500 font-14"
          >Who can see your profile photos?</label
        >
        <button class="input-border dropdown" (click)="selectDropdown($event)">
          {{ "Anyone" }}
          <div class="dropdown-options">
            <div class="dropdown-option">
              {{ "Anyone" }}
            </div>
          </div>
        </button>
      </div>
      <div class="col-6">
        <label for="first-name" class="form-label fw-500 font-14"
          >First Name
          <span class="text-danger">*</span>
        </label>
        <input
          type="text"
          class="form-control"
          id="first-name"
          placeholder="First Name"
          formControlName="firstName"
        />
      </div>
      <div class="col-6">
        <label for="last-name" class="form-label fw-500 font-14"
          >Last Name <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control"
          id="last-name"
          placeholder="Last Name"
          formControlName="lastName"
        />
      </div>
      <div class="col-12">
        <label for="email" class="form-label fw-500 font-14"
          >Email <span class="text-danger">*</span></label
        >
        <input
          type="email"
          class="form-control"
          id="email"
          placeholder="Email"
          formControlName="email"
        />
      </div>
      <div class="col-6">
        <label class="form-label fw-500 font-14"
          >Country
          <span class="text-danger">*</span>
        </label>
        <button
          class="input-border dropdown d-flex align-items-center"
          (click)="selectDropdown($event)"
          [disabled]="user && user.verificationStatus.rank > 2"
          [ngClass]="{ 'text-muted': !profileForm.value.country }"
        >
          <p
            class="truncate mb-0"
            [ngStyle]="{ 
              width: profileForm.value.country ? '4.5em' : 'auto',
             }"
          >
            {{ profileForm.value.country || "Select Country" }}
          </p>
          <img
            loading="lazy"
            [hidden]="
              !globals.searchCountryBy(profileForm.value.country, 'name')
            "
            [src]="
              globals.searchCountryBy(profileForm.value.country, 'name')?.flag
            "
            alt="country"
          />
          <div class="dropdown-options">
            <div
              class="dropdown-option"
              *ngFor="let country of countries"
              (click)="setCountry(country)"
            >
              {{ country.name }}
              <img
                loading="lazy"
                [src]="country.flag"
                alt="country"
                class="me-2"
              />
            </div>
          </div>
        </button>
      </div>
      <div class="col-6">
        <label class="form-label fw-500 font-14"
          >State
          <span class="text-danger">*</span>
        </label>
        <button
          class="input-border dropdown"
          (click)="selectDropdown($event)"
          [disabled]="user && user.verificationStatus.rank > 2"
          [ngClass]="{ 'text-muted': !profileForm.value.state }"
        >
          <p
            class="truncate mb-0"
            [ngStyle]="{ 
              width:  profileForm.value.state ? '4.5em' : 'auto',
             }"
          >
            {{ profileForm.value.state || "Select State" }}
          </p>
          <div class="dropdown-options">
            <div
              class="dropdown-option"
              *ngFor="let state of states"
              (click)="setState(state)"
            >
              {{ state.name }}
            </div>
          </div>
        </button>
      </div>
      <div class="col-12">
        <label class="form-label fw-500 font-14">City</label>
        <button
          class="input-border dropdown"
          (click)="selectDropdown($event)"
          [disabled]="user && user.verificationStatus.rank > 2"
          [ngClass]="{ 'text-muted': !profileForm.value.city }"
        >
          <p
            class="truncate mb-0"
            [ngStyle]="{ 
              width:  profileForm.value.city ? '4.5em' : 'auto',
             }"
          >
            {{ profileForm.value.city || "Select City" }}
          </p>
          <div class="dropdown-options">
            <div
              class="dropdown-option"
              *ngFor="let city of cities"
              (click)="setCity(city)"
            >
              {{ city.name }}
            </div>
          </div>
        </button>
      </div>
      <div class="col-12">
        <label for="aboutme" class="form-label fw-500 font-14">About me</label>
        <textarea
          class="form-control"
          name="aboutMe"
          formControlName="aboutMe"
          id="aboutme"
          placeholder="Enter description"
          rows="5"
        ></textarea>
      </div>
    </form>
  </div>
  <div class="modal-footer border-0">
    <button
      type="submit"
      class="submit-btn"
      (click)="onSubmit()"
    >
      Save Changes
    </button>
  </div>
</swap-modal>
