import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'view-payment-proof',
  templateUrl: './view-payment-proof.component.html',
  styleUrls: ['./view-payment-proof.component.scss'],
})
export class ViewPaymentProofComponent implements OnChanges {
  @Input() modalId: string = '';
  @Input() imageURL: string = '';

  zoom: boolean = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {}
}
