import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'messaging-form',
  templateUrl: './messaging-form.component.html',
  styleUrls: ['./messaging-form.component.scss'],
})
export class MessagingFormComponent {
  @Input() disableSendBtn: boolean = false;

  @Output() emitMessage: EventEmitter<{ comment: string }> = new EventEmitter<{
    comment: string;
  }>();
  messageForm: FormGroup = new FormGroup({
    comment: new FormControl('', Validators.required),
  });

  toggled: boolean = false;

  onSubmit() {
    if (this.messageForm.invalid) {
      return;
    }
    this.emitMessage.emit({
      comment: this.messageForm.value.comment,
    });
    this.messageForm.reset({
      comment: '',
    });
  }

  onKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.onSubmit();
    }
  }

  addEmoji(event: any) {
    let cursorPos = document.getElementById('comment') as HTMLTextAreaElement;
    cursorPos?.focus();
    event.selectionStart = cursorPos?.selectionStart;
    const { comment } = this.messageForm.value;
    const emoji = event.char;
    const start = comment.slice(0, event.selectionStart);
    const end = this.messageForm.value.comment.slice(event.selectionStart);
    this.messageForm.patchValue({
      comment: `${start}${emoji}${end}`,
    });
  }
}
