<swap-modal [modalId]="modalId">
  <div class="w-full">
    <div class="d-flex justify-content-between modal-header">
      <p class="color-header modal-title fs-5 fw-700 font-18 text-left">
        Offer
      </p>
      <div
        data-bs-dismiss="modal"
        aria-label="Close"
        #closeModal
        class="pointer"
      >
        <img loading="lazy" src="assets/icons/close-icon.svg" alt="" />
      </div>
    </div>
    <!-- <hr class="line" /> -->
    <div class="modal-body m-3">
      <div class="d-flex flex-row justify-content-between">
        <p class="fw-400 font-14">Amount</p>
        <p class="fw-500 font-14 text-right">
          {{ offers?.amount | currency : offers?.localCurrency?.code }}
        </p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="fw-400 font-14">Want</p>
        <p class="fw-700 font-14 text-right">
          {{ offers?.foreignCurrency?.code }}
        </p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="fw-400 font-14">Rate</p>
        <p class="fw-500 font-14 text-right">
          {{ offers?.rate }}
        </p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="fw-400 font-14">Country</p>
        <div class="d-flex gap-2">
          <img
            *ngFor="let item of offers?.preferredCountries | slice : 0 : 3"
            loading="lazy"
            [src]="globals.searchCountryBy(item?.country, 'name')?.flag"
            class="me-1"
            style="width: 25px; height: 25px; object-fit: contain"
          />
        </div>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="fw-400 font-14">Payment(send from)</p>
        <p class="fw-500 font-14 text-right">
          {{ offers?.paymentSendFrom }}
        </p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="fw-400 font-14">Payment(receive through)</p>
        <p class="fw-500 font-14 text-right">
          {{ offers?.paymentReceiveThrough }}
        </p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="fw-400 font-14">Limit</p>
        <!-- <div style="width: 163.5px; height: 19px;"> -->
        <p class="fw-500 font-14 text-right">
          {{
            offers?.minAmount
              | currency : offers?.localCurrency?.code : "symbol-narrow"
          }}
          -
          {{
            offers?.amount
              | currency : offers?.localCurrency?.code : "symbol-narrow"
          }}
        </p>
        <!-- </div> -->
      </div>
    </div>
    <div class="m-3" *ngIf="showComponent; else button">
      <button
        (click)="onSwap(offers?._id)"
        class="w-full bg-purple p-3 color-white round-12 outline-none font-16 fw-700 mt-2"
      >
        Swap
      </button>
    </div>
    <ng-template #button>
      <button
        (click)="buyerOfferModal(offers)"
        class="w-full bg-purple p-3 color-white round-12 font-16 fw-700 mt-2 outline-none"
      >
        Swap
      </button>
    </ng-template>
  </div>
</swap-modal>
