import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'new-ticket',
  templateUrl: './new-ticket.component.html',
  styleUrls: ['./new-ticket.component.scss'],
})
export class NewTicketComponent {
  @Input() modalId: string = '';

  @Input() ticketCategories: { _id: string; item: string }[] = [];

  @Input() title: string = 'New Ticket';

  @Input() type: string = '';

  @Input() prefilledMessage: string = '';

  @Input() biddingId: string = '';

  @Input() reportUserDetails: { email: string; username: string } = {
    email: '',
    username: '',
  };

  @Input() disconnectSubject: string = '';

  @Output() submitForm: EventEmitter<any> = new EventEmitter<any>();

  ticketForm: FormGroup = new FormGroup({
    subject: new FormControl('', Validators.required),
    ticket_category: new FormControl('', Validators.required),
    message: new FormControl('', Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(256)])),
    image: new FormControl(null),
    user_id: new FormControl(''),
  });

  fileName: string = '';
  fileSize: string = '';
  progress: number = 0;
  setInterval: any;
  fileOverZone: boolean = false;

  constructor() {}

  ngOnInit() {
    if (this.type === 'disconnect') {
      this.ticketForm.patchValue({
        subject: this.disconnectSubject,
        ticket_category: this.ticketCategories.find(
          (category) => category.item.toLowerCase() === 'app security'
        )?.item,
      });
    }

    if (this.type ==='report') {
      this.ticketForm.patchValue({
        subject:
          this.reportUserDetails.username + ' (Review Content Integrity)',
      });
    }

    if (this.type === 'transaction') {
      this.ticketForm.patchValue({
        subject: 'Issue with my transaction: ' + this.biddingId,
        ticket_category: this.ticketCategories.find(
          (category) => category.item.toLowerCase() === 'transaction'
        )?.item,
        message: this.prefilledMessage,
      });
    }
  }

  onSubmit() {
    this.submitForm.emit(this.ticketForm.value);
    this.ticketForm.reset({
      subject: '',
      ticket_category: '',
      message: '',
      image: null,
    });
    this.clearFile();
  }

  ticketCategoryDropDown() {
    let dropDown = document.querySelector(
      '.form-control.dropdown'
    ) as HTMLElement;
    dropDown.classList.toggle('active');
    // Close the dropdown if the user clicks outside of it
    document.addEventListener('click', function (e: any) {
      if (!dropDown.contains(e.target)) {
        dropDown.classList.remove('active');
      }
    });
  }

  selectCategory(category: string) {
    this.ticketForm.patchValue({
      ticket_category: category,
    });
  }

  uploadFile(event: any) {
    this.ticketForm.patchValue({
      image: event.target.files[0],
    });
    const reader = new FileReader();
    reader.readAsDataURL(this.ticketForm.value.image);
    reader.onprogress = (event) => {
      if (event.lengthComputable) {
        this.progress = Math.round((event.loaded / event.total) * 100);
      }
    };
    this.fileName = this.ticketForm.value.image.name;
    this.fileSize =
      (this.ticketForm.value.image.size / 1024).toFixed(2) + ' KB';
  }

  clearFile() {
    this.ticketForm.patchValue({
      image: null,
    });
    this.fileName = '';
    this.fileSize = '';
    this.progress = 0;
  }

  onDragEnter() {
    this.fileOverZone = true;
  }

  onDragLeave() {
    this.fileOverZone = false;
  }

  get subject() {
    return this.ticketForm.get('subject') as FormControl;
  }

  get category() {
    return this.ticketForm.get('ticket_category') as FormControl;
  }

  get message() {
    return this.ticketForm.get('message') as FormControl;
  }
}
