import { Pipe, PipeTransform } from '@angular/core';
import { Country } from '../core/IApp';

@Pipe({
  name: 'countryFilter',
})
export class CountryFilterPipe implements PipeTransform {
  transform(currencies: Country[], searchTerm: string): any[] {
    if (!currencies || !searchTerm) return currencies;

    let exceptions = [
      {
        name: 'United States',
        currency: 'USD',
      },
      {
        name: 'United Kingdom',
        currency: 'GBP',
      },
      {
        name: 'European Union',
        currency: 'EUR',
      },
      { name: 'Russia', currency: 'RUB' },
      { name: 'China', currency: 'CNY' },
      { name: 'Japan', currency: 'JPY' },
      { name: 'India', currency: 'INR' },
      {
        name: 'Australia',
        currency: 'AUD',
      },
      { name: 'Canada', currency: 'CAD' },
      { name: 'Brazil', currency: 'BRL' },
      { name: 'Mexico', currency: 'MXN' },
      {
        name: 'South Africa',
        currency: 'ZAR',
      },
      { name: 'Nigeria', currency: 'NGN' },
      { name: 'Kenya', currency: 'KES' },
      { name: 'Ghana', currency: 'GHS' },
      {
        name: 'Uganda',
        currency: 'UGX',
      },
      {
        name: 'Tanzania',
        currency: 'TZS',
      },
      { name: 'Zambia', currency: 'ZMW' },
      { name: 'Ethiopia', currency: 'ETB' },
      { name: 'Egypt', currency: 'EGP' },
      { name: 'Morocco', currency: 'MAD' },
      { name: 'Algeria', currency: 'DZD' },
      { name: 'Tunisia', currency: 'TND' },
      {
        name: 'Saudi Arabia',
        currency: 'SAR',
      },
      { name: 'Turkey', currency: 'TRY' },
      { name: 'Iran', currency: 'IRR' },
      { name: 'Iraq', currency: 'IQD' },
    ];

    let filteredArray = currencies.filter(
      (item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.currency.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.iso2.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.currency_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // rearrange exceptions to the top of the list if search term matches any of the currency codes
    exceptions.forEach((exception) => {
      if (searchTerm.toLowerCase().includes(exception.currency.toLowerCase())) {
        let index = filteredArray.findIndex((item) =>
          item.name.toLowerCase().includes(exception.name.toLowerCase())
        );
        if (index > -1) {
          let temp = filteredArray[index];
          filteredArray.splice(index, 1);
          filteredArray.unshift(temp);
        }
      }
    });

    return filteredArray;
  }
}
