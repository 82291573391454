import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'remove-card',
  templateUrl: './remove-card.component.html',
  styleUrls: ['./remove-card.component.scss'],
})
export class RemoveCardComponent {
  @Input() modalId: string = '';

  @Output() emitRemoveCard: EventEmitter<boolean> = new EventEmitter<boolean>();
}
