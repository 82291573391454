import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Transaction } from '../../../services/core/IApp';
import { GlobalsService } from '../../../services/core/globals';

@Component({
  selector: 'money-recieve',
  templateUrl: './money-recieve.component.html',
  styleUrls: ['./money-recieve.component.scss'],
})
export class MoneyRecieveComponent implements OnInit {
  @Input() modalId: string = '';
  @Input() transaction: Transaction | any;
  @Output() emitReceivedConfirmation: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public globals: GlobalsService,
  ) {}

  ngOnInit(): void {}

  calculateCurrency(val1: any, val2: any) {
    return Number(val1) * Number(val2);
  }
}
