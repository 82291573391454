import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { GlobalsService } from '../../../services/core/globals';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'upgrade-account',
  templateUrl: './upgrade-account.component.html',
  styleUrls: ['./upgrade-account.component.scss'],
})
export class UpgradeAccountComponent {
  @Input() modalId: string = '';

  @Input() verifyPhoneModalId: string = '';

  @Input() verificationStatus: { name: string; rank: number } | undefined;

  @Output() upgradeAccount: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('closeModal') closeModal: any;

  window: any = window;

  verificationURL: string = '';

  constructor(public globals: GlobalsService) {
    this.verificationURL = environment.verificationURL;
  }

  navigate(route: string) {
    this.globals.router.navigate([route]);
    this.closeModal.nativeElement?.click();
  }
}
