<swap-modal [modalId]="modalId" [centered]="true">
  <div class="modal-header">
    <h5 class="fs-5 mb-0" id="staticBackdropLabel">
      Connect {{ type | titlecase }}
    </h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeModal
    ></button>
  </div>
  <div class="modal-body mx-3">
    <div class="parent bg-white mt-2 text-center">
      <div class="pb-4">
        <section class="d-flex flex-column align-items-center">
          <div class="p-2 pb-4">
            <img
              loading="lazy"
              *ngIf="type"
              src="assets/images/{{ type }}.png"
              alt="Instagram Logo"
              class="w-50"
            />
          </div>
          <div
            class="input-shape p-3 w-100 d-flex align-items-center gap-3 mb-2"
          >
            <h2 class="text-muted mb-0 col-1 font-16">@</h2>
            <input
              type="text"
              name="instagramSearch"
              placeholder="{{
                type === 'instagram'
                  ? 'Enter Instagram Username'
                  : 'Enter your Facebook Profile URL'
              }}"
              class="col-11"
              [(ngModel)]="searchTerm"
              [pattern]="type === 'instagram' ? '^[a-zA-Z0-9._-]*$' : regex"
            />
          </div>
          <small class="text-danger" [hidden]="searchTerm.length > 0">
            NB: Please ensure that the information you provide is accurate as
            submitting false information will result in your account being
            banned.
          </small>
          <small
            class="text-danger"
            *ngIf="
              type === 'instagram' &&
              searchTerm.length > 0 &&
              !searchTerm.match('^[a-zA-Z0-9._-]*$')
            "
          >
            Please enter a valid username
          </small>
          <small
            class="text-danger"
            *ngIf="
              type === 'facebook' &&
              searchTerm.length > 0 &&
              !searchTerm.match(regex)
            "
          >
            Please enter a valid facebook profile url e.g.
            https://www.facebook.com/username
          </small>
          <!-- <small class="text-danger">
            NB: Please ensure that the information you provide is accurate as
            submitting false information will result in your account being
            banned.
          </small> -->
          <div class="pt-2 w-100">
            <button
              class="bg-purple border-0 round-8 fw-700 font-14 color-white h-3 w-100"
              (click)="connectAccount()"
              [disabled]="
                searchTerm.length < 1 ||
                (type === 'instagram' &&
                  !searchTerm.match('^[a-zA-Z0-9._-]*$')) ||
                (type === 'facebook' && !searchTerm.match(regex))
              "
            >
              Connect Account
            </button>
          </div>
        </section>
      </div>
    </div>
  </div>
</swap-modal>
