<swap-modal [modalId]="modalId" [centered]="true">
  <div class="row m-3">
    <div class="col-12 d-flex justify-content-between mb-3">
      <h5 class="modal-title mb-0 fw-bolder" id="exampleModalLabel">
        View Payment Proof
      </h5>
      <button
        type="button"
        class="closeBtn"
        data-bs-dismiss="modal"
        aria-label="Close"
        #closeBtn
      >
        <img loading="lazy" src="assets/icons/close-icon.svg" alt="" />
      </button>
    </div>
    <div class="col-12 mb-2 text-center">
      <img
        loading="lazy"
        [src]="imageURL"
        class="view-img"
        (click)="zoom = !zoom"
        [ngClass]="{ zoom }"
      />
    </div>
  </div>
</swap-modal>
