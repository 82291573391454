<swap-modal [modalId]="modalId" [centered]="true">
  <div class="modal-header border-0">
    <img loading="lazy" src="assets/images/Featured icon.svg" />
    <button
      type="button"
      class="d-none"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeModal
    ></button>
  </div>
  <div class="modal-body mx-3">
    <p class="color-header fw-700 font-18 mb-0">Remove Card</p>
    <p class="font-15 color-text mb-4 p-0">
      Are you sure you want to remove this payment method?
    </p>

    <div class="d-flex align-items-center gap-2">
      <button
        data-bs-dismiss="modal"
        aria-label="Close"
        class="btn-confirm fw-700 font-13"
      >
        Cancel
      </button>
      <button
        class="btn-confirm fw-700 font-13 btn-delete"
        (click)="emitRemoveCard.emit(true)"
      >
        Remove
      </button>
    </div>
  </div>
</swap-modal>
