import { Component, Input } from '@angular/core';
import { GlobalsService } from '../../../services/core/globals';

@Component({
  selector: 'deactivated-user-modal',
  templateUrl: './deactivated-user-modal.component.html',
  styleUrls: ['./deactivated-user-modal.component.scss'],
})
export class DeactivatedUserModalComponent {
  @Input() modalId: string = '';

  constructor(
    private globals: GlobalsService,
  ){}

  navigate() {
    this.globals.router.navigate(['faqs/deactivated-user']);
  }
}
