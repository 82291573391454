import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'deactivate-modal',
  templateUrl: './deactivate-modal.component.html',
  styleUrls: ['./deactivate-modal.component.scss'],
})
export class DeactivateModalComponent implements OnInit {
  @Input() modalId: string = '';
  @Input() email: string = '';
  @Output() emitDeleteAccount: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('closeModal') closeModal: HTMLButtonElement | any;
  confirmEmail: string = '';

  constructor() {}

  ngOnInit(): void {}

  deleteAccount() {
    this.emitDeleteAccount.emit();
    this.closeModal.nativeElement.click();
  }

  closeDialog() {
    this.closeModal.nativeElement.click();
  }
}
