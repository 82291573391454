<div class="input-border">
  <div class="d-flex align-items-center border-end py-2">
    <div
      class="d-flex align-items-center position-relative gap-1 pointer"
      (click)="openDropdown()"
      *ngIf="!showDropdown"
    >
      <img
        loading="lazy"
        [src]="selectedCountryPhone?.flag"
        alt=""
        width="20px"
      />
      <span>+{{ selectedCountryPhone?.phone_code }}</span>
      <i class="bi bi-chevron-down me-2"></i>
    </div>
    <div class="flex-grow-1 position-relative" *ngIf="showDropdown">
      <input
        type="text"
        placeholder="Search"
        class="search-input"
        [(ngModel)]="dialCodeSearch"
        #searchInput
      />
      <div
        class="dropdown-menu"
        aria-labelledby="dropdownMenuButton1"
        [ngClass]="{
          show: showDropdown
        }"
      >
        <div
          class="dropdown-item"
          *ngFor="let country of phoneCodeData | phoneFilter : dialCodeSearch"
          (click)="selectCode(country)"
        >
          <img
            loading="lazy"
            [src]="country.flag"
            alt=""
            width="20px"
            class="me-1"
          />
          +{{ country.phone_code }}
        </div>
      </div>
    </div>
  </div>
  <input
    type="number"
    id="phone"
    name="phone"
    autocomplete="off"
    [value]="nationalNumber"
    placeholder="x x x x x x x x x x"
    (input)="submitPhoneNumber($event)"
  />
  <button
    *ngIf="otpButton"
    class="otpBtn"
    (click)="timer > 0 && enableOtpButton ? null : startCountDown()"
    [disabled]="!enableOtpButton || timer > 0"
  >
    {{ timer > 0 ? "Retry in " + timer + " secs" : "Send OTP" }}
  </button>
</div>
