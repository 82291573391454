import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Country } from 'src/app/services/core/IApp';

@Component({
  selector: 'app-edit-currency',
  templateUrl: './edit-currency.component.html',
  styleUrls: ['./edit-currency.component.scss'],
})
export class EditCurrencyComponent {
  @Input() modalId: string = '';

  @Input() currencies: Array<any> = [];

  @Input() countries: Array<Country> = [];

  @Output() emitCurrencies: EventEmitter<Array<any>> = new EventEmitter<
    Array<any>
  >();

  @ViewChild('closeBtn') closeBtn: any;

  search: string = '';

  currencyListings: Array<any> = [];
  buttonText: string = 'Edit Currencies';
  selectedCurrencyListings: Array<any> = [];

  constructor() {}

  ngOnChanges() {
    this.currencyListings = this.countries?.map((list) => {
      var obj = {
        ...list,
        checked: this.currencies?.some(
          (myList: { country: string }) => myList.country === list.name
        ),
      };
      return obj;
    });
    this.updateCurrency();
  }

  onClose() {
    this.closeBtn.nativeElement.click();
  }

  saveCurrency() {
    const currencies = this.selectedCurrencyListings.map((list) => {
      return {
        country: list.name,
        code: list.currency,
        iso2: list.iso2,
      };
    });
    if (currencies.length > 5) return;
    this.emitCurrencies.emit(currencies);
    this.onClose();
  }

  updateCurrency() {
    this.selectedCurrencyListings =
      this.currencyListings?.filter((cur: any) => cur.checked) || [];
    this.buttonText =
      this.selectedCurrencyListings.length > 0
        ? 'Save Currencies'
        : 'Edit Currencies';
  }
}
