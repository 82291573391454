<swap-modal [modalId]="modalId" [centered]="true">
  <div class="row m-3">
    <div class="col-12 d-flex mb-3">
      <h5 class="modal-title mb-0 fw-bolder" id="exampleModalLabel">
        Money Received?
      </h5>
      <button
        type="button"
        class="closeBtn d-none"
        data-bs-dismiss="modal"
        aria-label="Close"
        #closeBtn
      >
        <img loading="lazy" src="assets/icons/close-icon.svg" alt="" />
      </button>
    </div>
    <div class="col-12 mb-2">
      <p>
        Are you sure you have recieved the amount of
        <b>
          {{
            globals.user?._id === transaction?.bidding?.seller?.user?._id
              ? (transaction?.bidding?.amount * transaction?.rate
                | currency
                  : transaction?.foreignCurrency?.code
                  : "symbol-narrow")
              : (transaction?.bidding?.amount
                | currency : transaction?.localCurrency?.code : "symbol-narrow")
          }}
        </b>
        from
        <b>
          {{
            globals.user?._id === transaction?.bidding?.seller?.user?._id
              ? transaction?.bidding?.buyer?.user?.username
              : transaction?.bidding?.seller?.user?.username
          }} </b
        >?<br /><br />
        Do note that by doing this, you are confirming that the amount sent has
        been received in your specified account. Kindly check you account
        balance if needed.
      </p>
      <div class="d-flex align-items-center gap-2 mt-4">
        <button
          (click)="emitReceivedConfirmation.emit(false); closeBtn.click()"
          class="submit-btn fw-700 font-13 btn-decline"
        >
          Cancel
        </button>
        <button
          class="submit-btn fw-700 font-13"
          (click)="emitReceivedConfirmation.emit(true); closeBtn.click()"
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
</swap-modal>
