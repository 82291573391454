import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Country, State, User } from '../../../services/core/IApp';
import { GlobalsService } from '../../../services/core/globals';

@Component({
  selector: 'edit-profile-modal',
  templateUrl: './edit-profile-modal.component.html',
  styleUrls: ['./edit-profile-modal.component.scss'],
})
export class EditProfileModalComponent {
  @Input() modalId: string = '';

  @Input() modalId2: string = '';

  @Input() user: User | undefined;

  @Output() emitProfile: EventEmitter<User> = new EventEmitter<User>();

  @Output() emitChangeCountry: EventEmitter<string> =
    new EventEmitter<string>();

  @Input() countries: Country[] = [];

  @Input() states: State[] = [];

  @ViewChild('closeModal') closeModal: any;

  profileForm: FormGroup = new FormGroup({
    firstName: new FormControl('', Validators.compose([Validators.required])),
    lastName: new FormControl('', Validators.compose([Validators.required])),
    email: new FormControl(
      '',
      Validators.compose([Validators.required, Validators.email])
    ),
    country: new FormControl('', Validators.compose([Validators.required])),
    state: new FormControl('', Validators.compose([Validators.required])),
    city: new FormControl(''),
    aboutMe: new FormControl(''),
  });

  selectedCountry: Country | undefined;

  cities: Array<any> = [];

  dataLoaded: boolean = false;

  constructor(public globals: GlobalsService) {}

  ngOnChanges() {
    if (!this.user || this.dataLoaded) return;
    this.dataLoaded = true;
    this.profileForm.patchValue({
      firstName: this.user?.name?.first,
      lastName: this.user?.name?.last,
      email: this.user?.email,
      country: this.user?.address?.country,
      state: this.user?.address?.state,
      city: this.user?.address?.city,
      aboutMe: this.user?.aboutMe || '',
    });
    this.profileForm.get('email')?.disable();
    this.profileForm.get('firstName')?.disable();
    this.profileForm.get('lastName')?.disable();
  }

  setCountry(country: Country) {
    this.emitChangeCountry.emit(country._id);
    this.profileForm.patchValue({ country: country.name });
  }

  setCity(city: any) {
    this.profileForm.patchValue({ city: city.name });
  }

  setState(state: State) {
    this.profileForm.patchValue({ state: state.name });
    this.cities = state.cities;
  }

  onSubmit() {
    this.profileForm.get('email')?.enable();
    this.profileForm.get('firstName')?.enable();
    this.profileForm.get('lastName')?.enable();
    this.emitProfile.emit(this.profileForm.value);
    this.profileForm.get('email')?.disable();
    this.profileForm.get('firstName')?.disable();
    this.profileForm.get('lastName')?.disable();
    this.closeModal.nativeElement.click();
  }

  selectDropdown(event: any) {
    // close other dropdown options
    event.preventDefault();
    let mainElement = event.target;
    const element = document.querySelectorAll('.dropdown-options');
    element.forEach((el) => {
      if (
        el.classList.contains('show') &&
        el !== mainElement.nextElementSibling
      ) {
        el.classList.remove('show');
      }
    });

    if (mainElement.nodeName !== 'BUTTON') mainElement = mainElement.parentNode;

    // add show class to dropdown options
    for (let i = 0; i < mainElement.children.length; i++) {
      if (mainElement.children[i].classList.contains('dropdown-options')) {
        mainElement.children[i].classList.toggle('show');
      }
    }
    // close dropdown options when click outside
    document.addEventListener('mousedown', (e: any) => {
      if (!e.target?.classList.contains('dropdown-option')) {
        element.forEach((el) => {
          if (el.classList.contains('show')) {
            el.classList.remove('show');
          }
        });
      }
    });
  }

  resetForm() {
    this.profileForm.reset({
      firstName: this.user?.name?.first,
      lastName: this.user?.name?.last,
      email: this.user?.email,
      country: this.user?.address?.country,
      state: this.user?.address?.state,
      city: this.user?.address?.city,
      aboutMe: this.user?.aboutMe || '',
    });
  }
}
