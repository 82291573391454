import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
})
export class DisclaimerComponent {
  @Input() modalId: string = '';
  @Output() emitDisclaimerAccepted: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  window: any = window;
}
