import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'confirm-transfer',
  templateUrl: './confirm-transfer.component.html',
  styleUrls: ['./confirm-transfer.component.scss'],
})
export class ConfirmTransferComponent {
  @Input() modalId: string = '';
  @Output() emitTransferConfirmation: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}
}
