<div class="row g-0 gy-2 mt-2 p-2 p-md-5 mt-md-5">
  <div class="col-12 col-md-6 p-3">
    <h1 class="fw-bolder fs-1 text-center">OOPS!</h1>
    <h2 class="fs-2 text-center">THIS PAGE DOESN'T EXIST</h2>
    <p class="fw-bold">
      The page you're trying to access isn't available. It could be due to a
      typo in the URL, the page being moved or deleted, or a broken link.
    </p>
    <p class="fw-bold">
      Please check the URL for errors or use the navigation menu to find your
      way. For any help, please reach out to our support team at
      support@mail.expatswap.com. Thanks for your understanding.
    </p>
  </div>
  <div class="col-12 col-md-6 p-3 order-small-first">
    <img
      src="assets/404.png"
      class="w-100"
      alt="Page Not found"
      height="100%"
    />
  </div>
  <div class="col-12 col-md-6 text-center">
    <swap-button
      (click)="goBack()"
      [ngStyle]="{
        'max-width': '300px',
        padding: '15px',
        width: '100%',

      }"
    >
      Go back to the previous page
    </swap-button>
  </div>
  <div class="col-12 col-md-6 text-center">
    <swap-button
      [routerLink]="'/main/home'"
      [ngStyle]="{
        'max-width': '300px',
        padding: '15px',
        width: '100%',
        height: '100%'
      }"
    >
      Visit our homepage and explore our latest features and offers.
    </swap-button>
  </div>
</div>
