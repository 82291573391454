import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io } from 'socket.io-client';
import { environment } from '../../../../environments/environment';
import { Offer, Ticket } from '../../core/IApp';
import { GlobalsService } from '../../core/globals';
import { RequestService } from '../../core/request';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  // Socket connection
  socket: any;
  notificationListener: any;
  ticket: Ticket | any;
  typing: boolean = false;

  // Variables

  constructor(private globals: GlobalsService, private api: RequestService) {}

  ngOnDestroy(): void {
    this.notificationListener?.unsubscribe();
  }

  initializeSocket(token: string) {
    try {
      this.socket = io(environment.apiBaseURL.replace('/api/', ''), {
        transportOptions: {
          polling: {
            extraHeaders: {
              Authorization: 'Bearer ' + token,
            },
          },
        },
      });
      this.notificationListener = this.getNewNotifications().subscribe(
        (data: any) => {
          this.globals.notifications.unshift(data);
          this.globals.unreadMessages = true;
          this.globals.numberOfUnreadMessages++;
          this.globals.checkForNotifications(this.globals.user.settings, data);
        }
      );
    } catch (ex) {
      console.log(ex);
    }
  }

  disconnectSocket() {
    this.socket && this.socket.disconnect();
  }

  // this function is used to get newly created offers
  getNewOffers(): Observable<Offer> {
    return new Observable((observer) => {
      this.socket.on('triggers', async (data: any) => {
        observer.next(data);
      });
    });
  }

  // This function is used listen to new messages
  getNewTransactionMessages(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('messages', async (data: any) => {
        observer.next(data);
      });
    });
  }

  // This fucntion is used to send messages
  sendMessage(message: any) {
    this.socket.emit('messages', message, (res: any) => {
      if (res.status !== 200) {
        this.globals.toast.error(res.message);
        throw new Error(res.message);
      }
    });
  }

  // This function is used to listen to notifications
  getNewNotifications(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('notifications', async (data: any) => {
        observer.next(data);
      });
    });
  }

  listenToMessages(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('ticket-messages', (data: any) => {
        observer.next(data);
      });
    });
  }

  sendTicketMessage(message: string) {
    let data = {
      authorId: this.globals.user?._id,
      content: message,
      createdAt: new Date(),
    };
    this.ticket.messages.push(data);
    this.socket.emit(
      'ticket-messages',
      {
        content: message,
        ticketId: this.ticket._id,
      },
      (res: any) => {
        if (res.status !== 200) {
          this.globals.toast.error(res.message);
          throw new Error(res.message);
        }
      }
    );
  }

  // This function creates a ticket and sends a message to the server
  async createTicket(message: any) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const formData = new FormData();
        formData.append('subject', message.subject);
        formData.append('category', message.ticket_category);
        formData.append('message', message.message);
        formData.append('ticket', message.image);
        const resp: any = await this.api.post('tickets', formData);
        this.ticket = resp.data as Ticket;
        this.globals.toast.success(resp.message);
        this.globals.spinner.hide();
        resolve(resp);
      } catch (err:any) {
        this.globals.spinner.hide();
        this.globals.toast.error(err.message);
        reject(err);
      }
    });
  }

  // This function gets a ticket by id
  async getTicketMessagesById(id: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.get(`tickets/messages/${id}`);
        this.ticket = resp.data as Ticket;
        this.globals.spinner.hide();
        resolve(resp);
      } catch (error) {
        reject(error);
      }
    });
  }
}
