<swap-modal [modalId]="modalId" [centered]="true">
  <div class="row m-3">
    <div class="col-12 mb-2">
      <img
        loading="lazy"
        src="assets/icons/warning-with-inner-circle-2.svg"
        alt=""
      />
    </div>
    <div class="col-12 d-flex mb-3">
      <h5 class="modal-title mb-0 fw-bolder" id="exampleModalLabel">
        Confirmation Required
      </h5>
      <button
        type="button"
        class="closeBtn d-none"
        data-bs-dismiss="modal"
        aria-label="Close"
        #closeBtn
      >
        <img loading="lazy" src="assets/icons/close-icon.svg" alt="" />
      </button>
    </div>
    <div class="col-12 mb-3">
      <p class="mb-0 text-muted">
        To disconnect your social accounts, please contact our support team for
        assistance.<br /><br />
        Our support team will guide you through the process and ensure a smooth
        disconnection.
      </p>

      <div class="d-flex align-items-center gap-2 mt-4">
        <button
          data-bs-toggle="modal"
          data-bs-target="#changePlanModal"
          class="submit-btn fw-700 font-13 btn-decline"
          (click)="closeBtn.click()"
        >
          Cancel
        </button>
        <button
          type="submit"
          class="submit-btn fw-700 font-13"
          data-bs-toggle="modal"
          attr.data-bs-target="#{{ modalId2 }}"
          (click)="closeBtn.click()"
        >
          Contact Support
        </button>
      </div>
    </div>
  </div>
</swap-modal>
