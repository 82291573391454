<swap-modal
  [modalId]="modalId"
  [centered]="true"
  [backdrop]="'static'"
  [keyboard]="false"
>
  <div class="row m-3">
    <div class="col-12 d-flex mb-3">
      <h5 class="modal-title mb-0 fw-bolder" id="exampleModalLabel">
        Out of time?
      </h5>
      <button
        type="button"
        class="closeBtn d-none"
        data-bs-dismiss="modal"
        aria-label="Close"
        #closeBtn
      >
        <img loading="lazy" src="assets/icons/close-icon.svg" alt="" />
      </button>
    </div>
    <div class="col-12 mb-2">
      <div class="text-center my-3">
        <img loading="lazy" src="assets/icons/urgent-bro.svg" alt="" />
      </div>
      <div class="d-flex align-items-center gap-2 mt-4">
        <button
          (click)="emitExtentionConfirmation.emit(false); closeBtn.click()"
          class="submit-btn fw-700 font-13 btn-decline"
        >
          Decline
        </button>
        <button
          class="submit-btn fw-700 font-13"
          (click)="emitExtentionConfirmation.emit(true); closeBtn.click()"
        >
          Extend Time
        </button>
      </div>
    </div>
  </div>
</swap-modal>
