import { Component, Input } from '@angular/core';
import { GlobalsService } from 'src/app/services/core/globals';

@Component({
  selector: 'deactivate-socials-confirmation',
  templateUrl: './deactivate-socials-confirmation.component.html',
  styleUrls: ['./deactivate-socials-confirmation.component.scss'],
})
export class DeactivateSocialsConfirmationComponent {
  @Input() modalId: string = '';

  @Input() modalId2: string = '';

}
