export const faqList = [
    {
      id: 1,
      title: 'What is ExpatSwap?',
      category: 'About ExpatSwap',
      description: [
        'ExpatSwap is a community-based platform where people with different currencies can swap with other community members on their terms and at their preferred rates. All you have to do is make a new post, set your terms, or select any of the existing deals and start swapping with any of the multiple verified users who also need the currency you have.',
      ],
      subtitle: [],
    },
    {
      id: 2,
      title: 'Expatswap works in what way?',
      category: 'About ExpatSwap',
      description: [
        'We create a global community through our platform and relationships on social media to satisfy your financial and commercial needs. This is achieved through peer-to-peer money exchange and our community-based platforms, which provide a safe exchange of currencies among families, friends, associates, and trusted strangers.',
        'Our view of a community is the growth of trusted and reliable traders from different platforms whose financial needs are mutual. Our swap pal feature helps us make this a reality by promoting an online platform known as a swap network.',
      ],
      subtitle: [],
    },
    {
      id: 3,
      title: 'How do I make exchanges?',
      category: 'About ExpatSwap',
      description: [
        'There are two options to make a swap.',
  
        'The easiest is to select any of the existing deals posted by other users who want the currency you have. Once comfortable with their rates, profiles, and additional information, click the swap now button to start your transaction with your preferred trader. Choose your preferred payment mode and only transact with traders verified by expatswap.',
  
        'The second option is creating a new offer by selecting the Create offer button, filling in your details, and submitting it for approval and listing.',
  
        'In each scenario, choose your payment mode transaction. And then, each party would have to make a transfer and share the confirmation with the second party through the ExpatSwap platform. Please Note to only transact with whom you have vetted and trust. We also have a couple of security features to help and guide you',
      ],
      subtitle: [],
    },
    {
      id: 4,
      title: 'How safe is it?',
      category: 'About ExpatSwap',
      description: [
        "When trading currencies, you have to be very vigilant. Expatswap is safe as it facilitates peer-to-peer money exchange. For security, Expatswap recommends only verified members. Verified member's identity, location, and other information options like social account integration and tracked referrals are confirmed. Other features to help enhance trust before, during, and after a transaction include ratings, comments, and verified badges  options.",
      ],
      subtitle: [],
    },
    {
      id: 5,
      title: 'What are the advantages of ExpatSwap?',
      category: 'About ExpatSwap',
      description: [
        'Expatswap is not a payment platform; we provide a marketplace where Expats can trade currencies on their terms, rates, and payment mode. The platform brings together a community of verified individuals, reducing the risks associated with money exchange. Our platform also gives you a wide range of trading options from our vast community.',
  
        'ExpatSwap offers free services: money exchange and access to other social features come at no cost. However, getting access to accredited money exchange features comes at a cost. We also allow organizations to run free adverts to people in their regions and locality. However, getting access to additional business services like our online shops comes at a low cost.',
  
        'Finally, our business support catalog feature helps narrow searches to fit your needs and specifica hiii tions by paying attention to your location (city, province, or country). Through this feature, a sense of a trusted community is built.',
      ],
      subtitle: [],
    },
    {
      id: 6,
      title: "Is ExpatSwap's peer-to-peer service free?",
      category: 'About ExpatSwap',
      description: [
        "Yes, the peer-to-peer service is free. However, ExpatSwap offers a premium version in cases where the participants want the transaction supervised. This is achieved through ExpatSwap's certified money exchange under ExpatSwap's Escrow supervision, which provides a certified entity the ability to guarantee the transaction's authenticity. This service comes at a small cost from both parties.",
      ],
      subtitle: [],
    },
    {
      id: 7,
      title: 'How secure are the peer-to-peer transactions on ExpatSwap?',
      category: 'About ExpatSwap',
      description: [
        'Be aware that the security of your peer-to-peer (P2P) exchanges with friends and peers is entirely your responsibility. However, we make every effort to give the most up-to-date data on the identity of any individual with whom you trade money.',
      ],
      subtitle: [],
    },
    {
      id: 8,
      title: 'How many currencies can be traded on ExpatSwap?',
      category: 'About ExpatSwap',
      description: ['ExpatSwap trades over 250 currencies around the world.'],
      subtitle: [],
    },
    {
      id: 9,
      title: 'Why trust ExpatSwap?',
      category: 'About ExpatSwap',
      description: [
        "Expatswap is a registered p2p marketplace platform committed to ensuring that users' transaction is adequately secured through our layers of verification and providing you with important data about your fellow Expats. We build trust by building a community-based network of verified Expats.",
      ],
      subtitle: [],
    },
  
    {
      id: 10,
      title: 'Is there a specific payment mode on Expatswap?',
      category: 'Transactions',
      description: [
        'No, Expatswap provides a variety of options like cash app, bank transfer, Momo, Monzo, crypto, and others to pick from. Therefore, the choice lies in the comfortability for the both traders.',
      ],
      subtitle: [],
    },
    {
      id: 11,
      title: 'What If I want to transact less than the offer posted?',
      category: 'Transactions',
      description: [
        "Every offer posted has a minimum and maximum value. This serves as a guide: you cannot input less than the minimum amount or higher than the maximum amount. The approval within the amount range is based on the seller's choice.",
      ],
      subtitle: [],
    },
    {
      id: 12,
      title: 'Who are those that can see my offers?',
      category: 'Transactions',
      description: [
        'There is a potential for the entire Expats to see your offer placements. This is because every offer made is viewed in real-time. Another means through which your offer can be viewed is through filtering. Your offer will immediately appear if it meets the search specification.',
      ],
      subtitle: [],
    },
    {
      id: 13,
      title: 'What does the different transaction status mean?',
      category: 'Transactions',
      description: [
        'To create a seamless transaction process, every transaction stage is clearly pronounced: from the creation of an offer to the failure, decline, or success of the offer.',
      ],
      subtitle: [
        {
          title: 'Pending status',
          decription:
            "After the deal has been made in response to the seller's offer; the transaction awaits approval from the seller. The period between when the deal was made, and the deal's approval is known as PENDING Status in the transaction summary page.",
        },
        {
          title: 'Decline status',
          decription:
            "The seller, probably not comfortable with the buyer's offer, can choose to decline the deal. This automatically ends the transaction by going straight to the archive and also indicates DECLINE in the transaction summary page.",
        },
        {
          title: 'Ongoing status',
          decription:
            "This follows the seller's approval of the buyer's deal. Automatically a chat page is opened where both parties can interact and share details. The medium of interaction is based on the preference of both parties (call or chat). Note that only one transaction can be processed within the transacting period.",
        },
        {
          title: 'Failed status',
          decription:
            'This status occurs when there is an eventual loss of interest or disagreement in the transaction by any of the parties in an ongoing transaction. The status appears after the execution of the end transaction button, and automatically, the transaction closes.',
        },
  
        {
          title: 'Successful status',
          decription:
            'This status follows the completion of a transaction where both parties are satisfied with the deal. The criteria for the successful status include execution of the “Money Received” button and the submission of the proof of payment. ',
        },
      ],
    },
    {
      id: 14,
      title: 'Can I transact with more than one person at once?',
      category: 'Transactions',
      description: [
        'No, you cannot transact with more than one person simultaneously. Once there is an ongoing transaction status, every other pending transaction will be put on hold until the current transaction is closed.',
      ],
      subtitle: [],
    },
    {
      id: 15,
      title: 'How can I keep tabs on my favorite or potential trader?',
      category: 'Transactions',
      description: [
        'The My Swap Pal feature on your profile page enables you to add traders to your favorite list. This allows you to keep an eye on them and their new offers.',
      ],
      subtitle: [],
    },
    {
      id: 16,
      title: 'Is there a particular time allocated to a transaction process?',
      category: 'Transactions',
      description: [
        'Yes, a duration of 30mins is allocated for every transaction process.',
        "However, if the time isn't enough, both parties can request additional time. By clicking the add time button, an option to pick between 10, 20, and 30mins appears.",
        'In addition, for a time request of up to 1 hr or more, a report detailing the reason for the request will need to be filed before such time can be approved.',
      ],
      subtitle: [],
    },
    {
      id: 17,
      title: 'Can I choose the country I want to transact with?',
      category: 'Transactions',
      description: [
        "ExpatSwap provides a filter feature in the create offer section that enables you to select preferred countries. However, this feature doesn't mean other countries cannot make deals on your offer.",
      ],
      subtitle: [],
    },
    {
      id: 18,
      title: 'Is there an expiry date for offers placement?',
      category: 'Transactions',
      description: [
        'Yes, any offer made not attended to within 30 days will automatically be removed from the offer list and archived.',
      ],
      subtitle: [],
    },
    {
      id: 19,
      title:
        'What can I do in the case of any irregularities in the transaction process?',
      category: 'Transactions',
      description: [
        'It is important to reiterate that you are responsible for your transaction, and the transaction process should be handled carefully. However, ExpatSwap has done its due diligence by providing you with reliable information that can assist you in making informed decisions. In addition, ExpatSwap has a support page where you can contact or log complaints of any uncomfortable occurrences in the transaction process.  ',
      ],
      subtitle: [],
    },
  
    {
      id: 20,
      title: 'Tier One verification',
      category: 'Verification',
      description: [
        'This phase deals with verifying your email and phone number. The verification stage gives you the benefit of creating an offer.',
      ],
      subtitle: [],
    },
    {
      id: 21,
      title: 'Tier Two verification',
      category: 'Verification',
      description: [
        'This is the social media verification stage. You are required to upload your social media information. This stage of verification gives you the benefit of approving offers.',
      ],
      subtitle: [],
    },
    {
      id: 22,
      title: 'Tier Three verification',
      category: 'Verification',
      description: [
        'This stage requires uploading a verified identity card (passport, NIN). This verification stage increases your level of interaction with your trading partner: you can chat, call, and view transaction history.',
      ],
      subtitle: [],
    },
  
    {
      id: 23,
      title: 'What is EXS Token?',
      category: 'Tokens and Plans',
      description: [
        'ExpatSwap tokens enable access to different gateways on the ExpatSwap platform. You can use these tokens based on the various activities/features of interest',
      ],
      subtitle: [],
    },
    {
      id: 24,
      title: 'How is it used?',
      category: 'Tokens and Plans',
      description: [
        'Every major activity carried out on the platform will cost a few tokens. Details will be displayed on the tokens dashboard',
      ],
      subtitle: [],
    },
    {
      id: 25,
      title: 'How do I get the tokens?',
      category: 'Tokens and Plans',
      description: [
        'You can get EXS tokens via the following activities; Sign up Bonuses, Referrals, and Promo codes. More so, Expatswap provides its traders with 50 free monthly tokens.',
  
        'There are two other ways you can get the tokens: through subscription plans or bundle plans.',
      ],
      subtitle: [
        {
          title: 'The subscription plans',
          decription:
            'The subscription plans are paid on a monthly/yearly basis. This plan is categorized into four segments, which also influences the price. They are free, classical, plus and premium. Details about the subscription plan are provided below',
        },
        {
          title: 'The Bundle Plans',
          decription:
            'The bundle Plans are a quantity-based plans that allows traders to acquire tokens based on the range of quantity they desire. Find below the token’s price list and the corresponding quantity.',
        },
      ],
    },
    {
      id: 26,
      title: 'Do the tokens expire? ',
      category: 'Tokens and Plans',
      description: [
        'Earned tokens have expiry dates according to the terms and conditions which are constantly reviewed. However, tokens purchased via subscriptions do not have an expiry date.',
      ],
      subtitle: [],
    },
    {
      id: 27,
      title: 'How do I reset my password?',
      category: 'Security',
      description: [
        'You can reset your password by following the "forgot password" link on the login page. This will prompt you to enter your email address or username associated with your account. An email will be sent to you with instructions on how to reset your password. It is important to keep your password secure and not share it with anyone.',
        'You can also change your password from the setting option.'
      ],
      subtitle: [],
    },
    {
      id: 28,
      title: 'What should I do if I suspect someone has accessed my expatSwap account without my permission?',
      category: 'Security',
      description: [
        'You need to understand that the safety of your account is your responsibility, and this means your vital information, like your password, needs to be kept safe. In a case where you have someone access your account without your permission, we will advise that you change your password. (link to the change your password section).',
      ],
      subtitle: [],
    },
    {
      id: 29,
      title: 'How can I contact Expatswap customer support?',
      category: 'Security',
      description: [
        'The Expatswap help section has been developed to seamlessly help you search for solutions or get in touch with the Expatswap support team if you: the search bar provides a list of common issues and their solutions. When you are not satisfied with the solution, you can place a complaint by clicking the “Get in touch” icon.',
      ],
      subtitle: [],
    },
    {
      id: 30,
      title: 'Are there any restrictions on the types of devices or browsers that can be used to access expatSwap?',
      category: 'Security',
      description: [
        "No, there are no restrictions on the types of devices or browsers that can be used to access expatSwap. Whether you prefer to use your desktop computer, laptop, tablet, or smartphone, you can easily access the platform. You also don't have to worry about which browser you're using: expatSwap is designed to work seamlessly with all popular browsers, including Chrome, Firefox, Safari, and Edge.",
      ],
      subtitle: [],
    },
    {
      id: 31,
      title: 'How do I know if someone I referred has signed up or used my referral code or link?',
      category: 'Referrals',
      description: [
        'You can easily track the progress of your referrals and determine whether they have signed up or used your referral code or link. Your referral details contain a list of all the individuals who have used your code or link to sign up for the service. You can access this information by logging into your account and navigating to the referral section.',
      ],
      subtitle: [],
    },
    {
      id: 32,
      title: 'How can I refer friends to expatSwap?',
      category: 'Referrals',
      description: [
        'To refer a friend, simply share your referral code or link with them. Once they sign up using your code or link, they will receive certain benefits.',
      ],
      subtitle: [],
    },
    {
      id: 33,
      title: 'Is there a limit to the number of people I can refer to on expatSwap?',
      category: 'Referrals',
      description: [
        'There is no limit to the number of people you can refer to on ExpatSwap. Whether you want to invite your friends, family, or even strangers who might be interested in the platform, you are free to do so.',
      ],
      subtitle: [],
    },
  
    {
      id: 34,
      title: 'What is your cancellation policy',
      category: 'Account and Profile',
      description: [
        'We understand that things change. You can cancel your plan at any time and we’ll refund you the difference already paid.',
      ],
      subtitle: [],
    },
    {
      id: 35,
      title: 'What are the documents I need to open an account?',
      category: 'Account and Profile',
      description: [
        "There are no documents required to open an account on ExpatSwap. Your Email, Facebook address or Google Mail account will suffice for the opening of your account. However, in order to have access to certain services (Reference Tier 3), your means of identification (passport, national ID card, or driver's licence) will be required. This is to ensure the security of your account and prevent fraud.",
      ],
      subtitle: [],
    },
    {
      id: 36,
      title: 'How do I find my user ID?',
      category: 'Account and Profile',
      description: [
        "Your user ID is a unique identifier allocated to every Expatsawp account. To find your user ID, simply login to your account. On the dashboard, you will see your user ID at the top of the page.",
        'You can also navigate to the "Profile" section. Your user ID will be displayed there, along with other important information about your account.'
      ],
      subtitle: [],
    },
    {
      id: 37,
      title: 'Can I change my personal details after registering on the platform?',
      category: 'Account and Profile',
      description: [
        'Yes, you can change your personal information after registration: you can update your profile information such as your name, address, and contact details by accessing the settings section of your account.',
        'This can also be achieved through the setting option. '
      ],
      subtitle: [],
    },
  ];
  
  export const CategoryList = [
    {
      id: 1,
      name: 'About ExpatSwap',
      img: 'assets/icons/swap-faq.svg',
    },
    {
      id: 2,
      name: 'Account and Profile',
      img: 'assets/icons/account-faq.svg',
    },
    {
      id: 3,
      name: 'Verification',
      img: 'assets/icons/verification-faq.svg',
    },
    {
      id: 4,
      name: 'Transactions',
      img: 'assets/icons/transaction-faq.svg',
    },
    {
      id: 5,
      name: 'Tokens and Plans',
      img: 'assets/icons/token-faq.svg',
    },
    {
      id: 6,
      name: 'Security',
      img: 'assets/icons/security-faq.svg',
    },
    {
      id: 7,
      name: 'Referrals',
      img: 'assets/icons/referral-faq.svg',
    },
  ];
  
  export interface faq {
    id: number;
    title: string;
    category: string;
    description: string | string[];
  }
  
  export interface category {
    id: number;
    name: string;
    img: string
  }
  