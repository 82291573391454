import { AbstractControl, ValidatorFn } from '@angular/forms';

export default class Validation {
  static passwordValidate = {
    firstCharacterInUpperCase: /^[A-Z]/,
    hasDigit: /[0-9]/,
    hasSymbol: /[@!><~|%#&*_-]/,
    minLen: /.{8}/,
  };

  static match(controlName: string, checkControlName: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);
      const checkControl = controls.get(checkControlName);

      if (checkControl?.errors && !checkControl?.errors['matching']) {
        return null;
      }

      if (control?.value !== checkControl?.value) {
        controls.get(checkControlName)?.setErrors({ matching: true });
        return { matching: true };
      } else {
        return null;
      }
    };
  }

  static phoneValidator(controlName: string): ValidatorFn {
    return (controls: AbstractControl) => {
      return import('awesome-phonenumber').then((module) => {
        if (
          controls.get(controlName)?.value &&
          controls.get(controlName)?.value.e164Number !== ''
        ) {
          console.log(controls.get(controlName)?.value.e164Number);
          const phoneNumber = module.parsePhoneNumber(
            controls.get(controlName)?.value.e164Number,
            controls.get(controlName)?.value.countryCode
          );
          const isValid = phoneNumber.valid;
          if (!isValid) {
            controls.get(controlName)?.setErrors({ phone: true });
            return { phone: true };
          } else {
            return null;
          }
        }
        return null;
      });
    };
  }

  static password_firstCharacterUpperCaseError(): ValidatorFn {
    return (controls: AbstractControl) => {
      if (controls?.errors && !controls?.errors['firstCharacterUppercase']) {
        return null;
      }

      if (
        controls.value &&
        !this.passwordValidate.firstCharacterInUpperCase.test(controls.value)
      ) {
        return { firstCharacterUppercase: true };
      } else {
        return null;
      }
    };
  }

  static password_noDigitError(): ValidatorFn {
    return (controls: AbstractControl) => {
      if (controls?.errors && !controls?.errors['hasDigit']) {
        return null;
      }

      if (!this.passwordValidate.hasDigit.test(controls.value)) {
        return { hasDigit: true };
      } else {
        return null;
      }
    };
  }

  static password_minLenError(): ValidatorFn {
    return (controls: AbstractControl) => {
      if (controls?.errors && !controls?.errors['minLen']) {
        return null;
      }

      if (!this.passwordValidate.minLen.test(controls.value)) {
        return { minLen: true };
      } else {
        return null;
      }
    };
  }

  static password_noSymbolError(): ValidatorFn {
    return (controls: AbstractControl) => {
      if (controls?.errors && !controls?.errors['symbol']) {
        return null;
      }

      if (!this.passwordValidate.hasSymbol.test(controls.value)) {
        return { symbol: true };
      } else {
        return null;
      }
    };
  }

  static validateCardNumber(
    control: AbstractControl
  ): { [key: string]: any } | null {
    if (control.value && !luhnCheck(control.value)) {
      return { cardNumber: true };
    }
    return null;
  }
}

function luhnCheck(value: string): boolean {
  // accept only digits, dashes or spaces
  if (/[^0-9-\s]+/.test(value)) {
    return false;
  }

  let nCheck = 0;
  let bEven = false;
  value = value.replace(/\D/g, '');

  for (let n = value.length - 1; n >= 0; n--) {
    const cDigit = value.charAt(n);
    let nDigit = parseInt(cDigit, 10);

    if (bEven && (nDigit *= 2) > 9) {
      nDigit -= 9;
    }

    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 === 0;
}
