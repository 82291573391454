<div class="form-input row g-0 justify-content-between">
  <div class="col-7 p-2">
    <div class="font-10 fw-700 color-text">{{ label }}</div>
    <input
      [name]="name"
      class="font-10 fw-700 color-body border-0 w-100"
      type="text"
      placeholder="{{
        '0.00' | currency : selectedCurrency?.currency : 'symbol-narrow'
      }}"
      [value]="inputValue"
      [disabled]="disabled"
      [readonly]="readonly"
      (input)="onInputChange($event)"
      (keyup)="keyup.emit()"
    />
  </div>
  <div
    class="col-5 col-md-4 dropdown-currency"
    id="currency-select-{{ name }}"
    (click)="selectCurrencyFilter()"
  >
    <div>
      <div class="dropdown-currency-input p-1" id="dropdown-input-{{ name }}">
        <div class="d-flex flex-column align-items-center">
          <img loading="lazy" [src]="selectedCurrency?.flag" alt="" />
          <span class="fw-bolder font-10">{{
            selectedCurrency?.currency
          }}</span>
          <input
            type="text"
            class="font-10"
            placeholder="Type..."
            id="currencysearchbox{{ name }}"
            [(ngModel)]="countrySearchTerm"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>
        <img
          loading="lazy"
          src="assets/icons/chevron-arrow-down.svg"
          width="10"
        />
      </div>
      <div
        class="option"
        [ngStyle]="{
          right: rightOffset ? '0' : 'auto',
          bottom: bottomOffset ? '0' : 'auto',
          top: bottomOffset && 'initial' ,
        }"
      >
        <ng-container
          *ngFor="let currency of countries | countryFilter : countrySearchTerm"
        >
          <div class="option-item" (click)="selectCurrency(currency)">
            <img loading="lazy" [src]="currency?.flag" alt="Currency Flag" />
            <span>
              {{
                currency?.name + " - " + currency?.currency_name | titlecase
              }}</span
            >
            <small>({{ currency?.currency | uppercase }})</small>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
