<swap-modal
  [modalId]="modalId"
  [centered]="true"
  [backdrop]="'static'"
  [keyboard]="false"
>
  <div class="row m-3">
    <div class="col-12 d-flex mb-3">
      <h5 class="modal-title mb-0 fw-bolder" id="exampleModalLabel">
        Upload Proof
      </h5>
      <button
        type="button"
        class="closeBtn d-none"
        data-bs-dismiss="modal"
        aria-label="Close"
        #closeBtn
      >
        <img loading="lazy" src="assets/icons/close-icon.svg" alt="" />
      </button>
    </div>
    <div class="col-12 mb-2">
      <p class="mb-0 text-muted" *ngIf="!file">
        To complete your transaction successfully, we kindly request you to
        upload your proof of funds transferred to the recipient. Ensure you
        perform the transfer yourself, on your banking or payment platform.
        <br /><br />❌ Do not send fraudulent or misleading proof of payment
        details. Violations of the terms will not be accepted.
      </p>
      <div
        class="my-3 border dragArea font-14"
        *ngIf="!file"
        [ngClass]="{ dragging: fileOverZone }"
      >
        <input
          type="file"
          accept="image/jpeg, image/png, image/jpg"
          (change)="uploadFile($event)"
          (dragenter)="onDragEnter()"
          (dragleave)="onDragLeave()"
        />
        <img loading="lazy" src="assets/images/Featured icon1.svg" />
        <p class="mb-0">
          <span class="text-primary">Click to upload</span> or drag and drop
          <span class="font-12">PNG or JPG (max. 800x400px)  (File size should not exceed 5MB)</span>
        </p>
      </div>
      <div class="row g-0 gx-2 file mb-2" *ngIf="file">
        <p class="mb-2 text-muted">
          Are you sure you want to proceed with this action?
        </p>
        <div class="col">
          <img loading="lazy" src="assets/icons/payment-proof.svg" />
        </div>
        <div class="col-9">
          <p class="mb-0 font-14 truncate" style="max-width: 200px">
            {{ fileName | titlecase }}
          </p>
          <p class="mb-2 font-14 text-muted">{{ fileSize }}</p>
          <div class="d-flex align-items-center gap-2">
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                [style.width.%]="progress"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="mb-0">{{ progress }}%</p>
          </div>
        </div>
        <div class="col text-center">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="15"
              height="15"
              rx="7.5"
              fill="#3D1560"
            />
            <path
              d="M11.3337 5.5L6.75033 10.0833L4.66699 8"
              stroke="white"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <rect
              x="0.5"
              y="0.5"
              width="15"
              height="15"
              rx="7.5"
              stroke="#3D1560"
            />
          </svg>
          <hr class="mb-2" style="background: #9b53d9" />
          <svg
            width="12"
            height="14"
            viewBox="0 0 12 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            (click)="clearFile()"
            class="pointer"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.5247 2.49401C11.7841 2.49401 12 2.70934 12 2.98334V3.23667C12 3.504 11.7841 3.726 11.5247 3.726H0.475902C0.215907 3.726 0 3.504 0 3.23667V2.98334C0 2.70934 0.215907 2.49401 0.475902 2.49401H2.41971C2.81457 2.49401 3.1582 2.21335 3.24703 1.81735L3.34882 1.36269C3.50702 0.74336 4.02766 0.332031 4.62351 0.332031H7.37649C7.96585 0.332031 8.49233 0.743361 8.64469 1.33002L8.75362 1.81668C8.8418 2.21335 9.18543 2.49401 9.58094 2.49401H11.5247ZM10.5371 11.7548C10.74 9.86349 11.0953 5.3702 11.0953 5.32487C11.1083 5.18754 11.0635 5.05754 10.9747 4.95287C10.8794 4.85487 10.7588 4.79688 10.6259 4.79688H1.37887C1.24531 4.79688 1.11823 4.85487 1.03005 4.95287C0.940574 5.05754 0.896485 5.18754 0.902969 5.32487C0.90416 5.3332 0.916909 5.49148 0.938224 5.75609C1.03291 6.93159 1.29664 10.2056 1.46705 11.7548C1.58765 12.8961 2.33651 13.6135 3.42123 13.6395C4.25827 13.6588 5.1206 13.6655 6.00239 13.6655C6.83294 13.6655 7.67647 13.6588 8.53945 13.6395C9.66178 13.6201 10.41 12.9155 10.5371 11.7548Z"
              fill="#E61B00"
            />
          </svg>
        </div>
      </div>
      <div class="d-flex align-items-center gap-2 mt-4">
        <button
          (click)="clearFile(); closeBtn.click()"
          class="submit-btn fw-700 font-13 btn-decline"
        >
          Cancel
        </button>
        <button
          [disabled]="progress < 100"
          class="submit-btn fw-700 font-13"
          (click)="uploadProof()"
        >
          Upload Proof
        </button>
      </div>
    </div>
  </div>
</swap-modal>
