import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MessagesService } from '../../../services/api/messages/messages.service';
import { TransactionService } from '../../../services/api/transactions/transaction.service';
import { Transaction } from '../../../services/core/IApp';
import { GlobalsService } from '../../../services/core/globals';

@Component({
  selector: 'transaction-modal',
  templateUrl: './transaction-modal.component.html',
  styleUrls: ['./transaction-modal.component.scss'],
})
export class TransactionModalComponent {
  @Input() transaction: Transaction | any;
  @Input() utc_offset: string = '';
  @Input() modalId: string = '';
  @Input() modalId2: string = '';
  @Output() transactionAction: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() cancelTransaction: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @ViewChild('closeBtn') closeBtn: any;
  paymentProof: string = '';
  modalId10: string = 'viewProofModal';

  constructor(
    public globals: GlobalsService,
    public messagesService: MessagesService,
    public transactionService: TransactionService
  ) {}

  navigate(user_id: string | undefined) {
    this.closeBtn.nativeElement.click();
    this.globals.router.navigateByUrl(`/main/profile/${user_id}`);
  }

  calculateCurrency(val1: any, val2: any) {
    return Number(val1) * Number(val2);
  }

  navigateToChat(id: string | undefined) {
    this.globals.router.navigateByUrl('/main/transaction/' + id + '/messages');
    this.globals.closeModal('#' + this.modalId);
  }

  cancelBidding() {
    if (confirm('Are you sure you want to cancel this transaction?')) {
      this.cancelTransaction.emit(true);
      this.closeBtn.nativeElement.click();
    }
  }

  displayUpgradeModal(emit: boolean = false) {
    switch (this.globals.user?.verificationStatus?.rank) {
      case 0:
        this.closeBtn?.nativeElement.click();
        this.globals.openModal(this.modalId2);
        break;
      case 1:
        this.closeBtn?.nativeElement.click();
        this.globals.openModal(this.modalId2);
        break;
      case 2:
        this.closeBtn?.nativeElement.click();
        this.globals.openModal(this.modalId2);
        break;
      case 3:
        this.transactionAction.emit(emit);
        this.closeBtn?.nativeElement.click();
        break;
      default:
        break;
    }
  }
}
