<swap-modal [modalId]="modalId" [scrollable]="true" [right]="true">
  <div class="modal-header border-0">
    <h2 class="modal-title fs-5 fw-bolder" id="staticBackdropLabel">
      {{ title }}
    </h2>
    <button
      type="button"
      class="closeBtn"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeBtn
    >
      <img loading="lazy" src="assets/icons/close-icon.svg" alt="" />
    </button>
  </div>
  <div class="modal-body m-3 mt-0 pt-0">
    <form [formGroup]="ticketForm" (ngSubmit)="onSubmit()">
      <div class="mb-3 user-id" *ngIf="type === 'report'">
        <p class="mb-2 d-flex align-items-center">
          <span class="dot"></span> <small class="text-muted">User's ID:</small>
          <span class="text-dark ms-2">{{ reportUserDetails.username }}</span>
        </p>
        <p
          class="mb-0 d-flex align-items-center"
          *ngIf="reportUserDetails.email"
        >
          <span class="dot"></span> <small class="text-muted">Email:</small>
          <span class="text-dark ms-2">{{ reportUserDetails.email }}</span>
        </p>
      </div>
      <div class="mb-3" *ngIf="type !== 'report'">
        <label for="subject" class="form-label"
          >Subject
          <span class="text-danger">*</span>
        </label>
        <input
          type="text"
          class="form-control"
          id="subject"
          formControlName="subject"
          placeholder="i.e. I want to know about your service"
        />
      </div>
      <div class="mb-3">
        <label class="form-label"
          >{{ type === "report" ? "Reason for Report" : "Ticket Category" }}
          <span class="text-danger">*</span>
        </label>
        <div
          class="form-control dropdown w-100"
          (click)="ticketCategoryDropDown()"
        >
          {{ ticketForm.value.ticket_category || "Select Category" }}
          <div class="dropdown-option">
            <div
              class="dropdown-option-item"
              *ngFor="let category of ticketCategories"
              (click)="selectCategory(category.item)"
            >
              {{ category.item }}
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label for="message" class="form-label"
          >{{ type === "report" ? "Details of the incident" : "Message" }}
          <span class="text-danger">*</span></label
        >
        <textarea
          class="form-control w-100"
          id="message"
          rows="5"
          formControlName="message"
          [placeholder]="
            type === 'report'
              ? 'Provide a detailed description'
              : type === 'disconnect'
              ? 'Please state your reason for disconnecting and indicate a new social account'
              : 'Write your message or inquiry'
          "
        ></textarea>
        <small class="text-muted"> ({{ message.value.length }}/256) </small>
        <div *ngIf="message.invalid && (message.dirty || message.touched)">
          <small
            class="text-danger"
            >{{ message.errors?.['required'] ? "Message is required" : "" }}</small
          >
          <small
            class="text-danger"
            >{{ message.errors?.['minlength'] ? "Message is too short" : "" }}</small
          >
          <small
            class="text-danger"
            >{{ message.errors?.['maxlength'] ? "Message is too long" : "" }}</small
          >
        </div>
      </div>
      <div class="mb-3">
        <div
          class="my-3 border dragArea font-14"
          *ngIf="!ticketForm.value.image"
          [ngClass]="{ dragging: fileOverZone }"
        >
          <input
            type="file"
            accept=".svg, .png, .jpg, .jpeg"
            (change)="uploadFile($event)"
            (dragenter)="onDragEnter()"
            (dragleave)="onDragLeave()"
          />
          <img loading="lazy" src="assets/images/Featured icon1.svg" />
          <p class="mb-0">
            <span class="text-secondary fw-bolder">Click to upload</span> or
            drag and drop
            <span class="font-12">PNG or JPG (max. 800x400px)</span>
          </p>
        </div>
        <div class="row g-0 gx-2 file mb-2" *ngIf="ticketForm.value.image">
          <div class="col-9">
            <p class="mb-0 font-14">{{ fileName | titlecase }}</p>
            <p class="mb-2 font-14 text-muted">
              {{ fileSize }} - {{ progress }}% uploaded
            </p>
          </div>
          <div class="col text-end">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="15"
                height="15"
                rx="7.5"
                fill="#3D1560"
              />
              <path
                d="M11.3337 5.5L6.75033 10.0833L4.66699 8"
                stroke="white"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <rect
                x="0.5"
                y="0.5"
                width="15"
                height="15"
                rx="7.5"
                stroke="#3D1560"
              />
            </svg>
            <button type="button" (click)="clearFile()" class="deleteBtn">
              <svg
                width="36"
                height="37"
                viewBox="0 0 36 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 13.4993H12.1667M12.1667 13.4993H25.5M12.1667 13.4993V25.166C12.1667 25.608 12.3423 26.032 12.6548 26.3445C12.9674 26.6571 13.3913 26.8327 13.8333 26.8327H22.1667C22.6087 26.8327 23.0326 26.6571 23.3452 26.3445C23.6577 26.032 23.8333 25.608 23.8333 25.166V13.4993H12.1667ZM14.6667 13.4993V11.8327C14.6667 11.3907 14.8423 10.9667 15.1548 10.6542C15.4674 10.3416 15.8913 10.166 16.3333 10.166H19.6667C20.1087 10.166 20.5326 10.3416 20.8452 10.6542C21.1577 10.9667 21.3333 11.3907 21.3333 11.8327V13.4993M16.3333 17.666V22.666M19.6667 17.666V22.666"
                  stroke="#797B89"
                  stroke-width="1.67"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <button
        type="submit"
        class="btn btn-primary w-100 mt-5"
        data-bs-dismiss="modal"
        aria-label="Close"
        [disabled]="!subject.value || !message.value || !category.value"
      >
        Submit
      </button>
    </form>
  </div>
</swap-modal>
