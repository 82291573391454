<form class="row g-0 px-3 pt-2 mb-2 gap-2 font-14 type-message" [formGroup]="messageForm">
  <div class="col-12">
    <textarea
      class="form-control"
      name="comment"
      rows="3"
      formControlName="comment"
      placeholder="Send a message..."
      required
      id="comment"
      [value]="messageForm.value.comment"
      (keydown)="onKeydown($event)"
    ></textarea>
  </div>
  <div class="col-12">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center gap-2">
        <!-- <button type="button" class="transparentBtn">
          <img
            loading="lazy"
            src="assets/icons/Image-inv.svg"
            alt="Picture Image"
          />
        </button> -->
        <div
          (click)="toggled = !toggled"
          [(emojiPickerIf)]="toggled"
          [emojiPickerDirection]="'right'"
          (emojiPickerSelect)="addEmoji($event)"
          class="pointer"
        >
          😄
        </div>
      </div>
      <button
        type="button"
        class="submitBtn"
        [disabled]="disableSendBtn"
        (click)="onSubmit()"
      >
        <img src="assets/icons/send_white.svg" alt="Send Button">
      </button>
    </div>
  </div>
</form>
