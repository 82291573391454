<swap-modal [modalId]="modalId" [scrollable]="true" [right]="true">
  <div class="modal-header border-0 mx-3 mt-2">
    <h5 class="modal-title font-18 fw-700" id="staticBackdropLabel">
      Change Avatar
    </h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeBtn
    ></button>
  </div>
  <div class="modal-body mx-3">
    <div class="row g-0 pb-4">
      <div class="col-12 text-center mb-2">
        <img loading="lazy" [src]="avatar" alt="currentImg" width="100" />
      </div>
      <div
        class="col-12 row g-4 mt-0"
        style="height: calc(100vh - 21rem); overflow-y: auto"
      >
        <div class="col-3" *ngFor="let item of imagesCount">
          <img
            loading="lazy"
            [src]="'https://expatswap-avatar.s3.amazonaws.com/' + item + '.png'"
            alt="avatar"
            width="75"
            class="{{
              'https://expatswap-avatar.s3.amazonaws.com/' + item + '.png' ===
              avatar
                ? 'active'
                : ''
            }}"
            (click)="
              selectImg(
                'https://expatswap-avatar.s3.amazonaws.com/' + item + '.png'
              )
            "
            (dblclick)="
              selectImg(
                'https://expatswap-avatar.s3.amazonaws.com/' + item + '.png'
              );
              onSubmit();
              closeBtn.click()
            "
          />
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="submit-btn" (click)="onSubmit()" data-bs-dismiss="modal">
      Use Avatar
    </button>
  </div>
</swap-modal>
