import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'close-transaction',
  templateUrl: './close-transaction.component.html',
  styleUrls: ['./close-transaction.component.scss'],
})
export class CloseTransactionComponent implements OnInit {
  @Input() modalId: string = '';
  @Output() emitCloseConfirmation: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}
}
