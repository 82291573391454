<div class="row g-0 profile-form justify-content-center">
  <div class="col-12 border-bottom mb-4">
    <h6 class="fw-700 p-4 mb-0">Social accounts</h6>
  </div>
  <div class="col-12 row g-4 px-4">
    <div class="row g-0 mb-4 justify-content-between border-bottom pb-4">
      <div class="col-6">
        <div class="d-flex">
          <img loading="lazy" src="assets/icons/facebook.svg" alt="facebook" />
          <div class="ms-2">
            <p class="form-label fw-700 font-14 mb-0">Facebook</p>
            <p
              class="mb-0 text-muted font-12"
              [class.text-primary]="globals.user?.socials?.facebook"
            >
              {{ globals.user?.socials?.facebook || "Not connected" }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <button
          class="submit-btn font-14"
          style="max-width: 124px"
          data-bs-toggle="modal"
          attr.data-bs-target="#{{ modalId3 }}"
          *ngIf="globals.user?.socials?.facebook"
        >
          Disconnect
        </button>
        <button
          class="submit-btn connect font-14"
          style="max-width: 104px"
          *ngIf="!globals.user?.socials?.facebook"
          data-bs-toggle="modal"
          attr.data-bs-target="#{{ modalId2 }}"
        >
          Connect
        </button>
      </div>
    </div>
    <div class="row g-0 mb-4 justify-content-between border-bottom pb-4">
      <div class="col-6">
        <div class="d-flex">
          <img
            loading="lazy"
            src="assets/icons/instagram.svg"
            alt="Instagram"
          />
          <div class="ms-2">
            <p class="form-label fw-700 font-14 mb-0">Instagram</p>
            <p
              class="mb-0 text-muted font-12"
              [class.text-primary]="globals.user?.socials?.instagram"
            >
              {{ globals.user?.socials?.instagram || "Not connected" }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <button
          class="submit-btn font-14"
          style="max-width: 124px"
          *ngIf="globals.user?.socials?.instagram"
          data-bs-toggle="modal"
          attr.data-bs-target="#{{ modalId3 }}"
        >
          Disconnect
        </button>
        <button
          class="submit-btn connect font-14"
          style="max-width: 104px"
          *ngIf="!globals.user?.socials?.instagram"
          data-bs-toggle="modal"
          attr.data-bs-target="#{{ modalId }}"
        >
          Connect
        </button>
      </div>
    </div>
  </div>
</div>

<social-connect
  [modalId]="modalId"
  [type]="'instagram'"
  (emitSocials)="updateSocialAccount({ socials: $event, type: 'instagram' })"
></social-connect>
<social-connect
  [modalId]="modalId2"
  [type]="'facebook'"
  (emitSocials)="updateSocialAccount({ socials: $event, type: 'facebook' })"
></social-connect>
<deactivate-socials-confirmation
  [modalId]="modalId3"
  [modalId2]="modalId4"
></deactivate-socials-confirmation>
<new-ticket
  [modalId]="modalId4"
  type="disconnect"
  disconnectSubject="Disconnect social account"
  (submitForm)="submitForm($event)"
  [ticketCategories]="globals.helpCategories"
></new-ticket>
