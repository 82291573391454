<swap-modal [modalId]="modalId" [centered]="true">
  <div class="modal-header border-0">
    <img loading="lazy" src="assets/images/Featured icon.svg" />
    <button
      type="button"
      class="d-none"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeModal
    ></button>
  </div>
  <form
    class="modal-body mx-3"
    [formGroup]="cancelSubscriptionForm"
    (ngSubmit)="onSubmit()"
  >
    <p class="color-header fw-700 font-18 mb-0">Cancel Subscription</p>
    <p class="font-15 color-text mb-3 p-0">
      Are you sure you want to cancel your subscription?
    </p>
    <div class="mb-3">
      <label class="form-label">Reason for Cancellation</label>
      <button class="form-control dropdown" (click)="selectDropdown($event)">
        {{ cancelSubscriptionForm.value.reason || "Choose reason" }}
        <div class="dropdown-option">
          <div
            class="dropdown-option-item"
            *ngFor="let reason of reasons"
            (click)="selectReason(reason)"
          >
            {{ reason }}
          </div>
        </div>
      </button>
    </div>
    <div class="mb-3">
      <div class="d-flex align-items-center mt-3">
        <input
          class="border-0 color-grey round-10 mr-btn-20"
          type="checkbox"
          name="agree"
          id="agree"
          class="agree-checkbox"
          formControlName="agree"
        />
        <label for="agree"> </label>
        <div class="font-14 color-text pl-3">
          <p class="fw-500 text-decoration-none color-primary pointer mb-0">
            I understand that my billing will stop after the cancellation date.
          </p>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center gap-2">
      <button
        data-bs-dismiss="modal"
        aria-label="Close"
        class="btn-confirm fw-700 font-13"
        type="button"
      >
        Go Back
      </button>
      <button class="btn-confirm fw-700 font-13 btn-delete" type="submit">
        Proceed
      </button>
    </div>
  </form>
</swap-modal>
