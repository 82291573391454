import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { GlobalsService } from 'src/app/services/core/globals';

@Injectable({
  providedIn: 'root',
})
export class AutoLoginGuard implements CanActivate {
  constructor(private globals: GlobalsService) {}

  canActivate(): boolean {
    if (this.globals.storage.getAccessToken()) {
      this.globals.router.navigateByUrl('/main/home');
      return false;
    }
    return true;
  }
}


