import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/services/api/auth/auth.service';
import { SocketService } from 'src/app/services/api/socketio/socket.service';
import { GlobalsService } from 'src/app/services/core/globals';

@Component({
  selector: 'social-account',
  templateUrl: './social-account.component.html',
  styleUrls: ['./social-account.component.scss'],
})
export class SocialAccountComponent implements OnInit {
  @Output() emitSocialAccount: EventEmitter<any> = new EventEmitter<any>();

  modalId: string = 'connectInstagramModal';
  modalId2: string = 'connectFacebookModal';
  modalId3: string = 'deactivateSocialsConfirmationModal';
  modalId4: string = 'ticketModal';

  constructor(
    private authService: AuthService,
    public globals: GlobalsService,
    public socketService: SocketService
  ) {}

  ngOnInit() {}

  async updateSocialAccount({ socials, type }: any) {
    switch (type) {
      case 'facebook':
        this.authService.user.socials.facebook = socials;
        await this.authService.updateSocials(this.authService.user.socials);
        await this.authService.getUserDetails();
        if (this.globals.user.verificationStatus.rank !== 3)
          this.globals.openModal('upgradeModal');
        break;
      case 'instagram':
        this.authService.user.socials.instagram = socials;
        await this.authService.updateSocials(this.authService.user.socials);
        await this.authService.getUserDetails();
        if (this.globals.user.verificationStatus.rank !== 3)
          this.globals.openModal('upgradeModal');
        break;
      default:
        break;
    }
  }

  async submitForm(data: any) {
    await this.socketService.createTicket(data);
  }
}
