<swap-modal [modalId]="modalId" [scrollable]="true" [right]="true">
  <div class="modal-header mb-4">
    <p class="modal-title fw-bolder" id="staticBackdropLabel">
      {{ type.replace("-", " ") | titlecase }} With
      <img src="assets/icons/stripe.svg" alt="" />
    </p>
    <button
      type="button"
      class="closeBtn"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeBtn
    >
      <img src="assets/icons/close-icon.svg" alt="" />
    </button>
  </div>
  <form
    class="modal-body m-3 mt-0 pt-0"
    [formGroup]="paymentCardForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="mb-3">
      <label class="color-header fw-700 mb-2 font-14" for="cardName">
        Card Holder Name <span class="text-danger">*</span>
      </label>
      <input
        class="font-14 w-full fw-500 input-shape p-3"
        type="text"
        formControlName="cardHolderName"
        name="cardHolderName"
        id="cardName"
        placeholder="Enter Card Holder Name"
      />
      <small
        class="text-danger"
        *ngIf="cardHolderName.errors?.['required'] && (cardHolderName.dirty || cardHolderName.touched)"
      >
        Card holder name is required
      </small>
    </div>
    <div class="mb-3">
      <label class="color-header fw-700 mb-2 font-14" for="cardNumber">
        Card Number <span class="text-danger">*</span>
      </label>
      <div class="position-relative">
        <input
          class="font-14 w-full fw-500 input-shape p-3"
          style="padding-right: 5.7rem !important"
          type="text"
          formControlName="cardNumber"
          name="cardNumber"
          (input)="formatCardNumber($event)"
          maxlength="19"
          id="cardNumber"
          placeholder="i.e. 1234 5678 9012 3456"
        />
        <div class="card-imgs">
          <img
            loading="lazy"
            src="assets/icons/mastercard.svg"
            [ngClass]="{
              'shrink-out': paymentCardForm.value.cardNumber && !masterCard,
              'shrink-in': paymentCardForm.value.cardNumber && masterCard,
            }"
          />
          <img
            loading="lazy"
            style="right: 0"
            src="assets/icons/visa.svg"
            class="{{
              paymentCardForm.value.cardNumber && !visaCard ? 'shrink-out' : ''
            }} {{
              paymentCardForm.value.cardNumber && visaCard ? 'shrink-in' : ''
            }}"
          />
        </div>
      </div>
      <small
        *ngIf="cardNumber.errors?.['required'] && (cardNumber.dirty || cardNumber.touched)"
        class="text-danger"
      >
        Card number is required
      </small>
      <small
        *ngIf="cardNumber.errors?.['cardNumber'] && (cardNumber.dirty || cardNumber.touched)"
        class="text-danger"
      >
        We accept Visa and Mastercard only
      </small>
    </div>
    <div class="row g-0 gx-3">
      <div class="col-6">
        <div class="mb-3">
          <label class="color-header fw-700 mb-2 font-14" for="expiryDate">
            Exp Date <span class="text-danger">*</span>
          </label>
          <input
            class="font-14 w-full fw-500 input-shape p-3"
            type="text"
            name="expiryDate"
            (keyup)="formatExpiryDate($event)"
            maxlength="5"
            formControlName="expiry_date"
            id="expiryDate"
            placeholder="MM/YY"
          />
          <small
            class="text-danger"
            *ngIf="expiry_date?.errors?.['required'] && (expiry_date.dirty || expiry_date.touched)"
          >
            Expiry date is required
          </small>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <label class="color-header fw-700 mb-2 font-14" for="cvc">
            CVC <span class="text-danger">*</span>
          </label>
          <div class="position-relative">
            <input
              class="font-14 w-full fw-500 input-shape p-3"
              type="text"
              style="padding-right: 3rem !important"
              name="cvc"
              formControlName="cvc"
              maxlength="3"
              id="cvc"
              placeholder="CVC"
            />
            <img
              loading="lazy"
              src="assets/icons/credit-card.svg"
              alt=""
              class="position-absolute cvc-img"
            />
          </div>
          <small
            class="text-danger"
            *ngIf="cvc.errors?.['required'] && (cvc.dirty || cvc.touched)"
          >
            CVC is required
          </small>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <label class="form-label">Billing Address</label>
      <button
        class="form-control dropdown p-3"
        (click)="selectDropdown($event)"
        [ngClass]="{
        'text-muted': !paymentCardForm.value.country,
      }"
      >
        {{ paymentCardForm.value.country || "Choose country" }}
        <div class="dropdown-option">
          <div
            class="dropdown-option-item"
            *ngFor="let country of globals.countries"
            (click)="selectCountry(country.name)"
          >
            {{ country.name }}
          </div>
        </div>
      </button>
      <input
        class="font-14 w-full fw-500 input-shape p-3"
        style="border-radius: 0 0 15px 15px"
        type="text"
        name="postal_code"
        id="postal_code"
        formControlName="postalCode"
        placeholder="Postal Code"
      />
      <small
        class="text-danger"
        *ngIf="postalCode?.errors?.['required'] && (postalCode.dirty || postalCode.touched)"
      >
        Postal code is required
      </small>
    </div>

    <div class="text-center">
      <button
        class="w-full bg-purple p-3 color-white round-12 border-0 font-16 fw-700 mt-2"
        [disabled]="!paymentCardForm.valid"
        type="submit"
      >
        Submit
      </button>
    </div>
  </form>
</swap-modal>
