import { Component, Input, OnInit } from '@angular/core';
import { faq, faqList } from '../../../shared/data/faq';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() item: any;

  @Input() route: string = '';

  faqlists = faqList;

  filteredFaqs(category: any): faq[] {
    return this.faqlists.filter((faq) => faq.category === category);
  }

  constructor() {}

  ngOnInit(): void {}
}
