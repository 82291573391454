import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'end-transaction',
  templateUrl: './end-transaction.component.html',
  styleUrls: ['./end-transaction.component.scss'],
})
export class EndTransactionComponent implements OnInit {
  @Input() modalId: string = '';
  @Output() emitEndTransaction: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}
}
