import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'change-avatar',
  templateUrl: './change-avatar.component.html',
  styleUrls: ['./change-avatar.component.scss'],
})
export class ChangeAvatarComponent implements OnInit {
  @Input() modalId: string = '';

  @Input() avatar: string = '';

  @Output() emitAvatar: EventEmitter<string> = new EventEmitter<string>();

  searchTerm: string = '';

  imagesCount: Array<number> = Array.from(Array(123).keys()).map((i) => i + 1);

  constructor() {}

  ngOnInit() {}

  selectImg(img: string) {
    this.avatar = img;
  }

  onSubmit() {
    this.emitAvatar.emit(this.avatar);
  }
}
