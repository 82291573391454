<swap-modal
  [modalId]="modalId"
  size="xl"
  backdrop="static"
  [keyboard]="false"
  [centered]="true"
>
  <div class="row g-0 mx-5">
    <button
      type="button"
      class="btn-close d-none"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeBtn
    ></button>

    <div
      class="col-12 d-flex align-items-center justify-content-center my-3 gap-2 mt-5"
    >
      <div class="text-center">
        <img
          loading="lazy"
          [src]="
            globals.user?._id == ratingReview?.bidding?.buyer?.userId?._id
              ? ratingReview?.bidding?.buyer?.userId?.avatar
              : ratingReview?.bidding?.seller?.userId?.avatar
          "
          width="83.33px"
          height="83.33px"
        />
        <div class="font-12 fw-500">
          {{
            globals.user?._id == ratingReview?.bidding?.buyer?.userId?._id
              ? ratingReview?.bidding?.buyer?.userId?.username
              : ratingReview?.bidding?.seller?.userId?.username
          }}
        </div>
      </div>
      <img loading="lazy" src="./assets/images/Swap.svg" />
      <div class="text-center">
        <img
          loading="lazy"
          [src]="
            globals.user?._id == ratingReview?.bidding?.buyer?.userId?._id
              ? ratingReview?.bidding?.seller?.userId?.avatar
              : ratingReview?.bidding?.buyer?.userId?.avatar
          "
          width="83.33px"
          height="83.33px"
        />
        <div class="font-12 fw-500">
          {{
            globals.user?._id == ratingReview?.bidding?.buyer?.userId?._id
              ? ratingReview?.bidding?.seller?.userId?.username
              : ratingReview?.bidding?.buyer?.userId?.username
          }}
        </div>
      </div>
    </div>
    <div class="col-12 text-center border-bottom pb-4">
      <h5>Transaction completed 🎉 !</h5>
      <p class="mb-0 text-muted">
        <b>
          {{
            globals.user?._id == ratingReview?.bidding?.buyer?.userId?._id
              ? ratingReview?.bidding?.seller?.userId?.username
              : ratingReview?.bidding?.buyer?.userId?.username
          }}
        </b>
        enjoyed having you in today’s deal.
      </p>
    </div>
    <div class="col-12 text-center mt-4">
      <h6 class="fw-bolder">How was the transaction?</h6>
      <p class="mb-3 font-14">
        Kindly review
        {{
          globals.user?._id == ratingReview?.bidding?.buyer?.userId?._id
            ? ratingReview?.bidding?.seller?.userId?.username
            : ratingReview?.bidding?.buyer?.userId?.username
        }}
      </p>
      <div class="d-flex align-items-center justify-content-center gap-3 mb-4">
        <div
          class="emoji-btn"
          *ngFor="let emoji of emojis"
          (click)="ratingsReviewForm.patchValue({ rating: emoji.value })"
          [ngClass]="{ active: ratingsReviewForm.value.rating === emoji.value }"
        >
          <img
            [src]="emoji.img"
            alt=""
            loading="lazy"
            width="24px"
            style="object-fit: cover"
          />
          <p class="mb-0">
            {{ emoji.name }}
          </p>
        </div>
      </div>
      <h6 class="fw-bolder mb-3">Commend the Dealer</h6>
      <form [formGroup]="ratingsReviewForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <textarea
            class="form-control"
            formControlName="comment"
            rows="8"
            placeholder="Write your review here..."
          ></textarea>
        </div>
        <div
          class="d-flex align-items-center justify-content-center gap-2 my-4"
        >
          <button
            type="submit"
            class="submit-btn"
            style="max-width: 162px"
            [disabled]="ratingsReviewForm.invalid"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</swap-modal>
