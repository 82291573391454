import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalsService } from '../../../services/core/globals';

@Component({
  selector: 'cancel-subscription',
  templateUrl: './cancel-subscription.component.html',
  styleUrls: ['./cancel-subscription.component.scss'],
})
export class CancelSubscriptionComponent {
  @Input() modalId: string = '';

  @Output() emitCancelSubscription: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('closeModal') closeModal: HTMLButtonElement | any;

  cancelSubscriptionForm: FormGroup = new FormGroup({
    reason: new FormControl('', Validators.required),
    agree: new FormControl(false, Validators.requiredTrue),
  });

  reasons: string[] = [
    'Dissatisfaction of services',
    'Found better alternatives',
    'Incorrect charges',
    'Temporary need',
    'Unavailability of support',
    'Poor user experience/interface',
    'Financial constraints',
    'Other',
  ];

  constructor(public globals: GlobalsService) {}

  ngOnInit(): void {}

  onSubmit() {
    if (this.cancelSubscriptionForm.invalid) {
      this.globals.toast.warn('Please fill out all required fields');
    }
    this.emitCancelSubscription.emit(this.cancelSubscriptionForm.value.reason);
    this.closeModal.nativeElement.click();
    this.cancelSubscriptionForm.reset();
  }

  selectDropdown(event: any) {
    // close other dropdown options
    event.preventDefault();
    let mainElement = event.target;
    const element = document.querySelectorAll('.dropdown');
    element.forEach((el) => {
      if (
        el.classList.contains('active') &&
        el !== event.target.nextElementSibling
      ) {
        el.classList.remove('active');
      }
    });

    mainElement.classList.toggle('active');

    // close dropdown options when click outside
    document.addEventListener('mousedown', (e: any) => {
      if (!e.target?.classList.contains('dropdown-option-item')) {
        element.forEach((el) => {
          if (el.classList.contains('active')) {
            el.classList.remove('active');
          }
        });
      }
    });
  }

  selectReason(reason: string) {
    this.cancelSubscriptionForm.controls['reason'].setValue(reason);
  }
}
