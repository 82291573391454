<swap-modal [modalId]="modalId" [right]="true" #pdfContent>
  <!-- Right Pop up -->
  <div class="modal-header border-0">
    <h5 class="modal-title fw-700 fs-4" id="staticBackdropLabel">
      Transaction
    </h5>
    <button
      type="button"
      class="closeBtn"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeBtn
    >
      <img loading="lazy" src="assets/icons/close-icon.svg" alt="" />
    </button>
  </div>
  <div class="modal-body row align-items-start transactionModal m-3 mt-0">
    <div class="col-12 mb-4">
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="d-flex justify-content-center align-items-center flex-column gap-1 pointer"
          (click)="
            navigate(
              globals.user?._id !== activity?.bidding?.buyer?.user?._id
                ? activity?.bidding?.seller?.user?._id
                : activity?.bidding?.buyer?.user?._id
            )
          "
        >
          <img
            loading="lazy"
            [src]="
              globals.user?._id !== activity?.bidding?.buyer?.user?._id
                ? activity?.bidding?.seller?.user?.avatar
                : activity?.bidding?.buyer?.user?.avatar ??
                  'assets/icons/user.svg'
            "
            class="rounded-circle"
            alt="avatar"
            width="83"
            height="83"
          />
          <p class="mb-0 font-12 text-color">
            {{
              globals.user?._id !== activity?.bidding?.buyer?.user?._id
                ? activity?.bidding?.seller?.user?.username
                : activity?.bidding?.buyer?.user?.username
            }}
          </p>
          <small class="text-muted font-10">{{
            globals.user?._id !== activity?.bidding?.buyer?.user?._id
              ? "Seller"
              : "Buyer"
          }}</small>
        </div>
        <img
          loading="lazy"
          src="assets/icons/swap-row-icon.svg"
          alt=""
          class="mx-4"
          width="20"
        />
        <div
          class="d-flex justify-content-center align-items-center flex-column gap-1 pointer"
          (click)="
            navigate(
              globals.user?._id === activity?.bidding?.buyer?.user?._id
                ? activity?.bidding?.seller?.user?._id
                : activity?.bidding?.buyer?.user?._id
            )
          "
        >
          <img
            loading="lazy"
            [src]="
              globals.user?._id === activity?.bidding?.buyer?.user?._id
                ? activity?.bidding?.seller?.user?.avatar
                : activity?.bidding?.buyer?.user?.avatar ??
                  'assets/icons/user.svg'
            "
            class="rounded-circle"
            alt="avatar"
            width="83"
            height="83"
          />
          <p class="mb-0 fw-500 font-12 text-muted">
            {{
              globals.user?._id === activity?.bidding?.buyer?.user?._id
                ? activity?.bidding?.seller?.user?.username
                : activity?.bidding?.buyer?.user?.username
            }}
          </p>
          <small class="text-muted font-10">
            {{
              globals.user?._id === activity?.bidding?.buyer?.user?._id
                ? "Seller"
                : "Buyer"
            }}
          </small>
        </div>
      </div>
      <div class="mt-3 text-center">
        <p class="mb-0 text-muted font-14 fw-700">Payment</p>
        <h3 class="mb-0">
          {{
            globals.user?._id == activity?.bidding?.seller?.user?._id
              ? (activity?.bidding?.amount || 0
                | currency : activity?.localCurrency?.code : "symbol-narrow")
              : (activity?.bidding?.amount * activity?.rate || 0
                | currency : activity?.foreignCurrency?.code : "symbol-narrow")
          }}
        </h3>
      </div>
    </div>
    <div class="col-12">
      <section class="d-flex justify-content-center align-items-center mb-4">
        <div class="filterOptions">
          <ul class="nav nav-pills">
            <li
              class="nav-item"
              *ngFor="let tab of navTabs; let i = index"
              (click)="activeTab = i"
            >
              <a
                class="nav-link font-14 filterTab pointer"
                aria-current="page"
                [ngClass]="{ active: activeTab == i }"
                >{{ tab.name }}
              </a>
            </li>
          </ul>
        </div>
      </section>

      <!-- Transaction Details Tab -->
      <div class="accordion-body" *ngIf="activeTab == 0">
        <div class="row mb-2">
          <div class="col-6 d-flex align-items-center">
            <p class="mb-0 font-14 text-muted">Transaction ID</p>
          </div>
          <div class="col-6 d-flex justify-content-end align-items-center">
            <p class="mb-0 font-14 fw-500">#{{ activity?._id?.slice(-8) }}</p>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-6 d-flex align-items-center">
            <p class="mb-0 font-14 text-muted">Status</p>
          </div>
          <div class="col-6 d-flex justify-content-end align-items-center">
            <p
              class="mb-0 font-14 label"
              [ngClass]="{
                      'success': activity?.bidding.biddingStatus === 'completed',
                      'secondary': activity?.bidding.biddingStatus === 'declined',
                      'danger': activity?.bidding.biddingStatus === 'failed',
                    }"
            >
              {{ activity?.bidding.biddingStatus | titlecase }}
            </p>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-6 d-flex align-items-center">
            <p class="mb-0 font-14 text-muted">Seller's ID</p>
          </div>
          <div class="col-6 d-flex justify-content-end align-items-center">
            <p class="mb-0 font-14 fw-700">
              {{ activity?.bidding?.seller?.user?.username }}
            </p>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-6 d-flex align-items-center">
            <p class="mb-0 font-14 text-muted">Transfer Approval</p>
          </div>
          <div class="col-6 d-flex justify-content-end align-items-center">
            <p class="mb-0 font-14 fw-700">
              {{ "Yes" }}
            </p>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-6 d-flex align-items-center">
            <p class="mb-0 font-14 text-muted">Received</p>
          </div>
          <div class="col-6 d-flex justify-content-end align-items-center">
            <p class="mb-0 font-14 fw-700">
              {{
                activity?.bidding.amount * activity?.rate
                  | currency : activity?.foreignCurrency?.code : "symbol-narrow"
              }}
            </p>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-6 d-flex align-items-center">
            <p class="mb-0 font-14 text-muted">Sent</p>
          </div>
          <div class="col-6 d-flex justify-content-end align-items-center">
            <p class="mb-0 font-14 fw-700">
              {{
                activity?.bidding.amount
                  | currency : activity?.localCurrency?.code : "symbol-narrow"
              }}
            </p>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-6 d-flex align-items-center">
            <p class="mb-0 font-14 text-muted">Rate</p>
          </div>
          <div class="col-6 d-flex justify-content-end align-items-center">
            <p class="mb-0 font-14 fw-700">
              {{ activity?.rate }}
            </p>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-6 d-flex align-items-center">
            <p class="mb-0 font-14 text-muted">Sent from</p>
          </div>
          <div class="col-6 d-flex justify-content-end align-items-center">
            <p class="mb-0 font-14 fw-700">
              {{ activity?.paymentSendFrom }}
            </p>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-6 d-flex align-items-center">
            <p class="mb-0 font-14 fw-400 text-muted">Receive through</p>
          </div>
          <div class="col-6 d-flex justify-content-end align-items-center">
            <p class="mb-0 font-14 fw-700">
              {{ activity?.paymentReceiveThrough }}
            </p>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-6 d-flex align-items-center">
            <p class="mb-0 font-14 text-muted">Date</p>
          </div>
          <div class="col-6">
            <p class="font-14 color-body text-end mb-0 fw-bolder">
              {{
                activity?.bidding.updatedAt | date : "mediumDate" : utc_offset
              }}
            </p>
            <p class="font-12 color-text text-end mb-0">
              at
              {{
                activity?.bidding.updatedAt | date : "shortTime" : utc_offset
              }}
            </p>
          </div>
        </div>
      </div>
      <!-- End of Transaction Details Tab-->

      <!-- Document Tab -->
      <div class="accordion-body" *ngIf="activeTab == 1">
        <div class="d-flex justify-content-between gap-2">
          <div class="document" *ngIf="activity?.bidding?.buyer?.upload">
            <img
              loading="lazy"
              [src]="activity?.bidding?.buyer?.upload?.url"
              alt="Proof of Payment"
            />
            <p class="mb-0 font-14">
              @
              {{ activity?.bidding?.buyer?.user?.username }}
            </p>
            <p class="mb-0 font-14 text-muted">
              Uploaded
              <span class="text-primary"
                >@
                {{
                  activity?.bidding?.buyer?.upload?.date
                    | date : "hh:mm a" : utc_offset
                }}</span
              >
            </p>
          </div>
          <div class="document" *ngIf="activity?.bidding?.seller?.upload">
            <img
              loading="lazy"
              [src]="activity?.bidding?.seller?.upload?.url"
              alt="Proof of Payment"
            />
            <p class="mb-0 font-14">
              @ {{ activity?.bidding?.seller?.user?.username }}
            </p>
            <p class="mb-0 font-14 text-muted">
              Uploaded
              <span class="text-primary">
                @
                {{
                  activity?.bidding?.seller?.upload?.date
                    | date : "hh:mm a" : utc_offset
                }}</span
              >
            </p>
          </div>
        </div>
      </div>
      <!-- End of Document Tab -->

      <!-- Share and Download buttons -->
      <div class="d-flex align-items-center gap-2 mt-4">
        <button class="submit-btn fw-700 font-13 btn-share">Share</button>
        <button class="submit-btn fw-700 font-13" (click)="savePDF()">
          Download Receipt
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            class="ms-2"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.92257 5.46789V2.46113C8.92257 2.14142 9.17758 1.875 9.50008 1.875C9.78883 1.875 10.0335 2.09887 10.0722 2.38244L10.0776 2.46113V5.46789L13.6625 5.46812C15.4475 5.46812 16.914 6.92984 16.9964 8.75281L17 8.91457V12.694C17 14.5298 15.5845 16.0366 13.826 16.1213L13.67 16.125H5.33C3.545 16.125 2.08557 14.6706 2.00363 12.8409L2 12.6786L2 8.90682C2 7.07108 3.40843 5.55691 5.1665 5.47188L5.3225 5.46812H8.9225V10.2699L7.7225 9.03074C7.4975 8.79839 7.13 8.79839 6.905 9.03074C6.7925 9.14691 6.74 9.30181 6.74 9.45671C6.74 9.57443 6.7736 9.6971 6.84464 9.80095L6.905 9.87493L9.0875 12.1364C9.1925 12.2526 9.3425 12.3145 9.5 12.3145C9.625 12.3145 9.75 12.2715 9.84896 12.1899L9.905 12.1364L12.0875 9.87493C12.3125 9.64258 12.3125 9.26308 12.0875 9.03074C11.883 8.81952 11.5606 8.80031 11.3347 8.97313L11.27 9.03074L10.0775 10.2699V5.46812L8.92257 5.46789Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      <!-- End of Share and Download buttons -->
    </div>
  </div>
</swap-modal>
