<swap-modal
  [modalId]="modalId"
  [scrollable]="true"
  [right]="true"
  [modalContentClass]="{ verify: true }"
  *ngIf="verificationStatus && verificationStatus.rank < 3"
>
  <div class="modal-header border-0 mx-3">
    <h2 class="modal-title fs-5 fw-bolder" id="staticBackdropLabel">
      Upgrade your Account.
    </h2>
    <button
      type="button"
      class="btn-close closeBtn"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeModal
    ></button>
  </div>

  <div class="modal-body mx-3">
    <section>
      <div class="fw-400 mb-4 font-16">
        To smoothly proceed with your transaction, it's necessary to complete a
        few quick verifications. Please verify your phone, social media, and ID
        now. This will enable you to enjoy an uninterrupted experience on our
        platform.
      </div>

      <div class="card-backg p-3 mb-4">
        <div class="d-flex mb-3 justify-content-between align-items-center">
          <img
            loading="lazy"
            style="width: 42px"
            src="/assets/images/tier-1.svg"
            alt=""
          />
          <button
            class="p-2 font-14 fw-700 border-0 btn-upgrade"
            data-bs-toggle="modal"
            attr.data-bs-target="#{{ verifyPhoneModalId }}"
            (click)="closeModal.click()"
            *ngIf="verificationStatus && verificationStatus.rank == 0"
          >
            Upgrade
          </button>
          <img
            loading="lazy"
            *ngIf="verificationStatus && verificationStatus.rank >= 1"
            src="assets/icons/verification-checked.svg"
            alt=""
          />
        </div>
        <div class="font-16 mt-1 fw-700">Tier 1</div>
        <div class="font-14 fw-400">Please verify your phone number.</div>
      </div>

      <div class="card-backg mb-4 p-3">
        <div class="d-flex mb-3 justify-content-between align-items-center">
          <img
            loading="lazy"
            style="width: 42px"
            src="/assets/images/tier-2.svg"
            alt=""
          />
          <button
            class="p-2 font-14 fw-700 border-0 btn-upgrade"
            *ngIf="verificationStatus && verificationStatus.rank == 1"
            (click)="navigate('/main/settings/social-account')"
          >
            Upgrade
          </button>
          <img
            loading="lazy"
            *ngIf="verificationStatus && verificationStatus.rank >= 2"
            src="assets/icons/verification-checked.svg"
            alt=""
          />
        </div>
        <div class="font-16 mt-1 fw-700">Tier 2</div>
        <div class="font-14 fw-400">Please verify your socials.</div>
      </div>

      <div class="card-backg mb-4 p-3">
        <div class="d-flex mb-3 justify-content-between align-items-center">
          <img
            loading="lazy"
            style="width: 42px"
            src="/assets/images/tier-3.svg"
            alt=""
          />
          <button
            class="p-2 font-14 fw-700 border-0 btn-upgrade"
            (click)="
              window.open(
                verificationURL +
                  '?token=' +
                  globals.quickEncrypt(globals.user?.username),
                '_blank'
              )
            "
            *ngIf="verificationStatus && verificationStatus.rank == 2"
          >
            Upgrade
          </button>
          <img
            loading="lazy"
            *ngIf="verificationStatus && verificationStatus.rank >= 3"
            src="assets/icons/verification-checked.svg"
            alt=""
          />
        </div>
        <div class="font-16 mt-1 fw-700">Tier 3</div>
        <div class="font-14 fw-400">
          Verify your identity to upgrade your account.
        </div>
      </div>
    </section>
  </div>
</swap-modal>
