import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Validation from '../../../pages/auth/validation';
import { GlobalsService } from '../../../services/core/globals';

@Component({
  selector: 'payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss'],
})
export class PaymentModalComponent {
  @Input() modalId: string = '';

  @Input() type: string = 'add-card';

  @Output() submitForm: EventEmitter<any> = new EventEmitter<any>();

  paymentCardForm: FormGroup = new FormGroup({
    cardNumber: new FormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(19),
        Validators.maxLength(19),
        Validation.validateCardNumber,
      ])
    ),
    cardHolderName: new FormControl(
      '',
      Validators.compose([Validators.required])
    ),
    expiry_date: new FormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(5),
      ])
    ),
    cvc: new FormControl('', Validators.compose([Validators.required])),
    country: new FormControl('', Validators.compose([Validators.required])),
    postalCode: new FormControl('', Validators.compose([Validators.required])),
  });

  visaCard: boolean = false;
  masterCard: boolean = false;

  constructor(public globals: GlobalsService) {}

  onSubmit() {
    this.submitForm.emit(this.paymentCardForm.value);
  }

  formatCardNumber(event: any) {
    let cardNumber = event.target.value;
    cardNumber = cardNumber.replace(/\s/g, '');
    let cardNumberLength = cardNumber.length;
    if (cardNumberLength > 0) {
      cardNumber = cardNumber.match(new RegExp('.{1,4}', 'g')).join(' ');
    }
    this.paymentCardForm.controls['cardNumber'].setValue(cardNumber);

    if (cardNumberLength > 0) {
      let cardType = this.validateCardType(cardNumber);
      this.visaCard = false;
      this.masterCard = false;
      if (cardType === 'visa') {
        this.visaCard = true;
      } else if (cardType === 'mastercard') {
        this.masterCard = true;
      }
    }
  }

  validateCardType(cardNumber: string) {
    let re = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
    return re.test(cardNumber) ? 'visa' : 'mastercard';
  }

  formatExpiryDate(event: any) {
    const keyPress = event.key;
    let expiry_date = event.target.value;
    expiry_date = expiry_date.replace(/\s/g, '');
    let expiry_dateLength = expiry_date.length;
    if (keyPress !== 'Backspace' && expiry_dateLength > 0 && expiry_dateLength < 5) {
      expiry_date = expiry_date.match(new RegExp('.{1,2}', 'g')).join('/');
    }
    this.paymentCardForm.controls['expiry_date'].setValue(expiry_date);
  }

  selectCountry(country: string) {
    this.paymentCardForm.controls['country'].setValue(country);
  }

  selectDropdown(event: any) {
    // close other dropdown options
    event.preventDefault();
    let mainElement = event.target;
    const element = document.querySelectorAll('.dropdown');
    element.forEach((el) => {
      if (
        el.classList.contains('active') &&
        el !== event.target.nextElementSibling
      ) {
        el.classList.remove('active');
      }
    });

    mainElement.classList.toggle('active');

    // close dropdown options when click outside
    document.addEventListener('mousedown', (e: any) => {
      if (mainElement?.classList.contains('dropdown-option')) {
        element.forEach((el) => {
          if (el.classList.contains('active')) {
            el.classList.remove('active');
          }
        });
      }
    });
  }

  get cardNumber() {
    return this.paymentCardForm.get('cardNumber') as FormControl;
  }

  get cardHolderName() {
    return this.paymentCardForm.get('cardHolderName') as FormControl;
  }

  get expiry_date() {
    return this.paymentCardForm.get('expiry_date') as FormControl;
  }

  get cvc() {
    return this.paymentCardForm.get('cvc') as FormControl;
  }

  get postalCode() {
    return this.paymentCardForm.get('postalCode') as FormControl;
  }
}
