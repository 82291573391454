<swap-modal [modalId]="modalId" [centered]="true">
  <div class="modal-header border-0">
    <img loading="lazy" src="assets/images/Featured icon.svg" />
    <button
      type="button"
      class="d-none"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeModal
    ></button>
  </div>
  <div class="modal-body mx-3">
    <p class="color-header fw-700 font-18 mb-0">Deactivate Account</p>
    <div class="font-15 color-text mb-3 p-0">
      Are you sure you want to proceed with this action? Enter
      <b>{{ email }}</b> to deactivate your account.
    </div>
    <div class="p-2 btn2-outline w-full round-8 mb-3">
      <div class="color-text fw-400 font-12 mb-2">Email</div>
      <div class="d-flex">
        <input
          [(ngModel)]="confirmEmail"
          class="border-0 font-14 color-text w-full fw-700 p-1"
          type="email"
          id="emailAddress"
          name="email"
          placeholder="Enter your email"
        />
        <img loading="lazy" src="assets/icons/email-icon.svg" alt="" />
      </div>
    </div>

    <div class="d-flex align-items-center gap-2">
      <button (click)="closeDialog()" class="btn-confirm fw-700 font-13">
        Cancel
      </button>
      <button
        [disabled]="confirmEmail !== email"
        class="btn-confirm fw-700 font-13 btn-delete"
        (click)="deleteAccount()"
      >
        Deactivate
      </button>
    </div>
  </div>
</swap-modal>
