import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { TransactionService } from '../../../services/api/transactions/transaction.service';
import { BankAccount, Country, Offer } from '../../../services/core/IApp';
import { GlobalsService } from '../../../services/core/globals';
import { maxAmount } from '../../validators';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'create-offer',
  templateUrl: './create-offer.component.html',
  styleUrls: ['./create-offer.component.scss'],
})
export class CreateOfferComponent implements OnInit {
  isReadOnly: boolean = false;
  escrowEnabled: boolean = environment.escrowEnabled;
  @Input() modalId: string = '';
  @Input() userProfile: any;

  @Input() offer: Offer | any;

  @Input() countries: any[] = [];

  @Input() paymentMethods: { _id: string; item: string }[] = [];

  @Output() createOffer: EventEmitter<Offer> = new EventEmitter<Offer>();

  @ViewChild('closeModal') closeModal: any;

  iHave: Country | any;

  iWant: Country | any;

  createOfferForm: FormGroup = new FormGroup({
    amount: new FormControl(
      '',
      Validators.compose([Validators.required, Validators.pattern('^[0-9,]*$')])
    ),
    minAmount: new FormControl(
      '',
      Validators.compose([
        maxAmount('0'),
        Validators.required,
        Validators.pattern('^[0-9,]*$'),
      ])
    ),
    foreignCurrency: new FormControl(
      '',
      Validators.compose([Validators.required])
    ),
    localCurrency: new FormControl(
      '',
      Validators.compose([Validators.required])
    ),
    rate: new FormControl('', Validators.compose([Validators.required])),
    rating: new FormControl(0),
    preferredCountries: new FormControl([]),
    paymentSendFrom: new FormControl('Bank Transfer'),
    paymentReceiveThrough: new FormControl('Bank Transfer'),
    transactionPurpose: new FormControl(
      '',
      Validators.compose([Validators.required])
    ),
    verificationStatus: new FormControl({}),
    offerType: new FormControl('non-escrow'),
    receivingAccount: new FormControl(''),
  });

  transactionPurposeList: Array<string> = [
    'Allowance/Maintenance',
    'Educational Support',
    'Family Support',
    'Gift',
    'Investment',
    'Loan',
    'Medical payment',
    'Real Estate',
    'Rent',
    'School Fees',
    'Tax payment',
    'Trade transaction',
    'Travel',
    'Utilities',
  ];

  offerTypes: Array<string> = ['non-escrow', 'escrow'];

  verificationStatus: Array<{ name: string; rank: number }> = [
    { name: 'tier', rank: 3 },
    { name: 'tier', rank: 2 },
    { name: 'tier', rank: 1 },
  ];

  rateType: Array<string> = ['5', '4', '3', '2', '1'];

  selectedCountries: Country[] = [];
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    limitSelection: 3,
    allowSearchFilter: true,
  };

  // initialize variables for currency search filter
  searchCurrency: string = '';
  searchPaymentMethod: string = '';

  // initialize variables for currency conversion
  convertedRate: number = 0;

  constructor(
    private globals: GlobalsService,
    public transactionService: TransactionService
  ) {}

  async ngOnInit() {
    this.resetForm();
    await this.getGlobalRate();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['offer']) {
      const change = changes['offer'];
      console.log('Offer previous value:', change.previousValue);
      console.log('Offer current value:', change.currentValue);
    }
    console.log('Offer current value2 for offer:', this.offer);

    if (this.offer && Object.keys(this.offer).length > 0) {
      this.isReadOnly = true;
      this.createOfferForm.patchValue(this.offer);
      this.createOfferForm.patchValue({
        iHave: this.offer.iHave,
        iWant: this.offer.iWant,
        rate: this.offer.rate,
        amount: this.offer.amount,
      });

      const countryValues = this.offer?.preferredCountries.map(
        (res: { country: any }) => res.country
      );
      this.createOfferForm.patchValue({
        preferredCountries: countryValues,
        foreignCurrency: this.offer?.foreignCurrency,
        localCurrency: this.offer?.localCurrency,
      });
      this.createOfferForm.get('preferredCountries')?.disable();
      this.iWant = this.globals.countries.find(
        (country) => country.name === this.offer?.foreignCurrency?.country
      );
      this.iHave = this.globals.countries.find(
        (country) => country.name === this.offer?.localCurrency?.country
      );
    } else {
      this.offer = undefined;
      this.createOfferForm.get('preferredCountries')?.enable();
      this.resetForm();
      this.isReadOnly = false;
    }
    // if (changes['offer']) {
    //   const change = changes['offer'];
    //   console.log('Offer previous value:', change.previousValue);
    //   console.log('Offer current value:', change.currentValue);
    // }

    console.log('This is offer', this.createOfferForm.value);
  }

  // ngOnChanges() {

  //   if (this.offer && Object.keys(this.offer).length > 0) {
  //     this.isReadOnly = true;
  //     this.createOfferForm.patchValue(this.offer);
  //     const countryValues = this.offer?.preferredCountries.map(
  //       (res: { country: any }) => res.country
  //     );
  //     this.createOfferForm.patchValue({
  //       preferredCountries: countryValues,
  //       foreignCurrency: this.offer?.foreignCurrency,
  //       localCurrency: this.offer?.localCurrency,
  //     });
  //     this.createOfferForm.get('preferredCountries')?.disable();
  //     this.iWant = this.globals.countries.find(
  //       (country) => country.name === this.offer?.foreignCurrency?.country
  //     );
  //     this.iHave = this.globals.countries.find(
  //       (country) => country.name === this.offer?.localCurrency?.country
  //     );
  //   } else {
  //     this.offer = undefined;
  //     this.createOfferForm.get('preferredCountries')?.enable();
  //     this.resetForm();
  //     this.isReadOnly = false;
  //   }
  //
  // }

  closeOpenModal() {
    this.closeModal.nativeElement.click();
  }

  setMinimumAmount(event: any) {
    this.createOfferForm.get('minAmount')?.setValue(event.target.value);
    this.createOfferForm
      .get('minAmount')
      ?.setValidators([maxAmount(event.target.value)]);
    this.createOfferForm.get('minAmount')?.updateValueAndValidity();
  }

  selectCurrencyFilter() {
    if (this.isReadOnly === false) {
      // Get the dropdown element
      let dropDown = document.querySelector(
        '#currency-select-3'
      ) as HTMLElement;
      // Get the search input element
      let searchbox = document.querySelector('#haveSearchBox') as HTMLElement;
      // Get the dropdown input to hide inner elements
      let dropdownInput = document.querySelector(
        '#dropdown-input-3'
      ) as HTMLElement;
      dropDown.classList.toggle('active');
      dropdownInput.classList.toggle('active');
      // Reset the search input
      this.searchCurrency = '';
      // Set focus on search input
      searchbox.focus();

      // Close the dropdown if the user clicks outside of it
      document.addEventListener('click', function (e: any) {
        if (!dropDown.contains(e.target)) {
          dropDown.classList.remove('active');
          dropdownInput.classList.remove('active');
        }
      });
    }
  }

  selectCurrencyFilter2() {
    if (this.isReadOnly === false) {
      // Get the dropdown element
      let dropDown = document.querySelector(
        '#currency-select-4'
      ) as HTMLElement;
      // Get the search input element
      let searchbox = document.querySelector('#wantSearchBox') as HTMLElement;
      // Get the dropdown input to hide inner elements
      let dropdownInput = document.querySelector(
        '#dropdown-input-4'
      ) as HTMLElement;
      dropDown.classList.toggle('active');
      dropdownInput.classList.toggle('active');
      // Reset the search input
      this.searchCurrency = '';
      // Set focus on search input
      searchbox.focus();

      // Close the dropdown if the user clicks outside of it
      document.addEventListener('click', function (e: any) {
        if (!dropDown.contains(e.target)) {
          dropDown.classList.remove('active');
          dropdownInput.classList.remove('active');
        }
      });
    }
  }

  async selectCurrency(variable: any, country: any) {
    if (variable === 'have') {
      this.iHave = country;
      this.createOfferForm.patchValue({
        localCurrency: {
          country: country.name,
          code: country.currency,
          iso2: country.iso2,
        },
      });
    } else if (variable === 'want') {
      this.iWant = country;
      this.createOfferForm.patchValue({
        foreignCurrency: {
          country: country.name,
          code: country.currency,
          iso2: country.iso2,
        },
      });
    }
    country.currency === 'NGN' &&
      this.createOfferForm.controls['offerType'].setValue('escrow');
    await this.getGlobalRate();

    if (this.isReadOnly === false) {
      let dropDown = document.querySelector('#country-select') as HTMLElement;
      dropDown?.classList.remove(...'active');
    }
  }

  activeDropdown(id: string) {
    if (this.isReadOnly === false) {
      let dropDown = document.querySelector(`#${id}`) as HTMLElement;
      let searchbox = id.toLocaleLowerCase().includes('send')
        ? (document.querySelector(`#sendFromSearchBox`) as HTMLElement)
        : id.toLocaleLowerCase().includes('receive')
        ? (document.querySelector(`#receiveSearchBox`) as HTMLElement)
        : null;

      this.searchPaymentMethod = '';

      dropDown.classList.toggle('active');
      searchbox?.classList?.toggle('d-none');
      searchbox?.focus();

      // Close the dropdown if the user clicks outside of it
      document.addEventListener('click', function (e: any) {
        if (!dropDown.contains(e.target)) {
          dropDown.classList.remove('active');
          searchbox?.classList?.add('d-none');
        }
      });
    }
  }

  selectOption(id: string, value: any) {
    this.createOfferForm.patchValue({
      [id]: value,
    });

    if (this.isReadOnly === false) {
      let dropDown = document.querySelector(`#${id}`) as HTMLElement;
      dropDown.classList.remove(...'active');
    }
  }

  onSubmit(offerForm: Offer) {
    offerForm.preferredCountries?.map((country: any) => {
      country.country = country.name;
      country.iso2 = this.countries
        .find((countryListing: any) => countryListing.name === country.name)
        ?.iso2.toUpperCase();
    });
    offerForm.amount = this.formatToNumber(
      offerForm.amount as unknown as string
    );
    offerForm.minAmount = this.formatToNumber(
      offerForm.minAmount as unknown as string
    );
    this.createOffer.emit(offerForm);
    this.closeOpenModal();
    this.resetForm();
  }

  resetForm() {
    let country = this.countries.find(
      (item) =>
        item.name?.toLowerCase() ===
        this.globals.storage
          .getUserLocationDetails()
          ?.country_name.toLowerCase()
    ) as Country;
    this.selectedCountries = [country];
    this.iHave = this.globals.countries?.find((country) => {
      return country.name?.includes(
        this.globals.storage.getUserLocationDetails()?.country_name
      );
    });
    this.iWant = this.globals.countries?.find((country) => {
      return country.name.toLowerCase().includes('united kingdom');
    });
    this.createOfferForm
      .get('minAmount')
      ?.setValidators([
        Validators.max(this.createOfferForm.get('amount')?.value),
      ]);
    this.createOfferForm.get('minAmount')?.updateValueAndValidity();
    this.createOfferForm.patchValue({
      localCurrency: {
        code: this.iHave?.currency,
        country: this.iHave?.name,
        iso2: this.iHave?.iso2,
      },
      paymentSendFrom: 'Bank Transfer',
      paymentReceiveThrough: 'Bank Transfer',
      preferredCountries: this.selectedCountries,
      amount: '',
      minAmount: '',
      rate: '',
      rating: 1,
      foreignCurrency: {
        code: this.iWant?.currency,
        country: this.iWant?.name,
        iso2: this.iWant?.iso2,
      },
      transactionPurpose: '',
      verificationStatus: '',
    });
  }

  async getGlobalRate(
    want: string = this.iWant?.currency.toLowerCase(),
    have: string = this.iHave?.currency.toLowerCase()
  ) {
    let resp: any = await this.transactionService.getGlobalRate(want, have);
    this.convertedRate = resp.conversion_rate;
  }

  async swapCurrencies() {
    let temp = this.iHave;
    this.iHave = this.iWant;
    this.iWant = temp;
    this.createOfferForm.patchValue({
      localCurrency: {
        code: this.iHave?.currency,
        country: this.iHave?.name,
        iso2: this.iHave?.iso2,
      },
      foreignCurrency: {
        code: this.iWant?.currency,
        country: this.iWant?.name,
        iso2: this.iWant?.iso2,
      },
    });
    await this.getGlobalRate();
  }

  formatAmount(event: any, type: string) {
    // format the amount to have commas and decimals
    let amount = event.target.value;
    amount = amount.replace(/,/g, '');
    amount = amount.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

    // allow only numbers, commas and decimals
    amount = amount.replace(/[^0-9,\.]/g, '');

    this.createOfferForm.patchValue({
      [type]: amount,
    });
  }

  formatToNumber(amount: string | number): number {
    if (typeof amount === 'number') return amount;
    return Number(amount.replace(/,/g, ''));
  }

  calcAndFormatAmount(amount: string | number, rate: number): string {
    let convertedAmount = this.formatToNumber(amount);
    let formattedAmount = (convertedAmount * rate).toFixed(2);
    formattedAmount = formattedAmount.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return formattedAmount;
  }

  findBankAccount(id: string): BankAccount | null {
    return (
      this.transactionService.bankAccounts.find((acc) => acc._id === id) || null
    );
  }

  filterBankAccountByCurrencyCode(code: string) {
    return this.transactionService.bankAccounts.filter(
      (acc) => acc.currency.code === code
    );
  }

  addBankAccount() {
    this.transactionService.selectedCurrency = {
      country: this.iWant?.name,
      code: this.iWant?.currency,
      iso2: this.iWant?.iso2,
    };
    this.globals.setPreviousModal(this.modalId);
  }

  get preferredCountries() {
    return this.createOfferForm.get('preferredCountries') as FormControl;
  }

  get minAmount() {
    return this.createOfferForm.get('minAmount') as FormControl;
  }
}
