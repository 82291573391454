<swap-modal [modalId]="modalId" [scrollable]="true" [right]="true">
  <div class="modal-header border-0 mx-3">
    <h2 class="modal-title fs-5 fw-bolder" id="staticBackdropLabel">
      {{ offer ? "Edit Offer" : "Create Offer" }}
    </h2>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="resetForm()"
      #closeModal
    ></button>
  </div>
  <div class="modal-body mx-3">
    <form
      [formGroup]="createOfferForm"
      class="row g-3 justify-content-between"
      (ngSubmit)="onSubmit(createOfferForm.value)"
    >
      <div class="col-5">
        <p class="fw-700 mb-2 font-14">
          I Have <span class="text-danger">*</span>
        </p>
        <div
          class="input-shape px-3 d-flex align-items-center justity-content-between"
          [ngClass]="{ readOnly: isReadOnly }"
        >
          <div
            class="dropdown-currency"
            (click)="selectCurrencyFilter()"
            id="currency-select-3"
          >
            <div
              class="dropdown-currency-input"
              id="dropdown-input-3"
              [ngClass]="{ readOnly: isReadOnly }"
            >
              <img
                loading="lazy"
                [src]="iHave?.flag || ''"
                alt=""
                [hidden]="iHave?.flag === ''"
              />
              <input
                type="text"
                readonly
                placeholder="Select a currency"
                name="dropdown"
                [value]="iHave?.currency || ''"
              />
              <input
                type="text"
                [readonly]="isReadOnly"
                placeholder="Type..."
                id="haveSearchBox"
                [(ngModel)]="searchCurrency"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
            <div class="option">
              <ng-container
                *ngFor="
                  let currency of countries | countryFilter : searchCurrency
                "
              >
                <div
                  class="option-item"
                  (click)="selectCurrency('have', currency)"
                >
                  <img
                    loading="lazy"
                    [src]="currency?.flag"
                    alt="Currency Flag"
                  />
                  <span>
                    {{
                      (currency?.iso2 | uppercase) + " - " + currency?.name
                    }}</span
                  >
                  <small>({{ currency?.currency | uppercase }})</small>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2 d-flex align-items-end justify-content-center">
        <img
          loading="lazy"
          class="pointer img-fluid"
          width="100%"
          height="100%"
          src="assets/images/round-swap-icon2.svg"
          (click)="swapCurrencies()"
        />
      </div>
      <div class="col-5">
        <p class="color-header fw-700 mb-2 font-14">
          I Want <span class="text-danger">*</span>
        </p>
        <div
          class="input-shape px-3 d-flex justify-content-between align-items-center"
          [ngClass]="{ readOnly: isReadOnly }"
        >
          <div
            class="dropdown-currency"
            id="currency-select-4"
            (click)="selectCurrencyFilter2()"
          >
            <div
              class="dropdown-currency-input"
              id="dropdown-input-4"
              [ngClass]="{ readOnly: isReadOnly }"
            >
              <img
                loading="lazy"
                [src]="iWant?.flag || ''"
                alt=""
                [hidden]="iWant?.flag === ''"
              />
              <input
                type="text"
                readonly
                placeholder="Select a currency"
                name="dropdown"
                [value]="iWant?.currency || ''"
              />
              <input
                type="text"
                [readonly]="isReadOnly"
                placeholder="Type..."
                id="wantSearchBox"
                [(ngModel)]="searchCurrency"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
            <div class="option-2">
              <ng-container
                *ngFor="
                  let currency of countries | countryFilter : searchCurrency
                "
              >
                <div
                  class="option-item"
                  (click)="selectCurrency('want', currency)"
                >
                  <img
                    loading="lazy"
                    [src]="currency?.flag"
                    alt="Currency Flag"
                  />
                  <span>
                    {{
                      (currency?.iso2 | uppercase) + " - " + currency?.name
                    }}</span
                  >
                  <small>({{ currency?.currency | uppercase }})</small>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <p class="fw-700 mb-2 font-14">
          Amount Available <span class="text-danger">*</span>
        </p>

        <div class="input-shape d-flex align-items-center p-3">
          <input
            formControlName="amount"
            [readonly]="isReadOnly"
            [ngClass]="{ readOnly: isReadOnly }"
            class="font-14 color-text fw-700 me-2 amount-input"
            type="text"
            (input)="formatAmount($event, 'amount')"
            pattern="^[0-9,]*$"
            name="amount"
            (keyup)="setMinimumAmount($event)"
            placeholder="0.00"
          />
          <div class="d-flex align-items-center gap-2">
            <img
              loading="lazy"
              [src]="iHave?.flag"
              width="20px"
              [hidden]="iHave?.flag === ''"
            />
            <p class="mb-0 color-text">
              {{ iHave?.currency }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 text-center">
        <p class="mb-0 badge">
          {{ "1 " + iHave?.currency }} =
          {{ convertedRate + " " + iWant?.currency }}
        </p>
        <p class="color-text m-0 font-12">Generic market rate</p>
      </div>
      <div class="col-4">
        <p class="color-header fw-700 mb-2 font-14 position-relative">
          My Rate <span class="text-danger">*</span>
          <i
            class="bi bi-info-circle-fill text-secondary"
            [ngbTooltip]="rate"
            tooltipClass="pink-tooltip"
          ></i>

          <ng-template #rate>
            <div class="text-left font-12 p-2">
              <p class="fw-700 mb-2 text-white">1/Rate = 0.0001</p>
              <p class="text-white fw-400 mb-0">
                E.g if rate is 1800 GBP to 1 NGN, 1 NGN to 1 GBP will be 1/1800
                which will be 0.00055556.
              </p>
            </div>
          </ng-template>
        </p>

        <div class="input-shape p-3">
          <input
            formControlName="rate"
            class="font-14 w-100 color-text fw-500"
            type="number"
            name="rate"
            [placeholder]="convertedRate"
          />
        </div>
      </div>
      <div class="col-8">
        <p class="color-header fw-700 mb-2 font-14">Minimum Amount</p>
        <div class="input-shape p-3" [ngClass]="{ readOnly: isReadOnly }">
          <input
            formControlName="minAmount"
            [readonly]="isReadOnly"
            [ngClass]="{ readOnly: isReadOnly }"
            class="font-14 w-100 color-text fw-700"
            type="text"
            (input)="formatAmount($event, 'minAmount')"
            name="minAmount"
            placeholder="0.00"
          />
        </div>
        <div *ngIf="minAmount.invalid && minAmount.touched" class="text-danger">
          <p
            class="fw-500 font-12 mb-2"
            *ngIf="minAmount.errors?.['exceedsMaxAmount']"
          >
            A maximum of {{ createOfferForm.value.amount }} is required
          </p>
          <p
            class="fw-500 font-12 mb-2"
            *ngIf="minAmount.errors?.['invalidNumber']"
          >
            Invalid amount
          </p>
        </div>
      </div>
      <div class="col-12">
        <p class="color-header fw-700 mb-2 font-14">I want</p>
        <div
          class="input-shape p-3 d-flex align-items-center"
          [ngClass]="{ readOnly: true }"
        >
          <input
            [readonly]="true"
            [ngClass]="{ readOnly: true }"
            class="font-14 color-text fw-700 amount-input"
            type="text"
            value="{{
              calcAndFormatAmount(
                createOfferForm.value.amount,
                createOfferForm.value.rate
              )
            }}"
            placeholder="0.00"
          />
          <div class="d-flex align-items-center gap-2">
            <img
              loading="lazy"
              [src]="iWant?.flag"
              width="20px"
              [hidden]="iHave?.flag === ''"
            />
            <p class="mb-0 color-text">
              {{ iWant?.currency }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="col-12 position-relative"
        *ngIf="
          escrowEnabled &&
          (iHave?.currency === 'NGN' || iWant?.currency === 'NGN')
        "
      >
        <p class="color-header fw-700 mb-2 font-14">
          Offer Type <span class="text-danger">*</span>
          <i
            class="bi bi-info-circle-fill text-secondary"
            [ngbTooltip]="preferredCountriesTooltip"
            tooltipClass="pink-tooltip"
          ></i>
          <ng-template #preferredCountriesTooltip>
            <div class="text-left font-12 p-2">
              <p class="text-white fw-400 mb-0">
                Please select nonn-escrow or escrow
              </p>
            </div>
          </ng-template>
        </p>

        <div class="input-shape p-3" [ngClass]="{ readOnly: isReadOnly }">
          <div
            class="d-flex justify-content-between align-items-center font-14 color-text fw-500"
          >
            <div
              class="dropdown-purpose"
              (click)="activeDropdown('offerType')"
              id="offerType"
            >
              <span>{{
                createOfferForm.value.offerType.replace("-", " ") | titlecase
              }}</span>

              <div
                class="w-100"
                class="dropdown-purpose-options"
                aria-label="select example"
              >
                <div
                  *ngFor="let type of offerTypes"
                  class="dropdown-item"
                  (click)="selectOption('offerType', type)"
                >
                  {{ type.replace("-", " ") | titlecase }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <p class="color-header fw-700 mb-2 font-14 position-relative">
          Preferred Countries
          <span class="text-muted fw-500"
            >({{ preferredCountries.value.length || 0 }}/3)</span
          >
          <i
            class="bi bi-info-circle-fill text-secondary"
            [ngbTooltip]="preferredCountriesTooltip"
            tooltipClass="pink-tooltip"
          ></i>

          <ng-template #preferredCountriesTooltip>
            <div class="text-left font-12 p-2">
              <p class="text-white fw-400 mb-0">
                Please chose up to 3 countries where you would prefer your trade
                partners to come from
              </p>
            </div>
          </ng-template>
        </p>

        <ng-multiselect-dropdown
          class="font-14 fw-500"
          [placeholder]="'choose country'"
          [settings]="dropdownSettings"
          [data]="countries"
          formControlName="preferredCountries"
          [disabled]="
            createOfferForm.get('preferredCountries')?.disabled || false
          "
        >
        </ng-multiselect-dropdown>
      </div>

      <div class="col-12">
        <p class="color-header fw-700 mb-2 font-14">
          Purpose of Transaction <span class="text-danger">*</span>
        </p>
        <div class="input-shape p-3" [ngClass]="{ readOnly: isReadOnly }">
          <div
            class="d-flex justify-content-between align-items-center font-14 color-text fw-500"
          >
            <div
              class="dropdown-purpose"
              (click)="activeDropdown('transactionPurpose')"
              id="transactionPurpose"
            >
              <span>{{
                createOfferForm.value.transactionPurpose
                  ? createOfferForm.value.transactionPurpose
                  : "Choose"
              }}</span>

              <div
                class="w-100"
                class="dropdown-purpose-options"
                aria-label="select example"
              >
                <div
                  *ngFor="let purpose of transactionPurposeList"
                  class="dropdown-item"
                  (click)="selectOption('transactionPurpose', purpose)"
                >
                  {{ purpose }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="color-header fw-700 mb-0 font-14">Payment method</p>
      <div class="col-6" *ngIf="createOfferForm.value.offerType !== 'escrow'">
        <div
          class="d-flex align-items-center mb-2 gap-2 position-relative bottom-4"
        >
          <p class="color-purple-light fw-500 font-14 mr-6 mb-0">Send via</p>
          <i
            class="bi bi-info-circle-fill text-secondary"
            [ngbTooltip]="sendFrom"
            tooltipClass="white-tooltip"
          ></i>

          <ng-template #sendFrom>
            <div class="text-left font-12 p-2">
              <p class="color-pink fw-700 mb-2">Send via</p>
              <p class="color-text fw-400 mb-0">
                Choose this payment method for how you would like to send your
                “I have currency”.
              </p>
            </div>
          </ng-template>
        </div>
        <div class="input-shape p-3" [ngClass]="{ readOnly: isReadOnly }">
          <div
            class="dropdown-purpose"
            id="paymentSendFrom"
            (click)="activeDropdown('paymentSendFrom')"
          >
            <input
              type="text"
              [readonly]="isReadOnly"
              placeholder="Type..."
              id="sendFromSearchBox"
              class="w-50 d-none"
              [(ngModel)]="searchPaymentMethod"
              [ngModelOptions]="{ standalone: true }"
            />
            <p class="font-14 mb-0 truncate" style="width: 7em">
              {{
                createOfferForm.value.paymentSendFrom
                  ? createOfferForm.value.paymentSendFrom
                  : "Choose Method"
              }}
            </p>

            <div
              class="font-14 w-wk fw-500 dropdown-purpose-options"
              aria-label="select example"
            >
              <div
                *ngFor="
                  let sendMethod of paymentMethods
                    | searchFilter : searchPaymentMethod : ['item']
                "
                class="dropdown-item"
                (click)="selectOption('paymentSendFrom', sendMethod.item)"
              >
                {{ sendMethod.item }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6" *ngIf="createOfferForm.value.offerType !== 'escrow'">
        <div
          class="d-flex align-items-center mb-2 gap-2 position-relative bottom-4"
        >
          <p class="color-purple-light fw-500 font-14 mr-6 mb-0">Receive via</p>

          <i
            class="bi bi-info-circle-fill text-secondary"
            [ngbTooltip]="receiveThrough"
            tooltipClass="white-tooltip"
          ></i>

          <ng-template #receiveThrough>
            <div class="text-left font-12 p-2">
              <p class="color-pink fw-700 mb-2">Receive via</p>
              <p class="color-text fw-400 mb-0">
                Choose this method for how you would prefer to receive your “I
                want currency”.
              </p>
            </div>
          </ng-template>
        </div>
        <div class="input-shape p-3" [ngClass]="{ readOnly: isReadOnly }">
          <div
            class="dropdown-purpose"
            id="paymentReceiveThrough"
            (click)="activeDropdown('paymentReceiveThrough')"
          >
            <input
              type="text"
              [readonly]="isReadOnly"
              placeholder="Type..."
              id="receiveSearchBox"
              class="w-50 d-none"
              [(ngModel)]="searchPaymentMethod"
              [ngModelOptions]="{ standalone: true }"
            />

            <p class="font-14 mb-0 truncate" style="width: 7em">
              {{
                createOfferForm.value.paymentReceiveThrough
                  ? createOfferForm.value.paymentReceiveThrough
                  : "Choose Method"
              }}
            </p>

            <div
              class="font-14 w-wk fw-500 dropdown-purpose-options"
              aria-label="select example"
            >
              <div
                *ngFor="
                  let receiveMethod of paymentMethods
                    | searchFilter : searchPaymentMethod : ['item']
                "
                class="dropdown-item"
                (click)="
                  selectOption('paymentReceiveThrough', receiveMethod.item)
                "
              >
                {{ receiveMethod.item }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12"
        *ngIf="escrowEnabled && createOfferForm.value.offerType === 'escrow'"
      >
        <div
          class="d-flex align-items-center mb-2 gap-2 position-relative bottom-4"
        >
          <p class="color-header fw-700 font-14 mr-6 mb-0">
            {{ iWant?.currency }} Receiving Account
          </p>
          <i
            class="bi bi-info-circle-fill text-secondary"
            [ngbTooltip]="receiveThrough"
            tooltipClass="white-tooltip"
          ></i>
          <ng-template #receiveThrough>
            <div class="text-left font-12 p-2">
              <p class="color-pink fw-700 mb-2">Receive via</p>
              <p class="color-text fw-400 mb-0">
                Choose this method for how you would prefer to receive your “I
                want currency”.
              </p>
            </div>
          </ng-template>
        </div>
        <div class="input-shape p-3" [ngClass]="{ readOnly: isReadOnly }">
          <div
            class="dropdown-purpose"
            id="receiveAccount"
            (click)="activeDropdown('receiveAccount')"
          >
            <span class="font-14">{{
              createOfferForm.value.receivingAccount
                ? (
                    findBankAccount(createOfferForm.value.receivingAccount)
                      ?.currency?.code +
                      " " +
                      findBankAccount(createOfferForm.value.receivingAccount)
                        ?.accountNumber +
                      " - " +
                      findBankAccount(createOfferForm.value.receivingAccount)
                        ?.bankName | uppercase
                  ).slice(0, 35) + "..."
                : "Select or Add an account"
            }}</span>
            <div
              class="font-14 w-wk fw-500 dropdown-purpose-options"
              aria-label="select example"
            >
              <div
                *ngFor="
                  let acc of filterBankAccountByCurrencyCode(iWant?.currency)
                "
                class="dropdown-item"
                (click)="selectOption('receivingAccount', acc._id)"
              >
                {{
                  (
                    acc?.currency?.code +
                      " " +
                      acc?.accountNumber +
                      " - " +
                      acc?.bankName | uppercase
                  ).slice(0, 35) + "..."
                }}
              </div>
              <div
                class="dropdown-item"
                data-bs-toggle="modal"
                data-bs-target="#addBank"
                (click)="addBankAccount(); closeModal.click()"
              >
                Add Bank Account
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <p class="color-header fw-700 mb-2 font-14">Verification status</p>
        <div class="input-shape p-3" [ngClass]="{ readOnly: isReadOnly }">
          <div
            class="dropdown-purpose"
            id="verificationStatus"
            (click)="activeDropdown('verificationStatus')"
          >
            <span class="font-14">{{
              createOfferForm.value.verificationStatus
                ? (createOfferForm.value.verificationStatus?.name +
                    " " +
                    createOfferForm.value.verificationStatus?.rank | titlecase)
                : "Choose Status"
            }}</span>
            <div
              class="font-14 w-wk fw-500 dropdown-purpose-options"
              aria-label="select example"
            >
              <div
                *ngFor="let status of verificationStatus"
                class="dropdown-item"
                (click)="selectOption('verificationStatus', status)"
              >
                {{ status.name + " " + status.rank | titlecase }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <p class="color-header fw-700 mb-2 font-14">Rating</p>
        <div class="input-shape p-3" [ngClass]="{ readOnly: isReadOnly }">
          <div
            class="dropdown-purpose"
            id="rating"
            (click)="activeDropdown('rating')"
          >
            <span class="font-14">
              {{
                createOfferForm.value.rating &&
                  "*".repeat(createOfferForm.value.rating)
              }}
            </span>
            <div
              class="font-14 w-wk fw-500 dropdown-purpose-options"
              aria-label="select example"
            >
              <div
                *ngFor="let rating of rateType"
                class="dropdown-item"
                (click)="selectOption('rating', rating)"
              >
                {{ rating }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <button
          type="submit"
          class="createBtn"
          [disabled]="createOfferForm.invalid"
        >
          {{ offer ? "Edit" : "Create" }}
        </button>
      </div>
    </form>
  </div>
</swap-modal>
