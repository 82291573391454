<div class="d-flex justify-content-center">
  <div class="wrapper">
    <section class="text-center md-mb-30" style="margin-bottom: 40px">
      <div class="font-16 fw-500 color-purple mb-3">FAQs</div>
      <div class="position-relative">
        <div class="d-flex justify-content-center">
          <img
            loading="lazy"
            class="position-absolute gen-splash"
            src="assets/images/splash-grey.svg"
            alt=""
          />
        </div>
        <div class="font-48 fw-700 mb-3 position-relative color-header">
          Ask us anything
        </div>
      </div>
      <div class="font-20 fw-400 color-body mb-3">
        Need something cleared up? Here are our most frequently asked questions.
      </div>
      <div class="d-flex justify-content-center">
        <form class="searchBar">
          <input
            id="searchQueryInput"
            name="searchQueryInput"
            type="text"
            placeholder="Search"
            [(ngModel)]="searchTerm"
          />

          <button id="searchQuerySubmit" type="submit" name="searchQuerySubmit">
            <img
              loading="lazy"
              class="mr-btn-20"
              src="assets/images/search-icon.svg"
              alt=""
            />
          </button>
        </form>
      </div>
    </section>
  </div>
</div>

<div class="container">
  <div *ngIf="filterData.length > 0">
    <p class="font-24 fw-500">
      {{ filterData.length }} Search results for "{{ searchTerm }}"
    </p>
    <div *ngFor="let data of filterData">
      <hr />
      <a
        class="text-black link"
        [routerLink]="
          globals.router.url.split('/')[1] === 'main'
            ? ['/main/help/', data.category]
            : ['/help/', data.category]
        "
      >
        <p class="font-16" (click)="clearSearch()">
          {{ data.title }}
        </p>
      </a>
    </div>
  </div>
</div>

<div class="container">
  <div *ngIf="searchTerm != ''">
    <div *ngIf="filterData.length === 0">
      <p class="font-29 fw-500 py-1">
        {{ filterData.length }} Search results for "{{ searchTerm }}"
      </p>
      <div class="d-flex justify-content-center">
        <app-empty-state content="no found"></app-empty-state>
      </div>
    </div>
  </div>
</div>
