import { Component, OnInit } from '@angular/core';
import { GlobalsService } from '../../../services/core/globals';
import { CategoryList, faqList } from '../../data/faq';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  categories = CategoryList;
  faqlists = faqList;
  searchTerm: string = '';
  showAll = false;
  displayLimit = 2;

  constructor(public globals: GlobalsService) {}

  ngOnInit(): void {}

  get filterData() {
    if (this.searchTerm.trim() === '') {
      return [];
    } else {
      return this.faqlists.filter((item) => {
        return (
          item.description
            .join(' ')
            .toLowerCase()
            .indexOf(this.searchTerm.toLowerCase()) !== -1
        );
      });
    }
  }
  get itemsToShow() {
    if (!this.searchTerm && !this.showAll) {
      return this.faqlists.slice(0, this.displayLimit);
    } else {
      return this.filterData;
    }
  }

  getCategory(category: any) {
    let catg = this.categories.filter((cat) => cat.name === category);
    return catg[0].id;
  }

  showAllItems() {
    this.showAll = true;
  }

  clearSearch() {
    this.searchTerm = '';
  }
}
