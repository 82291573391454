<swap-modal
  [modalId]="modalId"
  [centered]="true"
  backdrop="static"
  [keyboard]="false"
>
  <div class="row m-3">
    <div class="col-12 mb-3">
      <h5 class="modal-title text-center mb-0 fw-bolder" id="exampleModalLabel">
        Disclaimer ❗❗
      </h5>
    </div>
    <div class="col-12 mb-2">
      <p>
        ExpatSwap is a digital marketplace that connects buyers and sellers.
        Please note that by proceeding with this transaction, ExpatSwap is not
        liable for the safety or delivery of funds transferred. The users are
        fully responsible for this. To learn more about how we support safe
        transactions on our marketplace, visit
        <a
          [href]="window.location.origin + '/faqs'"
          target="_blank"
          class="fw-bolder text-primary"
          >here</a
        >. <br /><br />
        ❌ Do not send fraudulent or misleading proof of payment details.
        Violations of the terms will not be accepted.<br />✅ Transfer should be
        made from an account which you own and contain your name as shown on
        your verified identity document
      </p>
      <div class="d-flex align-items-center justify-content-center gap-2 mt-4">
        <button
          class="submit-btn fw-700 font-13"
          (click)="emitDisclaimerAccepted.emit(true)"
          data-bs-dismiss="modal"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</swap-modal>
