<swap-modal [modalId]="modalId" [centered]="true">
  <div class="row m-3">
    <div class="col-12 mb-2">
      <img
        loading="lazy"
        src="assets/icons/warning-with-inner-circle-2.svg"
        alt=""
      />
    </div>
    <div class="col-12 d-flex mb-3">
      <h5 class="modal-title mb-0 fw-bolder" id="exampleModalLabel">
        Change Plan
      </h5>
      <button
        type="button"
        class="closeBtn d-none"
        data-bs-dismiss="modal"
        aria-label="Close"
        #closeBtn
      >
        <img loading="lazy" src="assets/icons/close-icon.svg" alt="" />
      </button>
    </div>
    <form [formGroup]="plansForm" class="col-12 mb-3" (ngSubmit)="onSubmit()">
      <div class="mb-3">
        <p class="text-muted font-14">
          Ready to switch to {{ plansForm.value.period | titlecase }} renewal on
          your {{ plansForm.value.plan }} subscription? If so, your subscription
          will renew for
          {{
            plansForm.value.period === "monthly"
              ? plan?.price_per_month
              : plan?.price_per_year
          }}
          on {{ calculateExpiryDate() }}.
        </p>
        <label class="form-label">Plans </label>
        <button class="form-control dropdown" (click)="selectDropdown($event)">
          {{ plansForm.value.plan || "Choose plan" }}
          <div class="dropdown-option">
            <div
              class="dropdown-option-item"
              *ngFor="let plan of plans"
              (click)="selectPlan(plan.name)"
            >
              {{ plan.name }}
            </div>
          </div>
        </button>
      </div>

      <div class="mb-3">
        <label class="form-label">Period</label>
        <button
          class="form-control dropdown text-capitalize"
          (click)="selectDropdown($event)"
        >
          {{ (plansForm.value.period | titlecase) || "Choose period" }} -
          {{
            plansForm.value.period === "monthly"
              ? plan.price_per_month
              : plan.price_per_year
          }}
          <div class="dropdown-option">
            <div
              class="dropdown-option-item text-capitalize"
              *ngFor="let period of periods"
              (click)="selectPeriod(period)"
            >
              {{ period.period | titlecase }} - {{ period.amount }}
            </div>
          </div>
        </button>
      </div>

      <div class="d-flex align-items-center gap-2 mt-4">
        <button
          (click)="closeBtn.click()"
          class="submit-btn fw-700 font-13 btn-decline"
          type="button"
        >
          Cancel
        </button>
        <button type="submit" class="submit-btn fw-700 font-13">Next</button>
      </div>
    </form>
  </div>
</swap-modal>
