import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'choose-time',
  templateUrl: './choose-time.component.html',
  styleUrls: ['./choose-time.component.scss'],
})
export class ChooseTimeComponent implements OnInit {
  @Input() modalId: string = '';
  @Output() emitTimeExtention: EventEmitter<{
    status: boolean;
    value: number;
  }> = new EventEmitter<{ status: boolean; value: number }>();

  @ViewChild('closeBtn') closeBtn: any;

  constructor() {}

  timers = [
    { status: false, value: 10, img: 'assets/icons/smiling-face.svg' },
    { status: false, value: 20, img: 'assets/icons/beaming-face.svg' },
    { status: false, value: 30, img: 'assets/icons/star-struck.svg' },
  ];
  selectedTimer: any = null;

  ngOnInit(): void {}

  chooseTimer() {
    if (this.selectedTimer) {
      this.emitTimeExtention.emit(this.selectedTimer);
      this.selectedTimer = null;
      this.closeBtn.nativeElement.click();
    }
  }
}
