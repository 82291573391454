import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  constructor(
    // private ngxService: NgxUiLoaderService,
    private spinner: NgxSpinnerService
  ) {}

  show() {
    // this.ngxService.start();
    this.spinner.show();
  }

  hide() {
    // this.ngxService.stop();
    this.spinner.hide();
  }
}
